import { useEffect } from 'react';
import { getUniqueId } from 'utils';
import { setActiveChannelId, getActiveChannelId } from 'utils/use/chatActiveChannel';

const listeners = {};

function setReply(message, channelId) {
    if (channelId && getActiveChannelId() !== channelId) {
        setActiveChannelId(channelId);
        setTimeout(() => {
            setReply(message);
        }, 500);
        return;
    }

    for (const key in listeners) {
        listeners[key](message);
    }
}

export function useChatReply(props) {
    const { listenReply } = props || {};

    useEffect(() => {
        if (listenReply) {
            const key = getUniqueId();

            listeners[key] = listenReply;

            return () => {
                delete listeners[key];
            };
        }
    }, [listenReply]);

    return {
        setReply
    };
}
