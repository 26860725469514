import {
  Unsubscribe,
} from 'global';
import {
  InputProps,
  FileReceiver,
  Listener,
  ProviderMounter,
} from './types';

class DragAndDrop {
  private listeners: Listener[] = [];
  private providerMounter?: ProviderMounter;

  public mountProvider(providerMounter: ProviderMounter): void {
    this.providerMounter = providerMounter;
  }

  public addListener(fileReceiver: FileReceiver, inputProps: InputProps): Unsubscribe {
    this.listeners.push({
      fileReceiver,
      inputProps,
    });
    this.mountListener();

    return (): void => {
      this.removeListener(fileReceiver);
      this.mountListener();
    };
  }

  private removeListener(fileReceiver: FileReceiver): void {
    for (let i = 0, len = this.listeners.length; i < len; i++) {
      if (this.listeners[i].fileReceiver === fileReceiver) {
        this.listeners.splice(i, 1);
        break;
      }
    }
  }

  private mountListener(): void {
    if (this.providerMounter) {
      const listener: Listener | void = this.getCurrentListener();

      if (listener) {
        this.providerMounter(true, listener.inputProps);
      } else {
        this.providerMounter(false);
      }
    }
  }

  private getCurrentListener(): Listener | void {
    return this.listeners[this.listeners.length - 1];
  }

  public onReceiveFiles(files: FileList | null): void {
    const listener: Listener | void = this.getCurrentListener();

    if (listener && files !== null) {
      listener.fileReceiver(files);
    }
  }

}

const dragAndDrop: DragAndDrop = new DragAndDrop();

export default dragAndDrop;
