
function getSourceUrlFromCLoudinaryUrl(url) {
  const indexOfSourceUrl = url.indexOf('https://', 10);

  if (indexOfSourceUrl > -1) {
    return url.slice(indexOfSourceUrl);
  }

  return url;
}

export {
  getSourceUrlFromCLoudinaryUrl
};
