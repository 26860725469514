import PropTypes from 'prop-types';
import React from 'react';
import {
    Button,
    Toggle
} from 'components';
import {
    WORKITEM_SURVEY_QUESTION_CONFIG
} from 'config/workitem';
import styles from './index.module.scss';

const TYPES = Object.keys(WORKITEM_SURVEY_QUESTION_CONFIG);

Types.propTypes = {
    t: PropTypes.func.isRequired,
    activeType: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    isMandatory: PropTypes.bool.isRequired,
    setIsMandatory: PropTypes.func.isRequired
};

export default function Types(props) {
    const {
        t,
        activeType,
        onSelect,
        isMandatory,
        setIsMandatory
    } = props;

    const renderType = (type, index) => {
        const {
            iconBlack
        } = WORKITEM_SURVEY_QUESTION_CONFIG[type] || {};
        const onClick = () => {
            onSelect(type);
        };

        if (activeType) {
            if (activeType !== type) {
                return null;
            }
        }

        return (
            <Button
                key={index}
                className={styles.type}
                onClick={onClick}>
                <div className={styles.type_icon}>
                    <img src={iconBlack} alt='' />
                </div>

                {t(`card.survey.question.type.${type}`)}
            </Button>
        );
    };

    return (
        <div className={styles.Root}>
            {TYPES.map(renderType)}

            {!!activeType && (
                <div className={styles.mandatory}>
                    <Toggle
                        isActive={isMandatory}
                        onChange={setIsMandatory}
                        leftChildren={t('forms.create.form.questions.optional')}
                        rightChildren={t('forms.create.form.questions.mandatory')} />
                </div>
            )}
        </div>
    );
}
