// import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function Analytics() {
    const { pathname } = useLocation();
    const refLastPathname = useRef('');

    useEffect(() => {
        if (refLastPathname.current !== pathname) {
            refLastPathname.current = pathname;

            if (pathname !== '/') {
                firebase.analytics().logEvent('screen_view', {
                    firebase_screen: pathname
                });
            }
        }
    }, [pathname]);

    return null;
}
