import PropTypes from 'prop-types';
import React from 'react';
import {
  Toggle,
  SelectLanguage,
  SelectTags
} from 'components';
import {
  Language,
} from 'global';
import {
  useConfig,
  isExistingTemplate
} from '../use/useInputs';
import styles from './index.module.scss';
// Assets
import IconTemplate from './assets/template.svg';
import IconLanguage from './assets/language.svg';
import IconTags from './assets/tags.svg';


Template.propTypes = {
  t: PropTypes.func.isRequired
};

function Template(props) {
  const {
    t
  } = props;

  const {
    config,
    setConfig
  } = useConfig();
  const {
    isTemplate,
    language = Language.English,
    tags = []
  } = config;

  const existingTemplate = isExistingTemplate();

  const onChangeIsTemplate = (nextIsTemplate) => {
    setConfig({
      ...config,
      isTemplate: nextIsTemplate
    });
  };

  const onChangeLanguage = (nextLanguage) => {
    setConfig({
      ...config,
      language: nextLanguage
    });
  };

  const onChangeTags = (nextTags) => {
    setConfig({
      ...config,
      tags: nextTags
    });
  };

  const renderRow = (item) => {
    const {
      icon,
      label,
      body
    } = item;

    return (
      <div className={styles.row}>
        <img src={icon} alt='' />

        <span>
          {label}
        </span>

        <div>
          {body}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {!existingTemplate && renderRow({
        icon: IconTemplate,
        label: t('forms.create.template.save_as_template'),
        body: (
          <Toggle
            isActive={!!isTemplate}
            onChange={onChangeIsTemplate} />
        )
      })}

      {renderRow({
        icon: IconLanguage,
        label: t('forms.create.template.language'),
        body: (
          <SelectLanguage
            className={styles.SelectLanguage}
            value={language}
            onChange={onChangeLanguage} />
        )
      })}

      {renderRow({
        icon: IconTags,
        label: t('forms.create.template.tags'),
        body: (
          <SelectTags
            value={tags}
            onChange={onChangeTags} />
        )
      })}
    </div>
  );
}

export default Template;
