import { useState, useEffect } from 'react';
import { apiUsers } from 'api';
import { getUniqueId } from 'utils';
import { sortUserByName } from 'utils/sort';
import { USER_CONNECTION_STATUS } from 'config';

const __listenersRequests = {};
const __listenersCount = {};
let __requests = [];
let __count = 0;

function setRequests(requests) {
    __requests = requests;

    for (const key in __listenersRequests) {
        __listenersRequests[key](requests);
    }
}

function setCount(count) {
    __count = count;

    for (const key in __listenersCount) {
        __listenersCount[key](count);
    }
}

function parseData(data) {
    const requests = [];
    let count = 0;

    for (let i = 0, len = data.length; i < len; i++) {
        const item = data[i];
        const { connection_status: status } = item;
        const isReceivedRequest = status === USER_CONNECTION_STATUS.REQUEST_RECEIVED;
        const isSentRequest = status === USER_CONNECTION_STATUS.REQUEST_SENT;

        if (isReceivedRequest || isSentRequest) {
            requests.push(item);

            if (isReceivedRequest) {
                count++;
            }
        }
    }

    requests.sort(sortUserByName);

    return {
        count,
        requests
    };
}

async function fetchConnectionRequests() {
    try {
        const { data } = await apiUsers.getPublic();
        const {
            count,
            requests
        } = parseData(data);

        setCount(count);
        setRequests(requests);
    } catch (err) {
        //
    }
}

export function useConnectionRequests(props) {
    const {
        isReady = true
    } = props || {};
    const [requests, setRequests] = useState(__requests);
    const [count, setCount] = useState(__count);

    useEffect(() => {
        if (isReady) {
            const key = getUniqueId();

            __listenersRequests[key] = setRequests;
            __listenersCount[key] = setCount;

            fetchConnectionRequests();

            return () => {
                delete __listenersRequests[key];
                delete __listenersCount[key];
            };
        }
    }, [isReady]);

    return {
        requests,
        count
    };
}
