import PropTypes from 'prop-types';
import React from 'react';
import Range from 'react-simple-range';
import styles from './index.module.scss';

LinearScale.propTypes = {
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number
};

export default function LinearScale(props) {
    const {
        items,
        onChange,
        value
    } = props;

    const onChangeSlider = (sliderItem) => {
        const {
            value: sliderIndex
        } = sliderItem;

        onChange(sliderIndex);
    };

    return (
        <div className={styles.Root}>
            <Range
                step={1}
                min={0}
                max={items.length - 1}
                trackColor='#fff'
                customThumb={(
                    <div className={styles.thumb} />
                )}
                onChange={onChangeSlider} />

            <div className={styles.value}>
                {items[value]?.title}
            </div>
        </div>
    );
}
