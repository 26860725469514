import PropTypes from 'prop-types';
import React from 'react';
import {
    COLORS
} from 'config';
import styles from './index.module.scss';

Colors.propTypes = {
    onSelect: PropTypes.func.isRequired
};

export default function Colors(props) {
    const {
        onSelect
    } = props;

    const renderColor = (color, index) => {
        const onClick = () => {
            onSelect(color);
        };

        return (
            <div
                key={index}
                className={styles.color}
                onClick={onClick}
                style={{
                    backgroundColor: color
                }} />
        );
    };

    return (
        <div className={styles.Root}>
            {COLORS.map(renderColor)}
        </div>
    );
}
