import Pusher from 'pusher-js';
import { getBearerToken } from 'utils/auth';
import { PUSHER_KEY, PUSHER_AUTH_URL } from 'config';

export const apiPusher = new Pusher(PUSHER_KEY, {
    cluster: 'ap1',
    authorizer: (channel, options) => ({
        authorize: async (socketId, callback) => {
            fetch(PUSHER_AUTH_URL, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: await getBearerToken()
                }),
                body: JSON.stringify({
                    socket_id: socketId,
                    channel_name: channel.name
                })
            }).then((res) => {
                if (!res.ok) {
                    throw new Error(`Received ${res.statusCode} from ${PUSHER_AUTH_URL}`);
                }

                return res.json();
            }).then((data) => {
                callback(null, data);
            }).catch((err) => {
                callback(new Error(`Error calling auth endpoint: ${err}`), { auth: '' });
            });
        }
    })
});
