import PropTypes from 'prop-types';
import React from 'react';
import {
    Button
} from 'components';
import {
    Player
} from '@lottiefiles/react-lottie-player';
import styles from './index.module.scss';
// Assets
import LottieSuccess from './assets/success.json';

Submit.propTypes = {
    t: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    invalidQuestionIndexes: PropTypes.array.isRequired,
    setActiveQuestionIndex: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default function Submit(props) {
    const {
        t,
        isProcessing,
        isSuccess,
        invalidQuestionIndexes,
        setActiveQuestionIndex,
        onSubmit
    } = props;

    const onGoToFirstInvalidQuestion = () => {
        setActiveQuestionIndex(invalidQuestionIndexes[0]);
    };

    return (
        <div className={styles.Root}>
            {isSuccess
                ? <Player
                    className={styles.Player}
                    autoplay={true}
                    loop={false}
                    keepLastFrame={true}
                    src={LottieSuccess} />
                : invalidQuestionIndexes.length > 0
                    ? <div
                        className={styles.invalidQuestions}
                        onClick={onGoToFirstInvalidQuestion}>
                        {t('forms.respond.x_mandatory_questions', {
                            amount: invalidQuestionIndexes.length
                        })}
                    </div>
                    : <Button
                        className={styles.button}
                        isDisabled={invalidQuestionIndexes.length > 0}
                        isProcessing={isProcessing}
                        onClick={onSubmit}
                        colorActivityIndicator='#fff'
                        label={t('forms.respond.complete')} />
            }
        </div>
    );
}
