import PropTypes from 'prop-types';
import React from 'react';
import styles from './index.module.scss';

Email.propTypes = {
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
};

export default function Email(props) {
    const {
        t,
        onChange,
        value
    } = props;

    const onChangeText = (e) => {
        const {
            target
        } = e;
        const {
            value
        } = target;

        onChange(value);
    };

    return (
        <input
            className={styles.Root}
            type='email'
            placeholder={t('forms.create.form.questions.email.placeholder')}
            value={value || ''}
            onChange={onChangeText} />
    );
}
