import { useState, useEffect, useCallback } from 'react';
import { apiUsers } from 'api';
import { getUniqueId } from 'utils';
import firebase from 'firebase/compat/app';
import { initPushNotifications } from 'config/executors/firebase';
import { init as initLiveUsers } from 'utils/use/chatLiveUsers';
import { SK_ACTIVE_CHANNEL_ID } from 'config';

const __listeners = {
    profile: {},
    organization: {},
    permissions: {}
};

let __isInit = false;
let __profile = null;
let __organization = null;
let __permissions = null;

function callListenerProfile(data) {
    const nextData = { ...(__profile || {}), ...data };

    __profile = nextData;

    for (const key in __listeners.profile) {
        __listeners.profile[key](nextData);
    }
}
function callListenerOrganization(data) {
    const nextData = { ...(__organization || {}), ...data };

    __organization = nextData;

    for (const key in __listeners.organization) {
        __listeners.organization[key](nextData);
    }
}
function callListenerPermissions(data) {
    const nextData = { ...(__permissions || {}), ...data };

    __permissions = nextData;

    for (const key in __listeners.permissions) {
        __listeners.permissions[key](nextData);
    }
}

export function getUserId() {
    return __profile?.id;
}
export function getProfile() {
    return __profile;
}
export function getPermissions() {
    return __permissions;
}
// NOTE: FOR IFRAME ONLY
export function setUserId(id) {
    __profile = { id };
}

export function getOrgId() {
    return __organization?.id;
}
export function getOrg() {
    return __organization;
}
// NOTE: FOR IFRAME ONLY
export function setOrgId(id) {
    __organization = { id };
}

export async function fetchMe(props) {
    const {
        isIframe = false,
        authToken = null
    } = props || {};
    const isInit = __isInit;

    __isInit = true;

    try {
        let data;

        if (!isIframe && !isInit) {
            await firebase.auth().currentUser.getIdToken(true);
        }

        if (isIframe) {
            const response = await apiUsers.meIFRAME(authToken);

            data = response.data;
        } else {
            const response = await apiUsers.me();

            data = response.data;
        }

        const { organization, permissions, ...profile } = data;

        __profile = profile;
        __organization = organization;
        __permissions = permissions;

        callListenerProfile(profile);
        callListenerOrganization(organization);
        callListenerPermissions(permissions);

        if (!isIframe && !isInit) {
            initPushNotifications();
            initLiveUsers();
            firebase.analytics().setUserId(profile.id);
        }

        return data;
    } catch (err) {
        return false;
    }
}

export function useIsSignedIn() {
    const [isSignedIn, setIsSignedIn] = useState(!!__profile);

    useEffect(() => {
        if (!isSignedIn) {
            const key = getUniqueId();

            __listeners.profile[key] = () => setIsSignedIn(true);

            return () => {
                delete __listeners.profile[key];
            };
        }
    }, [isSignedIn]);

    return {
        isSignedIn
    };
}

export function useProfile() {
    const [profile, setProfile] = useState(__profile || {});

    useEffect(() => {
        const key = getUniqueId();

        __listeners.profile[key] = (data) => setProfile(data);

        return () => {
            delete __listeners.profile[key];
        };
    }, []);

    return profile;
}

export function useOrg() {
    const [org, setOrg] = useState(__organization || {});

    useEffect(() => {
        const key = getUniqueId();

        __listeners.organization[key] = (data) => setOrg(data);

        return () => {
            delete __listeners.organization[key];
        };
    }, []);

    return org;
}

export function usePermissions() {
    const [permissions, setPermissions] = useState(__permissions || {});

    useEffect(() => {
        const key = getUniqueId();

        __listeners.permissions[key] = (data) => setPermissions(data);

        return () => {
            delete __listeners.permissions[key];
        };
    }, []);

    return permissions;
}

export function useUpdateProfile() {
    const [isProcessing, setIsProcessing] = useState(false);

    const update = useCallback(async (payload) => {
        setIsProcessing(true);

        try {
            await apiUsers.updateMe(payload);

            callListenerProfile(payload);
        } catch (err) {
            //
        } finally {
            setIsProcessing(false);
        }
    }, []);

    return {
        isProcessing,
        update
    };
}

export async function logout() {
    try {
        await firebase.auth().signOut();
    } catch (error) {
        console.error(error);
    } finally {
        localStorage.removeItem(SK_ACTIVE_CHANNEL_ID);
        window.location = '/';
    }
}

export function useLeaveOrg() {
    const [isProcessing, setIsProcessing] = useState(false);

    const leaveOrg = async () => {
        setIsProcessing(true);

        try {
            await apiUsers.leaveOrg();

            // keep same with logout()
            firebase.auth().signOut();
            localStorage.removeItem(SK_ACTIVE_CHANNEL_ID);
        } catch (err) {
            setIsProcessing(false);
        } finally {
            window.location = '/';
        }
    };

    return {
        isProcessing,
        leaveOrg
    };
}
