import {
  StrictMode,
} from 'react';
import {
  createRoot,
} from 'react-dom/client';
import {
  BrowserRouter,
} from 'react-router-dom';
import {
  ToastProvider,
} from 'react-toast-notifications';
import {
  setInitialRoute,
} from 'utils/navigation';
import App from 'containers/App';
import 'config/execute';
import 'styles/styles.scss';

setInitialRoute();

const root = createRoot(
  document.getElementById('root'),
);

// root.render(
//   <BrowserRouter>
//     <ToastProvider>
//       <App />
//     </ToastProvider>
//   </BrowserRouter>
// );
root.render(
  <StrictMode>
    <BrowserRouter>
      <ToastProvider>
        <App />
      </ToastProvider>
    </BrowserRouter>
  </StrictMode>
);
