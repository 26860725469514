import {
    apiFiles
} from 'api';
import {
    CLOUDINARY_URL
} from 'config';
import {
    format
} from 'utils/datefns';
import {
    getProfile,
    getOrg
} from 'utils/use/me';
import {
    v4 as uuidv4,
} from 'uuid';
// Assets
import IconUnknownFile from './assets/unknown.svg';

export function deepCopyObject(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function moveArrayItem(array, oldIndex, newIndex, isMutation = false) {
    const nextArray = isMutation ? array : deepCopyObject(array);

    if (newIndex >= nextArray.length) {
        let k = newIndex - nextArray.length + 1;

        while (k--) {
            nextArray.push(undefined);
        }
    }

    nextArray.splice(newIndex, 0, nextArray.splice(oldIndex, 1)[0]);

    return nextArray;
}

export function getCapLetters(text) {
    if (text && typeof text === 'string') {
        const words = text.split(' ');
        const letters = words.length > 1
            ? `${words[0][0] || ''}${words[1][0] || ''}`
            : text.slice(0, 2);

        return letters.toUpperCase();
    }

    return '';
}

export function cloudinaryFetch(imgUrl) {
    return `${CLOUDINARY_URL}image/fetch/${imgUrl}`;
}

export function cloudinaryFit(imgUrl, size) {
    const config = `h_${size},w_${size},c_fit`;

    return `${CLOUDINARY_URL}image/fetch/${config}/${imgUrl}`;
}

export function cloudinaryFill(imgUrl, size) {
    const config = `h_${size},w_${size},c_fill`;

    return `${CLOUDINARY_URL}image/fetch/${config}/${imgUrl}`;
}

export function cloudinaryWaterMarkImage(imgUrl, props) {
    const {
        userName = getProfile().display_name,
        orgName = getOrg().name,
        size
    } = props || {};

    if (imgUrl.indexOf('.gif') > -1 ||
        imgUrl.indexOf('cloudinary') === -1) {
        return imgUrl;
    }

    const config = size ? `h_${size},w_auto,q_auto` : 'q_auto';
    const time = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timestamp = `l_text:Verdana_20:${encodeURIComponent(time)}(${encodeURIComponent(timeZone.replace('/', ' '))}),g_south_east,x_15,y_15,co_rgb:efefefef`;
    const user = `l_text:Verdana_20:${encodeURIComponent(userName)}@${encodeURIComponent(orgName)},g_south_east,x_15,y_35,co_rgb:efefefef`;
    const logo = 'l_v1606141807:images:StoreWise_Logo_Watermark,w_0.8,fl_relative,c_scale,o_35,x_20,y_20,e_brightness:80';

    return `${CLOUDINARY_URL}image/fetch/${config}/${timestamp}/${user}/${logo}/${imgUrl}`;
}

const PREVIEW_SIZE = 800;

export function cloudinaryWaterMarkedImagePreview(imgUrl) {
    if (imgUrl.indexOf('.gif') > -1 ||
        imgUrl.indexOf('cloudinary') === -1) {
        return imgUrl;
    }

    const config = `w_${PREVIEW_SIZE},h_${PREVIEW_SIZE},c_fit`;
    const configIndexSearchString = 'image/fetch/';
    const configIndex = imgUrl.indexOf(configIndexSearchString);
    const timestampIndex = imgUrl.indexOf('/', configIndex + configIndexSearchString.length);
    const a = imgUrl.slice(0, configIndex + configIndexSearchString.length);
    const b = imgUrl.slice(timestampIndex);

    return `${a}${config}${b}`;
}

export function cloudinaryImagePreview(imgUrl) {
    const config = `w_${PREVIEW_SIZE},h_${PREVIEW_SIZE},c_fit`;

    return `${CLOUDINARY_URL}image/fetch/${config}/${imgUrl}`;
}

export function cloudinaryVideoThumbnail(videoUrl) {
    return `${videoUrl.slice(0, videoUrl.lastIndexOf('.'))}.jpg`;
}

export function getPdfFileUrl(url) {
    const extension = url.slice(url.lastIndexOf('.'));
    const validExtensions = [
        '.key', '.odp', '.pps', '.ppt', '.pptx',
        '.txt', '.ods', '.xls', '.xlsm', '.xlsx',
        '.doc', '.docx', '.rtf', '.tex', '.wpd'
    ];

    if (extension === '.pdf') {
        return url;
    } else if (validExtensions.includes(extension)) {
        apiFiles.convertPdf(url);

        return url.replace(extension, '.pdf');
    }

    return null;
}

export function getUniqueId() {
    return uuidv4();
}

export function getUrlSearchParamByKey(search, key) {
    if (typeof search === 'string') {
        const keyValues = search.slice(1).split('&');

        for (let i = 0, len = keyValues.length; i < len; i++) {
            const keyValue = keyValues[i].split('=');

            if (keyValue[0] === key) {
                return keyValue[1];
            }
        }
    }

    return '';
}

export function promptFileDownload(href) {
    const element = document.createElement('a');

    element.setAttribute('href', href);
    element.setAttribute('target', '_blank');
    element.setAttribute('download', href.slice(href.lastIndexOf('/') + 1));
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export function postMessageToParentFrame(data) {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else {
        window.parent.postMessage(data);
    }
}

export function getFileNameByUri(uri) {
    return uri.slice(uri.lastIndexOf('/') + 1);
}

export function getFileIconByUri(uri) {
    const extAliasMap = {
        docx: 'doc',
        jpeg: 'jpg',
        pptx: 'ppt',
        xlsx: 'xls'
    };

    try {
        let ext = uri.slice(uri.lastIndexOf('.') + 1);

        if (extAliasMap[ext]) {
            ext = extAliasMap[ext];
        }

        return require(`./assets/${ext}.svg`);
    } catch (err) {
        return IconUnknownFile;
    }
}

export function parseJsonString(value) {
    if (typeof value === 'string') {
        try {
            return JSON.parse(value);
        } catch (err) {
            return {};
        }
    }

    return value;
}

export function getUrlSearchKeyValue(search, key) {
    const queries = search.slice(1).split('&');

    for (let i = 0, len = queries.length; i < len; i++) {
        const keyValue = queries[i].split('=');

        if (keyValue[0] === key) {
            return keyValue[1];
        }
    }

    return false;
}


export function parseJsonSafe(json, fallback) {
    try {
      return JSON.parse(json);
    } catch (error) {
      console.warn(error, 'Util.parseJsonSafe()');

      return fallback;
    }
  }
