import { LAPI, ROUTES_IFRAME } from 'config';
import { getBearerToken } from 'utils/auth';
import * as Sentry from '@sentry/react';

function responseOutside2xx(error) {
    Sentry.captureException(error);

    return Promise.reject(error);
}

async function requestInterceptor(config) {
    const { headers } = config;
    const { common } = headers;

    if (ROUTES_IFRAME.indexOf(window.location.pathname) > -1) {
        return config;
    }

    return {
        ...config,
        headers: {
            ...headers,
            common: {
                ...common,
                Authorization: await getBearerToken()
            }
        }
    };
}

LAPI.interceptors.request.use(requestInterceptor);
LAPI.interceptors.response.use(undefined, responseOutside2xx);
