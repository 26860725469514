import {
    getUrlSearchKeyValue
} from 'utils';
import {
    ROUTES,
    ROUTES_WITH_NAV
} from 'config';

const STORAGE_KEY = '@session-initial-route';

function setInitialRoute() {
    const {
        pathname,
        search
    } = {
        ...window.location
    };

    for (const index in ROUTES_WITH_NAV) {
        const route = ROUTES_WITH_NAV[index];

        if (pathname.indexOf(route) > -1) {
            sessionStorage.setItem(
                STORAGE_KEY,
                JSON.stringify({
                    pathname,
                    search
                })
            );
            break;
        }
    }
}

function getInitialRoute() {
    const initialRoute = sessionStorage.getItem(
        STORAGE_KEY
    );

    if (typeof initialRoute === 'string') {
        return JSON.parse(initialRoute);
    }

    return null;
}

function getMobileRouteIfApplicable() {
    const {
        pathname,
        search
    } = getInitialRoute() || {};

    switch (pathname) {
        case ROUTES.FORMS(): {
            const formTemplateId = getUrlSearchKeyValue(
                search,
                'ftIdR'
            );

            if (formTemplateId) {
                return ROUTES.FORM_RESPOND_MOBILE(formTemplateId);
            }

            break;
        }

        case ROUTES.CARDS(): {
            const workItemId = getUrlSearchKeyValue(
                search,
                'id'
            );

            if (workItemId) {
                return ROUTES.CARD_MOBILE(workItemId);
            }

            break;
        }

        default:
            break;
    }

    return false;
}

export {
    setInitialRoute,
    getInitialRoute,
    getMobileRouteIfApplicable
};
