import {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  ChangeEventHandler,
  MutableRefObject,
} from 'react';
import {
  useTranslation,
} from 'react-i18next';
import {
  WindowEvent,
} from 'global';
import {
  InputProps,
} from './types';
import DragAndDrop from '.';
import styles from './Provider.module.scss';

function DragAndDropProvider(): JSX.Element | null {
  const {
    t,
  } = useTranslation();

  const refInitialized: MutableRefObject<boolean> = useRef<boolean>(false);
  const [dropZoneVisible, setDropZoneVisible] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [inputProps, setInputProps] = useState<InputProps | null>(null);

  if (refInitialized.current === false) {
    refInitialized.current = true;
    DragAndDrop.mountProvider((mount: boolean, inputProps?: InputProps): void => {
      setActive(mount);
      setInputProps(inputProps || null);
    });
  }

  const onChangeInput: ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    DragAndDrop.onReceiveFiles(event.target.files);
    setDropZoneVisible(false);
  };

  useEffect(() => {
    if (active) {
      const onDragOver: WindowEvent<DragEvent> = (event: DragEvent): void => {
        const types = event?.dataTransfer?.types || [];

        for (let i = 0; i < types.length; i++) {
          if (types[i] === 'Files') {
            setDropZoneVisible(true);
            break;
          }
        }
      };

      const onDragLeave: WindowEvent<DragEvent> = (): void => {
        setDropZoneVisible(false);
      };

      window.addEventListener('dragover', onDragOver);
      window.addEventListener('dragleave', onDragLeave);

      return () => {
        window.removeEventListener('dragover', onDragOver);
        window.removeEventListener('dragleave', onDragLeave);
        setDropZoneVisible(false);
      };
    }
  }, [active]);

  if (dropZoneVisible === false) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.zone}>
        <div>
          {t('dragndrop.title')}
        </div>
      </div>

      <input
        {...(inputProps || {})}
        onChange={onChangeInput}
        type='file' />
    </div>
  );
}

export default DragAndDropProvider;