import PropTypes from 'prop-types';
import React from 'react';
import styles from './index.module.scss';

Component.propTypes = {
    body: PropTypes.any.isRequired
};

export default function Component(props) {
    return (
        <div className={styles.Component}>
            {props.body}
        </div>
    );
}
