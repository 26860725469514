// import PropTypes from 'prop-types';
import React, {
    useState,
    useRef
} from 'react';
import {
    createPortal
} from 'react-dom';
import {
    Button
} from 'components';
import {
    useContextMenuCore
} from 'utils/use/useContextMenu';
import styles from './index.module.scss';

export default function ContextMenuCore() {
    const ref = useRef();
    const [data, setData] = useState(null);
    const {
        innerWidth,
        innerHeight
    } = window;
    const {
        top,
        left,
        items
    } = data || {};
    const style = {
        top,
        left
    };

    useContextMenuCore({
        setData,
        ref
    });

    if (!data) {
        return null;
    }

    if (top > (innerHeight / 2)) {
        style.transform = 'translateY(-100%)';
    }

    if (left > (innerWidth / 2)) {
        style.transform = `${style.transform || ''} translateX(-100%)`;
    }

    const renderItem = (item, index) => {
        const {
            isVisible,
            isDisabled,
            isCloseOnClick,
            label,
            onClick: _onClick
        } = item;

        const onClick = (e) => {
            _onClick && _onClick(e);

            if (isCloseOnClick) {
                setData(null);
            }
        };

        if (isVisible === false) {
            return null;
        }

        return (
            <Button
                key={index}
                isDisabled={isDisabled}
                className={styles.Component__item}
                onClick={onClick}>
                {label}
            </Button>
        );
    };

    return createPortal(
        <div
            ref={ref}
            className={styles.Component}
            style={style}>
            {items.map(renderItem)}
        </div>,
        window.document.body
    );
}
