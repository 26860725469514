import {
    useState
} from 'react';

let ___setIsVisible;
let ___setFormTemplateId;
let ___setFormTemplateCreatorId;
let ___onClose;

export function showFormRespond(props) {
    const {
        formTemplateId,
        formTemplateCreatorId,
        onClose
    } = props;

    ___setFormTemplateId(formTemplateId);
    ___setFormTemplateCreatorId(formTemplateCreatorId);
    ___setIsVisible(true);
    ___onClose = onClose;
}

export default function useFormRespond() {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isRestarting, setIsRestarting] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [formTemplateId, setFormTemplateId] = useState(null);
    const [formTemplateCreatorId, setFormTemplateCreatorId] = useState(null);

    ___setIsVisible = setIsVisible;
    ___setFormTemplateId = setFormTemplateId;
    ___setFormTemplateCreatorId = setFormTemplateCreatorId;

    const resetState = () => {
        setIsSuccess(false);
        setFormTemplateId(null);
    };

    const onStartNew = () => {
        const _formTemplateId = formTemplateId;

        setIsRestarting(true);
        resetState();
        setTimeout(() => {
            setFormTemplateId(_formTemplateId);
            setIsRestarting(false);
        }, 250);
    };

    const onShow = () => {
        setIsVisible(true);
    };

    const onHide = () => {
        setIsVisible(false);
        ___onClose && ___onClose();

        if (isSuccess) {
            resetState();
        }
    };

    return {
        formTemplateId,
        formTemplateCreatorId,
        isVisible,
        isSuccess,
        isRestarting,
        setIsSuccess,
        onStartNew,
        onShow,
        onHide
    };
}
