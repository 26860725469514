// import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Tabs from './Tabs';
import SelectTemplate from './SelectTemplate';
import {
  TABS
} from './config';
import {
  resetData
} from './use/useInputs';
import Title from './Inputs/Title';
import Description from './Inputs/Description';
import Cover from './Inputs/Cover';
import Questions from './Inputs/Questions';
import Template from './Template';
import useFormCreate from './use/useFormCreate';
import styles from './index.module.scss';

export default function FormCreate() {
  const {
    t
  } = useTranslation();

  const {
    selectTemplate,
    setSelectTemplate,
    isVisible,
    onHide
  } = useFormCreate();

  const [activeTab, setActiveTab] = useState(TABS.TITLE);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const resetState = () => {
    setSelectTemplate(true);
    setActiveTab(TABS.TITLE);
    setActiveQuestionIndex(null);
    resetData();
  };

  const onSubmitted = () => {
    resetState();
    onHide();
  };

  const onDiscard = () => {
    if (window.confirm(t('forms.create.discard_confirmation'))) {
      resetState();
    }
  };

  const onCloseSelectTemplate = () => {
    setSelectTemplate(false);
  };

  const onSubmitTitle = () => {
    setActiveTab(TABS.DESCRIPTION);
  };

  const onSubmitDescription = () => {
    setActiveTab(TABS.COVER);
  };

  const onSubmitCover = () => {
    setActiveTab(TABS.QUESTIONS);
  };

  useEffect(() => {
    if (activeTab !== TABS.QUESTIONS) {
        setActiveQuestionIndex(null);
    }

    const onKeyDown = (e) => {
      const {
        key
      } = e;

      if (key === 'Enter') {
        if (activeTab === TABS.TITLE) {
          setActiveTab(TABS.DESCRIPTION);
        } else if (activeTab === TABS.DESCRIPTION) {
          setActiveTab(TABS.COVER);
        } else if (activeTab === TABS.COVER) {
          setActiveTab(TABS.QUESTIONS);
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [activeTab]);

  const renderSubmit = (_props) => {
    const {
      className,
      isDisabled,
      isValid,
      onClick,
      label
    } = _props;

    return (
      <Button
        className={`${styles.submit} ${isValid && styles.submit__isValid} ${className}`}
        isDisabled={isDisabled}
        onClick={onClick}>
        {label || t('forms.create.form.submit.next')}
      </Button>
    );
  };

  const renderBody = () => {
    switch (activeTab) {
      case TABS.TITLE:
        return (
          <Title
            t={t}
            onSubmit={onSubmitTitle}
            renderSubmit={renderSubmit} />
        );

      case TABS.DESCRIPTION:
        return (
          <Description
            t={t}
            onSubmit={onSubmitDescription}
            renderSubmit={renderSubmit} />
        );

      case TABS.COVER:
        return (
          <Cover
            t={t}
            onSubmit={onSubmitCover}
            renderSubmit={renderSubmit} />
        );

      case TABS.QUESTIONS:
        return (
          <Questions
            t={t}
            activeQuestionIndex={activeQuestionIndex}
            setActiveQuestionIndex={setActiveQuestionIndex}
            renderSubmit={renderSubmit} />
        );

      case TABS.TEMPLATE:
        return (
          <Template
            t={t} />
        );

      default:
        return null;
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal onClose={onHide}>
      <div className={styles.Root}>
        {selectTemplate
          ? <SelectTemplate
            className={styles.SelectTemplate}
            t={t}
            onClose={onCloseSelectTemplate} />
          : <>
            <Tabs
              className={styles.Tabs}
              t={t}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              activeQuestionIndex={activeQuestionIndex}
              setActiveQuestionIndex={setActiveQuestionIndex}
              onDiscard={onDiscard}
              onArchived={resetState}
              onSubmitted={onSubmitted} />

            <div className={styles.body}>
              {renderBody()}
            </div>
          </>
        }
      </div>
    </Modal>
  );
}
