import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import ActivityIndicator from 'components/ActivityIndicator';
import { ROUTES } from 'config';

import Fallback from './Fallback';
import SuspensedRoute from './SuspensedRoute';

const AppForm = lazy(() => import('containers/IFrame/AppForm'));
const AppView = lazy(() => import('containers/IFrame/AppView'));
const CheckIn = lazy(() => import('containers/IFrame/CheckIn'));
const AuthMap = lazy(() => import('containers/IFrame/AuthMap'));

export default function Router() {
    document.getElementById('viewport')
        .setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0');

    const routes = [{
        path: ROUTES.IFRAME.APP_FORM,
        Component: AppForm
    }, {
        path: ROUTES.IFRAME.APP_VIEW,
        Component: AppView
    }, {
        path: ROUTES.IFRAME.CHECK_IN,
        Component: CheckIn
    }, {
        path: ROUTES.IFRAME.AUTH_MAP,
        Component: AuthMap
    }];

    const renderRoute = (item, index) => {
        const { path, Component } = item;

        return (
            <Route
                key={index}
                path={path}
                exact={true}
                element={(
                    <SuspensedRoute
                        fallback={<Fallback body={<ActivityIndicator size={42} />} />}
                        Component={Component} />
                )} />
        );
    };

    return (
        <Routes>
            {routes.map(renderRoute)}
        </Routes>
    );
}
