import {
  lazy,
  Suspense
} from 'react';
import SuspenseFallback from 'components/SuspenseFallback';

const withSuspsense = (Component, props) => (
  <Suspense fallback={<SuspenseFallback className={(props || {}).className} />}>
    <Component {...props} />
  </Suspense>
);

// Input
const InputLazy = lazy(() => import('components/Input'));
const InputPhoneNumberLazy = lazy(() => import('components/Input/PhoneNumber'));
const InputCodeLazy = lazy(() => import('components/Input/Code'));
const InputDatePickerLazy = lazy(() => import('components/Input/DatePicker'));
const InputFileLazy = lazy(() => import('components/Input/File'));
const InputSearchLazy = lazy(() => import('components/Input/Search'));
const InputDateLazy = lazy(() => import('components/Input/Date'));
const InputTimeLazy = lazy(() => import('components/Input/Time'));
// -
const OutsideClickTriggerLazy = lazy(() => import('components/OutsideClickTrigger'));
const ActivityIndicatorLazy = lazy(() => import('components/ActivityIndicator'));
const SelectLanguageLazy = lazy(() => import('components/SelectLanguage'));
const SelectTagsLazy = lazy(() => import('components/SelectTags'));
const AvatarLazy = lazy(() => import('components/Avatar'));
const AvatarWithOnlineStateLazy = lazy(() => import('components/Avatar/AvatarWithOnlineState'));
const UserListLazy = lazy(() => import('components/UserList'));
const StaticAvatarsLazy = lazy(() => import('components/StaticAvatars'));
const ButtonLazy = lazy(() => import('components/Button'));
const ToggleLazy = lazy(() => import('components/Toggle'));
const AnchorLazy = lazy(() => import('components/Button/Anchor'));
const AddressesLazy = lazy(() => import('components/Addresses'));
const MapLazy = lazy(() => import('components/Map'));
const MapPicturesLazy = lazy(() => import('components/MapPictures'));
const MapSearchLazy = lazy(() => import('components/MapSearch'));
const MenuLazy = lazy(() => import('components/Menu'));
const ReactionPickerLazy = lazy(() => import('components/Reaction/Picker'));
const ReactionDisplayLazy = lazy(() => import('components/Reaction/Display'));
const TutorialFirstLoginLazy = lazy(() => import('components/Tutorial/FirstLogin'));
const AppViewLazy = lazy(() => import('components/AppView'));
const AppFormLazy = lazy(() => import('components/AppForm'));
const ProfileLazy = lazy(() => import('components/Profile'));
const ProfilePublicLazy = lazy(() => import('components/ProfilePublic'));
const EXTLazy = lazy(() => import('components/EXT'));
const ForwardLazy = lazy(() => import('components/Forward'));
const ChatAndUserPickerLazy = lazy(() => import('components/ChatAndUserPicker'));
const ChatAndUserPickerListLazy = lazy(() => import('components/ChatAndUserPicker/List'));
const PulseLazy = lazy(() => import('components/Pulse'));
const ChartLazy = lazy(() => import('components/Chart'));
const TooltipLazy = lazy(() => import('components/Tooltip'));
const FileLazy = lazy(() => import('components/File'));
const LinkPreviewLazy = lazy(() => import('components/LinkPreview'));
const MediaAnalyticsProviderLazy = lazy(() => import('components/MediaAnalytics'));
const SearchMessagesLazy = lazy(() => import('components/SearchMessages'));
const ShareFormTemplateLazy = lazy(() => import('components/ShareFormTemplate'));
const CardeLazy = lazy(() => import('components/Card'));
const PictureSearchLazy = lazy(() => import('components/PictureSearch'));
const NotificationsLazy = lazy(() => import('components/Notifications'));

// Input
export const Input = (props) => withSuspsense(InputLazy, props);
export const InputPhoneNumber = (props) => withSuspsense(InputPhoneNumberLazy, props);
export const InputCode = (props) => withSuspsense(InputCodeLazy, props);
export const InputFile = (props) => withSuspsense(InputFileLazy, props);
export const InputDatePicker = (props) => withSuspsense(InputDatePickerLazy, props);
export const InputSearch = (props) => withSuspsense(InputSearchLazy, props);
export const InputDate = (props) => withSuspsense(InputDateLazy, props);
export const InputTime = (props) => withSuspsense(InputTimeLazy, props);
// -
export const OutsideClickTrigger = (props) => withSuspsense(OutsideClickTriggerLazy, props);
export const ActivityIndicator = (props) => withSuspsense(ActivityIndicatorLazy, props);
export const SelectLanguage = (props) => withSuspsense(SelectLanguageLazy, props);
export const SelectTags = (props) => withSuspsense(SelectTagsLazy, props);
export const Avatar = (props) => withSuspsense(AvatarLazy, props);
export const AvatarWithOnlineState = (props) => withSuspsense(AvatarWithOnlineStateLazy, props);
export const UserList = (props) => withSuspsense(UserListLazy, props);
export const StaticAvatars = (props) => withSuspsense(StaticAvatarsLazy, props);
export const Button = (props) => withSuspsense(ButtonLazy, props);
export const Toggle = (props) => withSuspsense(ToggleLazy, props);
export const Anchor = (props) => withSuspsense(AnchorLazy, props);
export const Addresses = (props) => withSuspsense(AddressesLazy, props);
export const Map = (props) => withSuspsense(MapLazy, props);
export const MapPictures = (props) => withSuspsense(MapPicturesLazy, props);
export const MapSearch = (props) => withSuspsense(MapSearchLazy, props);
export const Menu = (props) => withSuspsense(MenuLazy, props);
export const ReactionPicker = (props) => withSuspsense(ReactionPickerLazy, props);
export const ReactionDisplay = (props) => withSuspsense(ReactionDisplayLazy, props);
export const TutorialFirstLogin = (props) => withSuspsense(TutorialFirstLoginLazy, props);
export const AppView = (props) => withSuspsense(AppViewLazy, props);
export const AppForm = (props) => withSuspsense(AppFormLazy, props);
export const Profile = (props) => withSuspsense(ProfileLazy, props);
export const ProfilePublic = (props) => withSuspsense(ProfilePublicLazy, props);
export const Forward = (props) => withSuspsense(ForwardLazy, props);
export const ChatAndUserPicker = (props) => withSuspsense(ChatAndUserPickerLazy, props);
export const ChatAndUserPickerList = (props) => withSuspsense(ChatAndUserPickerListLazy, props);
export const EXT = (props) => withSuspsense(EXTLazy, props);
export const Pulse = (props) => withSuspsense(PulseLazy, props);
export const Chart = (props) => withSuspsense(ChartLazy, props);
export const Tooltip = (props) => withSuspsense(TooltipLazy, props);
export const File = (props) => withSuspsense(FileLazy, props);
export const LinkPreview = (props) => withSuspsense(LinkPreviewLazy, props);
export const MediaAnalytics = (props) => withSuspsense(MediaAnalyticsProviderLazy, props);
export const SearchMessages = (props) => withSuspsense(SearchMessagesLazy, props);
export const ShareFormTemplate = (props) => withSuspsense(ShareFormTemplateLazy, props);
export const Card = (props) => withSuspsense(CardeLazy, props);
export const PictureSearch = (props) => withSuspsense(PictureSearchLazy, props);
export const Notifications = (props) => withSuspsense(NotificationsLazy, props);