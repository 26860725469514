
export enum EventName {
  ChatChannels = 'chat-channels',
  ChatUpdatedChannel = 'chat-updated-channel',
  ChatNewMessage = 'chat-new-message',
  ChatUpdatedMessage = 'chat-updated-message',
  ChatUserTyping = 'chat-user-typing',
  UsersOnlineIdMap = 'users-online-id-map',
  RightFrameView = 'right-frame-view',
}
