import { addToast } from 'utils/toaster';
import { Button } from 'components';
import i18next from 'i18next';

const styleRoot = {
    display: 'flex',
    flexDirection: 'column'
};
const styleButton = {
    alignSelf: 'flex-start',
    marginTop: '6px'
};

let lastErrorTime;

function onOffline() {
    const onReload = () => window.location.reload();
    const { t } = i18next;

    if (!lastErrorTime || (Date.now() - lastErrorTime >= 1000 * 60 * 1)) {
        lastErrorTime = Date.now();

        addToast((
            <div style={styleRoot}>
                {t('connection.error')}

                <Button
                    style={styleButton}
                    label={t('connection.button')}
                    onClick={onReload} />
            </div>
        ), {
            autoDismiss: false,
            appearance: 'error',
            id: 'offline',
        });
    }
}

window.addEventListener('offline', onOffline);
