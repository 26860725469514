import PropTypes from 'prop-types';
import {
  format
} from 'utils/datefns';
import {
  NativeLanguageName,
} from 'global';
import CountryFlag from 'components/CountryFlag';
import styles from './index.module.scss';

Template.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

function Template(props) {
  const {
    className,
    t,
    data,
    onSelect
  } = props;

  const {
    lang,
    downloads,
    created_at: createdAt,
    title,
    cover
  } = data;

  const isCoverImage = cover.indexOf('http') > -1;
  const date = new Date(createdAt);

  const onClickTemplate = () => {
    onSelect(data);
  };

  const renderLanguage = () => {
    const language = lang || 'en';

    return (
      <div>
        <CountryFlag
          language={lang} />

        {NativeLanguageName[language]}
      </div>
    );
  };

  return (
    <div
      className={`${styles.root} ${className}`}
      onClick={onClickTemplate}>
      <div className={styles.body}>
        <div
          style={{
            backgroundColor: !isCoverImage ? cover : null
          }}>
          {isCoverImage && (
            <img src={cover} alt='' />
          )}

          <div />
        </div>

        <strong>
          {title}
        </strong>

        <span>
          {format(date, 'MMM d yyyy')}
        </span>
      </div>

      <div className={styles.footer}>
        {renderLanguage()}

        <div>
          {t('forms.create.template_select.downloads', {
            amount: downloads || 0
          })}
        </div>
      </div>
    </div>
  );
}

export default Template;
