import PropTypes from 'prop-types';
import React, {
    useLayoutEffect,
    useRef
} from 'react';
import styles from './index.module.scss';

const __minHeight = 41;
const __maxHeight = 300;

const __style = {
    height: 0,
    minHeight: __minHeight
};

Text.propTypes = {
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
};

export default function Text(props) {
    const {
        t,
        onChange,
        value
    } = props;
    const refTextarea = useRef();

    const onChangeText = (e) => {
        refTextarea.current.style.height = '0px';
        onChange(e.target.value);
    };

    useLayoutEffect(() => {
        let nextHeight = Math.max(
            refTextarea.current.scrollHeight,
            __minHeight
        );

        if (nextHeight > __maxHeight) {
            nextHeight = __maxHeight;
        }

        refTextarea.current.style.height = `${nextHeight + 2}px`;
    }, [value]);

    return (
        <textarea
            ref={refTextarea}
            style={__style}
            className={styles.Root}
            placeholder={t('forms.respond.questions.title.placeholder')}
            value={value || ''}
            onChange={onChangeText} />
    );
}
