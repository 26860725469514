import firebase from 'firebase/compat/app';

let lastIssueTime = null;

export async function getBearerToken() {
    let isForceRefresh = false;

    if (!lastIssueTime) {
        lastIssueTime = Date.now();
    }

    // Force refresh if token is older than 45min
    if (Date.now() - lastIssueTime >= 1000 * 60 * 45) {
        isForceRefresh = true;
        lastIssueTime = Date.now();
    }

    const token = await firebase.auth().currentUser?.getIdToken(isForceRefresh);

    return `Bearer ${token || ''}`;
}
