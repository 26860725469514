
let __addToast;

export function registerToaster(props) {
    const { addToast } = props;

    __addToast = addToast;
}

export function addToast(...props) {
    __addToast && __addToast(...props);
}
