import { useState, useEffect } from 'react';
import { getGpsFromGeolocation } from 'utils/gps';

export function useGeoLocation(props) {
    const {
        isLive = false,
        liveDelay = (15 * 60 * 1000)
    } = props || {};
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            const location = await getGpsFromGeolocation();

            if (location) {
                const {
                    lat,
                    lng
                } = location;

                setLat(lat);
                setLng(lng);
            }

            setIsReady(true);
        };

        if (isLive) {
            setInterval(fetch, liveDelay);
        } else {
            fetch();
        }
    }, [isLive, liveDelay]);

    return {
        isReady,
        lat,
        lng
    };
}
