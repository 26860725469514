import { useState, useEffect, useCallback, useRef } from 'react';
import { apiAttendances, apiAddress } from 'api';
import { getUniqueId } from 'utils';
import {
    getGpsFromGeolocation,
    getDistanceBetweenGeolocations,
    getNearestAddressByGeolocation
} from 'utils/gps';
import { useGeoLocation } from 'utils/use/geoLocation';
import { useProfile, useUpdateProfile, fetchMe } from 'utils/use/me';
import { AT_LOCATION_DISTANCE_THRESHOLD } from 'config';

/**
 * IMPORTANT
 * IF ANYTHING CHANGES HERE - MAKE SURE TO APPLY THE CHANGES ON BOTH ENVIRONMENTS !
 */
export const STATES = {
    PROCESSING: 'PROCESSING',
    CHECKED_IN: 'CHECKED_IN',
    CHECKED_OUT: 'CHECKED_OUT'
};
const MAX_CHECK_IN_HOURS = 12;
const __listeners = {};

let __state = STATES.PROCESSING;

export function setState(nextState) {
    __state = nextState;

    for (const key in __listeners) {
        __listeners[key].setState(__state);
    }
}

function doCheckOut(attendanceId) {
    return apiAttendances.set({
        id: attendanceId,
        check_out: new Date().toISOString()
    });
}

async function tryAutoCheckIn(addresses, userLocation) {
    const { share_location: isAutoCheckIn } = await fetchMe();

    if (isAutoCheckIn) {
        const address = getNearestAddressByGeolocation(addresses, userLocation);

        if (address) {
            await apiAttendances.set({
                address_id: address.id,
                check_in: new Date().toISOString()
            });

            return true;
        }
    }

    return false;
}

async function startAutoAttendanceProcedure() {
    const prevState = __state;

    setState(STATES.PROCESSING);

    try {
        const userLocation = await getGpsFromGeolocation();
        const { data: addresses } = await apiAddress.get();
        const { data: attendances } = await apiAttendances.get();
        const lastAttendance = attendances[0];
        let isCheckedOut = false;

        if (lastAttendance) {
            const { id, address_id: addressId, check_in: checkIn, check_out: checkOut } = lastAttendance;
            const checkInDate = new Date(checkIn);

            if (!checkOut) {
                const hours = (((Date.now() - checkInDate.getTime()) / 1000) / 60) / 60;

                if (hours < MAX_CHECK_IN_HOURS) {
                    const findAddress = ({ id }) => id === addressId;
                    const lastCheckInAddress = addresses.find(findAddress);

                    if (lastCheckInAddress) {
                        if (userLocation) {
                            const distance = getDistanceBetweenGeolocations(userLocation, {
                                lat: lastCheckInAddress.lat,
                                lng: lastCheckInAddress.lng
                            });

                            if (distance > AT_LOCATION_DISTANCE_THRESHOLD) {
                                // more than X km away from last check in location
                                // check out from there
                                await doCheckOut(id);

                                isCheckedOut = true;
                            }
                        } else {
                            // no location shared - force check out
                            await doCheckOut(id);

                            isCheckedOut = true;
                        }
                    } else {
                        throw new Error('Could not find last check-in address in addresses.');
                    }
                } else {
                    // more than 12 hours past since the last check-in
                    // check out from that attendance
                    await doCheckOut(id);

                    isCheckedOut = true;
                }
            } else {
                // last attendance is already checked-out
                isCheckedOut = true;
            }
        } else {
            // There was no attendance yet
            isCheckedOut = true;
        }

        if (isCheckedOut && userLocation && await tryAutoCheckIn(addresses, userLocation)) {
            setState(STATES.CHECKED_IN);
            return;
        }

        setState(isCheckedOut ? STATES.CHECKED_OUT : STATES.CHECKED_IN);
    } catch (err) {
        setState(prevState);
    }
}

export function useAutoCheckIn() {
    const {
        id: userId,
        share_location: isAutoCheckIn
    } = useProfile();
    const {
        isReady: isReadyGeo
    } = useGeoLocation({
        isLive: isAutoCheckIn
    });
    const refLastIsAutoCheckIn = useRef(isAutoCheckIn);
    const refLastProcedureTimestamp = useRef(null);
    const isReady = isReadyGeo && !!userId;

    useEffect(() => {
        const timestamp = Date.now();

        if (isReady) {
            if (!refLastProcedureTimestamp.current ||
                (9 * 60 * 1000) >= (timestamp - refLastProcedureTimestamp.current) ||
                refLastIsAutoCheckIn.current !== isAutoCheckIn) {
                refLastProcedureTimestamp.current = timestamp;
                startAutoAttendanceProcedure();
            }
        }

        refLastIsAutoCheckIn.current = isAutoCheckIn;
    }, [isReady, isAutoCheckIn]);

    useEffect(() => {
        if (isReady) {
            const callback = () => {
                const timestamp = Date.now();

                if (!refLastProcedureTimestamp.current ||
                    (9 * 60 * 1000) >= (timestamp - refLastProcedureTimestamp.current)) {
                    refLastProcedureTimestamp.current = timestamp;
                    startAutoAttendanceProcedure();
                }
            };
            const interval = setInterval(callback, 10 * 60 * 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [isReady]);
}

export function useAutoCheckInState() {
    const { share_location: isAutoCheckIn } = useProfile();
    const { isProcessing: isProcessingToggleAutoCheckIn, update } = useUpdateProfile();
    const [state, setState] = useState(__state);

    const onToggleAutoCheckIn = useCallback(() => {
        update({ share_location: !isAutoCheckIn });
    }, [isAutoCheckIn, update]);

    useEffect(() => {
        const key = getUniqueId();

        __listeners[key] = { setState };

        return () => {
            delete __listeners[key];
        };
    }, []);

    return {
        state,
        isAutoCheckIn,
        onToggleAutoCheckIn,
        isProcessingToggleAutoCheckIn
    };
}
