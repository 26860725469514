import { useState, useEffect } from 'react';
import { getUniqueId } from 'utils';
import { SK_ACTIVE_CHANNEL_ID } from 'config';

const __listeners = {};
let __activeChannelId = localStorage.getItem(SK_ACTIVE_CHANNEL_ID);

export function setActiveChannelId(channelId) {
    __activeChannelId = channelId;

    if (channelId) {
        localStorage.setItem(SK_ACTIVE_CHANNEL_ID, channelId);
    } else {
        localStorage.removeItem(SK_ACTIVE_CHANNEL_ID);
    }

    for (const key in __listeners) {
        __listeners[key](channelId);
    }
}

export function getActiveChannelId() {
    return __activeChannelId;
}

export function useChatActiveChannel() {
    const [channelId, setChannelId] = useState(__activeChannelId);

    useEffect(() => {
        const key = getUniqueId();

        __listeners[key] = setChannelId;

        return () => {
            delete __listeners[key];
        };
    }, []);

    return {
        channelId
    };
}
