import {
  useState,
  useEffect
} from 'react';
import {
  apiForms
} from 'api';
import {
  addFormTemplate
} from 'utils/forms/useFormTemplates';
import {
  getUserId
} from 'utils/use/me';
import {
  getUniqueId
} from 'utils';
import {
  Language,
} from 'global';

// for questions only
const __listeners = {};

let __title;
let __description;
let __cover;
let __attachments;
let __questions;
let __config;
let __templateId;

resetData();

function resetData() {
    __title = '';
    __description = '';
    __cover = '';
    __attachments = [];
    __questions = [];
    __config = {};
    __templateId = null;
}

function setData(props) {
  const {
    id,
    title,
    description,
    cover,
    attachments: _attachments,
    items: _items
  } = props;

  let attachments = _attachments || [];
  let items = _items || [];

  if (typeof _attachments === 'string') {
    try {
      attachments = JSON.parse(_attachments);
    } catch (err) {
      attachments = [];
    }
  }

  if (typeof items === 'string') {
    try {
      items = JSON.parse(items);
    } catch (err) {
      items = [];
    }
  }

  const cleanQuestionItem = (item) => {
    delete item.id;
    delete item.summary;
    delete item.required;

    return item;
  };

  __title = title || '';
  __description = description || '';
  __cover = cover || '';
  __attachments = attachments;
  __questions = items.map(cleanQuestionItem);
  __templateId = id || null;
}

function isExistingTemplate() {
  return !!__templateId;
}

export function useTitle() {
    const [value, setValue] = useState(__title);

    __title = value;

    return {
        value,
        setValue
    };
}

export function getTitle() {
    return __title;
}

export function useDescription() {
    const [value, setValue] = useState(__description);

    __description = value;

    return {
        value,
        setValue
    };
}

export function useAttachments() {
    const [value, setValue] = useState(__attachments);

    __attachments = value;

    return {
        value,
        setValue
    };
}

export function useCover() {
    const [value, setValue] = useState(__cover);

    __cover = value;

    return {
        value,
        setValue
    };
}

export function setQuestions(value) {
    if (typeof value === 'function') {
        __questions = value(__questions);
    } else {
        __questions = value;
    }

    for (const key in __listeners) {
        __listeners[key](__questions);
    }
}

export function getQuestions() {
    return __questions;
}

export function useQuestionsValue() {
    const [value, setValue] = useState(__questions);

    useEffect(() => {
        const key = getUniqueId();

        __listeners[key] = setValue;

        return () => {
            delete __listeners[key];
        };
    }, []);

    return value;
}

function useConfig() {
  const [config, setConfig] = useState(__config);

  __config = config;

  return {
    config,
    setConfig
  };
}

export async function createFormTemplate() {
  try {
    const {
      isTemplate,
      language,
      tags
    } = __config;
  
    const payload = {
      creator_id: getUserId(),
      title: __title,
      description: __description,
      attachments: __attachments,
      cover: __cover,
      items: __questions,
      is_global_template: isTemplate || false,
      lang: language || Language.English,
      tags: tags || []
    };

    if (__templateId) {
      payload.duplicate_template_id = __templateId;
    }

    const {
      data
    } = await apiForms.saveFormTemplate(payload);

    if (!isTemplate) {
      addFormTemplate(data);
    }

    resetData();

    return data;
  } catch (err) {
    return false;
  }
}

async function editFormTemplate() {
  try {
    const {
      language,
      tags
    } = __config;

    await apiForms.saveFormTemplate({
      id: __templateId,
      title: __title,
      description: __description,
      attachments: __attachments,
      cover: __cover,
      items: __questions,
      lang: language || Language.English,
      tags: tags || []
    });
  } catch (err) {
    //
  }
}

async function archiveFormTemplate() {
  try {
    await apiForms.saveFormTemplate({
      id: __templateId,
      status: -1
    });
  } catch (err) {
    //
  }
}

export {
  useConfig,
  setData,
  resetData,
  isExistingTemplate,
  editFormTemplate,
  archiveFormTemplate
};
