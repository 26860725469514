
export function sortUserByName(itemA, itemB) {
    const {
        display_name: a
    } = itemA || {};
    const {
        display_name: b
    } = itemB || {};

    if (!b) return -1;

    if (!a) return 1;

    return a.localeCompare(b);
}
