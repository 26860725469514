import PropTypes from 'prop-types';
import React, {
    useState
} from 'react';
import {
    ActivityIndicator
} from 'components';
import styles from './index.module.scss';

Image.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
};

export default function Image(props) {
    const {
        className,
        src,
        alt
    } = props;
    const [isLoading, setIsLoading] = useState(true);

    const onLoad = () => {
        setIsLoading(false);
    };

    return (
        <div className={`${styles.Root} ${className}`}>
            <img
                className={className}
                onLoad={onLoad}
                src={src}
                alt={alt || ''} />

            {isLoading && (
                <ActivityIndicator
                    className={styles.ActivityIndicator}
                    color='white' />
            )}
        </div>
    );
}
