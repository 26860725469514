import i18next from 'i18next';
import i18nextLanguageDetector from 'i18next-browser-languagedetector';
import i18nextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import {
    // APP_ENV,
    // APP_ENVS,
    APP_URL,
    SK_APP_LANGUAGE
} from 'config';
import {
    DefaultLanguage,
} from 'global';

function getActiveLanguage() {
    const queries = window.location.search.slice(1).split('&');

    for (let i = 0, len = queries.length; i < len; i++) {
        const keyValue = queries[i].split('=');
        const key = keyValue[0];
        const value = keyValue[1];

        if (key === 'language') {
            if (value) {
                return value;
            }

            break;
        }
    }

    const storedLanguage = localStorage.getItem(SK_APP_LANGUAGE);

    if (storedLanguage) {
        return storedLanguage;
    }

    return DefaultLanguage;
}

const activeLanguage = getActiveLanguage();

i18next.setDefaultNamespace('web');
i18next
    .use(i18nextHttpBackend)
    .use(i18nextLanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: DefaultLanguage,
        // debug: APP_ENV === APP_ENVS.LOCAL,
        debug: false,

        interpolation: {
            escapeValue: false
        },

        lng: activeLanguage,
        preload: [activeLanguage],

        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true
        },

        backend: {
            loadPath: `${APP_URL}locales/{{lng}}/translation-{{ns}}.json`
        }
    });

i18next.on('languageChanged', (lng) => {
    localStorage.setItem(SK_APP_LANGUAGE, lng);
});
