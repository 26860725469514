import { EventName, events } from 'global';
import {
  FrameType,
} from './config';

export * from './config';
export * from './types';

class RightFrameView {
  private static instance: RightFrameView;
  public static getInstance(): RightFrameView {
    if (!RightFrameView.instance) {
      RightFrameView.instance = new RightFrameView();
    }

    return RightFrameView.instance;
  }

  private constructor() {}

  public show(
    frameType: FrameType,
    props?: Record<string, any>,
  ): void {
    events().trigger(
      EventName.RightFrameView,
      {
        frameType,
        props: props || {},
      },
    );
  }

  public hide = (): void => {
    events().trigger(
      EventName.RightFrameView,
      {
        frameType: null,
        props: {},
      },
    );
  }

}

const rightFrameView = (): RightFrameView => {
  return RightFrameView.getInstance();
};

export default rightFrameView;
