import React from 'react';
import { ActivityIndicator } from 'components';
import styles from './index.module.scss';
// Assets
import ImageLogo from './assets/storewise.svg';

export default function Component() {
    return (
        <div className={styles.Component}>
            <img
                className={styles.Component__logo}
                src={ImageLogo}
                alt='StoreWise' />

            <ActivityIndicator
                className={styles.Component__ActivityIndicator}
                size={38} />
        </div>
    );
}
