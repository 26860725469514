import {
    useOutsideClick
} from './outsideClick';

let __onToggle = null;
let __onClose = null;

//  <ContextMenu />
export function useContextMenuCore(props) {
    const {
        setData,
        ref
    } = props;

    __onToggle = setData;
    __onClose = () => setData(null);

    useOutsideClick(ref, __onClose);
}

// consumers
export function useContextMenu(props) {
    const {
        items
    } = props;

    const onContextMenu = (e) => {
        if (items?.length) {
            e.preventDefault();
            __onToggle({
                items,
                top: e.clientY,
                left: e.clientX
            });
        }
    };

    const onClose = () => __onClose(null);

    return {
        onContextMenu,
        onClose
    };
}
