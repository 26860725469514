import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { ActivityIndicator } from 'components';
import styles from './index.module.scss';

Button.propTypes = {
    className: PropTypes.string,
    classNameDisabled: PropTypes.string,
    isDisabled: PropTypes.bool,
    isProcessing: PropTypes.bool,
    label: PropTypes.any,
    onClick: PropTypes.func,
    children: PropTypes.any,
    sizeActivityIndicator: PropTypes.number,
    colorActivityIndicator: PropTypes.string,
    debounceDelay: PropTypes.number
};

Button.defaultProps = {
    className: '',
    classNameDisabled: '',
    isDisabled: false,
    isProcessing: false,
    label: '',
    sizeActivityIndicator: 24,
    debounceDelay: 150
};

export default function Button(props) {
    const {
        className, classNameDisabled, isDisabled, isProcessing, onClick: _onClick,
        label, children, sizeActivityIndicator, colorActivityIndicator, debounceDelay,
        ..._props
    } = props;
    const isDebounce = useRef(false);
    const classIsProcessing = isProcessing ? styles.isProcessing : '';
    const classIsDisabled = isDisabled ? `${styles.isDisabled} ${classNameDisabled}` : '';

    const onClick = (e) => {
        if (!isDebounce.current && !isProcessing && !isDisabled) {
            isDebounce.current = true;

            _onClick && _onClick(e);
            setTimeout(() => {
                isDebounce.current = false;
            }, debounceDelay);
        }
    };

    return (
        <button
            {..._props}
            className={`${classIsProcessing} ${classIsDisabled} ${className}`}
            onClick={onClick}>
            {isProcessing
                ? <ActivityIndicator
                    color={colorActivityIndicator}
                    size={sizeActivityIndicator} />
                : children || label
            }
        </button>
    );
}
