import PropTypes from 'prop-types';
import React, {
  useState
} from 'react';
import {
  ActivityIndicator
} from 'components';
import {
  useGlobalTemplates
} from '../use/useGlobalTemplates';
import {
  setData,
  resetData
} from '../use/useInputs';
import Filters, {
  filterTemplates
} from './Filters';
import Template from './Template';
import styles from './index.module.scss';
// Assets
import IconTemplates from './assets/templates.svg';
import IconPlus from './assets/plus.svg';
import IconClose from './assets/close.svg';

// const templates = [
//   {
//     id: '1231',
//     lang: 'en',
//     downloads: 23,
//     created_at: 100000000,
//     title: 'sdfg sdfgsdf',
//     cover: 'yellow',
//     is_global_template: true
//   }
// ];

const reduceTagsFromTemplate = (tags, template) => {
  if (template.tags?.length) {
    const nextTags = [...tags];

    for (const index in template.tags) {
      if (nextTags.indexOf(template.tags[index]) === -1) {
        nextTags.push(template.tags[index]);
      }
    }

    return nextTags;
  }

  return tags;
};

SelectTemplate.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

function SelectTemplate(props) {
  const {
    className,
    t,
    onClose
  } = props;

  const [selectTemplate, setSelectTemplate] = useState(false);
  const [filters, setFilters] = useState({});
  const {
    isLoading: isLoadingTemapltes,
    templates
  } = useGlobalTemplates();
  const availableTags = templates.reduce(reduceTagsFromTemplate, []);
  const templatesFiltered = filterTemplates(
    templates,
    filters
  );

  const onSelectTemplate = (template) => {
    setData(template);
    onClose();
  };

  const onCancelTemplateSelection = () => {
    setSelectTemplate(false);
  };

  const buttons = [
    {
      label: t('forms.create.template_select.browse_templates'),
      icon: IconTemplates,
      onClick: () => {
        setSelectTemplate(true);
      }
    }, {
      label: t('forms.create.template_select.blank_card'),
      icon: IconPlus,
      onClick: () => {
        resetData();
        onClose();
      }
    }
  ];

  const renderButton = (item, index) => {
    const {
      label,
      icon,
      onClick
    } = item;

    return (
      <div
        key={index}
        onClick={onClick}>
        <div>
          <img src={icon} alt='' />
        </div>

        <span>
          {label}
        </span>
      </div>
    );
  };

  const renderTemplate = (item) => {
    return (
      <Template
        key={item.id}
        t={t}
        data={item}
        onSelect={onSelectTemplate} />
    );
  };

  return (
    <div className={`${styles.root} ${className}`}>
      {selectTemplate
        ? <div className={styles.templates}>
            <div className={styles.templatesHeader}>
              <span>
                {t('forms.create.template_select.select_a_template')}

                {isLoadingTemapltes && (
                  <span>
                    <ActivityIndicator size={16} />
                  </span>
                )}
              </span>

              <div onClick={onCancelTemplateSelection}>
                <img src={IconClose} alt='' />
              </div>
            </div>

            <Filters
              t={t}
              className={styles.templateFilters}
              availableTags={availableTags}
              onChange={setFilters} />

            {!templates.length && !isLoadingTemapltes
              ? <div className={styles.templatesListEmpty}>
                  {t('forms.create.template_select.no_templates')}
                </div>
              : <div className={styles.templatesList}>
                  {templatesFiltered.map(renderTemplate)}
                </div>
            }
          </div>
        : <div className={styles.buttons}>
            {buttons.map(renderButton)}
          </div>
      }
    </div>
  );
}

export default SelectTemplate;
