// import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useCameraCore } from 'utils/use/useCamera';
import { Button } from 'components';
import { useIframeRequest } from 'utils/use/useIframeRequest';
import { useUpload } from 'utils/use/upload';
import Webcam from 'react-webcam';
import BarcodeReader from './BarcodeReader';
import { cloudinaryWaterMarkImage } from 'utils';
import { useProfile, useOrg } from 'utils/use/me';
import isMobile from 'is-mobile';
import styles from './index.module.scss';
// Assets
import IconClose from './assets/close.svg';
// import IconSubmit from './assets/submit.svg';

function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File(
        [u8arr],
        filename,
        { type: mime }
    );
}

export default function Camera() {
    const refCamera = useRef();
    const refCallbackOnChangeProcessingState = useRef();
    const refCallbackOnTakePicture = useRef();
    const [isBarcodeScanner, setIsBarcodeScanner] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const {
        upload
    } = useUpload({
        isGpsRequired: true,
        isPostProcess: true
    });
    const { requestAuthToken } = useIframeRequest();
    const { display_name: userName } = useProfile();
    const { name: orgName } = useOrg();
    const videoConstraints = isMobile() ? { facingMode: { exact: 'environment' } } : { facingMode: 'user' };

    useCameraCore({
        setIsVisible,
        setIsBarcodeScanner,
        refCallbackOnTakePicture,
        refCallbackOnChangeProcessingState
    });

    const onClose = () => {
        setIsVisible(false);

        refCallbackOnChangeProcessingState.current = null;
        refCallbackOnTakePicture.current = null;
    };
    const onTakePicture = () => {
        const imageSrc = refCamera.current.getScreenshot();

        if (imageSrc) {
            const file = dataURLtoFile(imageSrc, 'picture.jpg');

            refCallbackOnChangeProcessingState.current && refCallbackOnChangeProcessingState.current(true);
            setIsVisible(false);

            requestAuthToken(async (authToken) => {
                const {
                    uris
                } = await upload({
                    files: [file],
                    authToken
                });

                refCallbackOnTakePicture.current && refCallbackOnTakePicture.current(
                    cloudinaryWaterMarkImage(uris[0], {
                        userName,
                        orgName,
                        size: 1280
                    })
                );
                refCallbackOnChangeProcessingState.current && refCallbackOnChangeProcessingState.current(false);
                onClose();
            });
        }
    };
    const onTakeBarcode = (barcode) => {
        if (barcode && refCallbackOnTakePicture.current) {
            refCallbackOnTakePicture.current(barcode);
        }

        onClose();
    };

    if (!isVisible) {
        return null;
    }

    return createPortal(
        <div className={styles.Component}>
            {isBarcodeScanner
                ? <BarcodeReader
                    onSubmit={onTakeBarcode} />
                : <Webcam
                    ref={refCamera}
                    className={styles.Webcam}
                    screenshotFormat='image/jpeg'
                    videoConstraints={videoConstraints} />
            }

            <Button
                className={styles.close}
                onClick={onClose}>
                <img src={IconClose} alt='' />
            </Button>

            {!isBarcodeScanner && (
                <Button
                    className={styles.submit}
                    onClick={onTakePicture} />
            )}
        </div>,
        window.document.body
    );
}
