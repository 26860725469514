import PropTypes from 'prop-types';
import React, {
  useState
} from 'react';
import {
  apiDocument
} from 'api';
import {
  downloadRemoteFile
} from 'utils/download';
import {
  useChatReply
} from 'utils/use/chatReply';
import {
  useMediaData
} from 'utils/use/useMediaData';
import {
  useUser
} from 'utils/use/useUser';
import {
  format
} from 'date-fns';
import {
  useTranslation
} from 'react-i18next';
import {
  ActivityIndicator,
  Avatar,
  Button,
  Map
} from 'components';
import {
  addToast
} from 'utils/toaster';
import {
  getSourceUrlFromCLoudinaryUrl
} from 'utils/cloudinary';
import Table from './Table';
import ImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import styles from './index.module.scss';
// Assets
import IconBack from './assets/back.svg';
import IconMarker from './assets/marker.png';
import IconScan from './assets/scan.svg';
import IconCheck from './assets/check.svg';
import IconCells from './assets/cells.svg';
import IconReply from './assets/reply.svg';
import IconImage from './assets/image.svg';
import IconForward from './assets/forward.svg';
import IconDownload from './assets/download.svg';
import rightFrameView, { FrameType } from 'containers/App/RightFrameView';

function jpgToJpegIfJpg(extension) {
  if (extension === 'jpg') {
    return 'jpeg';
  }

  return extension;
}

let __setIsVisible;
let __props;

ImageView.propTypes = {
  onClose: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  user: PropTypes.object,
  message: PropTypes.object
};

function ImageView(props) {
  const {
    onClose,
    image,
    message,
    user: _userFromProps
  } = props;

  const {
    user: userFromApi
  } = useUser({
    id: !_userFromProps && message?.user_id,
    public: false
  });

  const user = _userFromProps || userFromApi;

  const {
    t
  } = useTranslation();

  const {
    setReply
  } = useChatReply();

  const {
    isLoading,
    mediaData
  } = useMediaData(image);
  const {
    label_tags: labelTags,
    logo_tags: logoTags,
    gps_verified: isGpsVerified,
    ocr_tags: ocrTags,
    lat,
    lng,
    address,
    date,
    fullAddress
  } = mediaData || {};
  const dateString = date ? format(new Date(date), 'dd/MM/yyyy - HH:mm (O)') : '';

  const [isImageTableDataVisible, setIsImageTableDataVisible] = useState(false);
  const [isImageTableDataError, setIsImageTableDataError] = useState(false);
  const [imageTableData, setImageTableData] = useState(null);

  const [isCopyOcrSuccess, setIsCopyOcrSuccess] = useState(false);
  const [isProcessingDownload, setIsProcessingDownload] = useState(false);
  const [isProcessingToDocument, setIsProcessingToDocument] = useState(false);

  const onProcessToDocument = async () => {
    if (imageTableData) {
      setIsImageTableDataVisible((prevState) => {
        return !prevState;
      });

      return;
    }

    setIsProcessingToDocument(true);

    try {
      const extension = image.slice(
        image.lastIndexOf('.') + 1
      );

      const {
        data
      } = await apiDocument.parseImage({
        url: getSourceUrlFromCLoudinaryUrl(image),
        mime_type: `image/${jpgToJpegIfJpg(extension)}`
      });

      if (!data.table?.length) {
        throw new Error();
      }

      setImageTableData(data.table);
      setIsImageTableDataVisible(true);
    } catch (err) {
      setIsImageTableDataError(true);
      addToast(
        t('mediaanalytics.document_ai_error'),
        {
          appearance: 'error'
        }
      );
    } finally {
      setIsProcessingToDocument(false);
    }
  };

  const onCopyOcrTags = () => {
    navigator.clipboard.writeText(
      ocrTags.join('\n')
    );

    setIsCopyOcrSuccess(true);

    setTimeout(() => {
      if (__props) {
        setIsCopyOcrSuccess(false);
      }
    }, 1000);
  };

  const onDownload = async () => {
    setIsProcessingDownload(true);
    await downloadRemoteFile(image);
    setIsProcessingDownload(false);
  };

  const onReply = () => {
    onClose();
    setReply(message);
  };

  const onForward = () => {
    onClose();
    rightFrameView().show(
      FrameType.Forward,
      {
        message,
      },
    );
  };

  const renderValue = (value, index) => {
    return (
      <div key={index}>
        {value}
      </div>
    );
  };

  return (
    <>
      <div
        className={styles.backgroud}
        onClick={onClose} />

      <div className={styles.root}>
        <div className={styles.header}>
          <div>
            <Button
              className={styles.button}
              onClick={onClose}>
              <img src={IconBack} alt='' />
            </Button>

            {isLoading && (
              <ActivityIndicator
                className={styles.ActivityIndicator}
                size={20}
                color='#fff' />
            )}
          </div>

          <div>
            {!isImageTableDataError && (
              <Button
                className={styles.button}
                isProcessing={isProcessingToDocument}
                colorActivityIndicator='#fff'
                onClick={onProcessToDocument}>
                <img
                  src={isImageTableDataVisible ? IconImage : IconCells}
                  alt='' />
              </Button>
            )}

            {!!ocrTags?.length && (
              <Button
                className={styles.button}
                isDisabled={isCopyOcrSuccess}
                onClick={onCopyOcrTags}>
                <img src={isCopyOcrSuccess ? IconCheck : IconScan} alt='' />
              </Button>
            )}

            <Button
              className={styles.button}
              isProcessing={isProcessingDownload}
              colorActivityIndicator='#fff'
              onClick={onDownload}>
              <img src={IconDownload} alt='' />
            </Button>

            {!!message && (
              <>
                <Button
                  className={styles.button}
                  onClick={onReply}>
                  <img src={IconReply} alt='' />
                </Button>

                <Button
                  className={styles.button}
                  onClick={onForward}>
                  <img src={IconForward} alt='' />
                </Button>
              </>
            )}
          </div>
        </div>

        <div className={styles.body}>
          {!!user && (
            <div className={styles.user}>
              <Avatar
                size={36}
                src={user.photo_url}
                alt={user.display_name} />

              <div>
                {user.display_name}
              </div>
            </div>
          )}

          {!!dateString && (
            <div className={styles.date}>
              Date: {dateString}
            </div>
          )}

          {isImageTableDataVisible
            ? <Table data={imageTableData} />
            : <div
              className={styles.image}
              onClick={onClose}
              style={{
                height: window.innerHeight * 0.7
              }}>
              <img src={image} alt='' />

              <ImageZoom
                className={styles.ImageZoom}
                src={image}
                zoomType='hover'
                zoomSrc={image}
                hideHint={true} />
            </div>
          }

          {!!fullAddress && (
            <div className={styles.place}>
              <div>
                {t('mediaanalytics.place', {
                  place: fullAddress
                })}

                {isGpsVerified && (
                  <img src={IconMarker} alt='' />
                )}
              </div>

              <Map
                className={styles.Map}
                isInteractive={true}
                center={[lng, lat]}
                centerText={address || ''} />
            </div>
          )}

          {!!labelTags?.length && (
            <div className={styles.tags}>
              <span>
                {t('mediaanalytics.tags')}
              </span>

              <div>
                {labelTags.map(renderValue)}
              </div>
            </div>
          )}

          {!!logoTags?.length && (
            <div className={styles.tags}>
              <span>
                {t('mediaanalytics.brands_and_logos')}
              </span>

              <div>
                {logoTags.map(renderValue)}
              </div>
            </div>
          )}

          {!!ocrTags?.length && (
            <div className={styles.text}>
              <span>
                {t('mediaanalytics.extracted_text')}
              </span>

              <div>
                {renderValue(ocrTags[0])}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function Provider() {
  const [isVisible, setIsVisible] = useState(false);

  __setIsVisible = setIsVisible;

  if (isVisible) {
    const onClose = () => {
      if (typeof __props.onClose === 'function') {
        __props.onClose();
      }

      __props = null;

      setIsVisible(false);
    };

    return (
      <ImageView
        {...__props}
        onClose={onClose} />
    );
  }

  return null;
}

function openImageView(props) {
  __props = props;

  __setIsVisible(true);
}

export {
  openImageView,
  Provider
};

export default ImageView;
