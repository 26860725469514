import {
    useState,
    useEffect
} from 'react';
import {
    apiForms,
    apiAddress
} from 'api';
import {
    getGpsFromGeolocation,
    getNearestAddressByGeolocation
} from 'utils/gps';
import {
    WORKITEM_SURVEY_QUESTION_TYPES
} from 'config/workitem';

export default function useFormTemplate(props) {
    const {
        formTemplateId,
        formTemplateCreatorId
    } = props;
    const [formTemplate, setFormTemplate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [questionsResponse, setQuestionsResponse] = useState(null);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
    const [addresses, setAddresses] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setActiveQuestionIndex(0);
            setFormTemplate(null);
            setQuestionsResponse(null);
            setIsLoading(true);
            setError(false);

            try {
                const {
                    data
                } = await apiForms.getOneTemplate(
                    formTemplateId,
                    formTemplateCreatorId
                );
                let parsedAttachments = [];
                let parsedItems = {};
                let nextQuestionsResponse = {};

                try {
                    parsedAttachments = JSON.parse(data.attachments);
                    parsedItems = JSON.parse(data.items);
                    nextQuestionsResponse = JSON.parse(data.items);

                    for (let i = 0, len = nextQuestionsResponse.length; i < len; i++) {
                        const {
                            type
                        } = nextQuestionsResponse[i];

                        if (type === WORKITEM_SURVEY_QUESTION_TYPES.DATE ||
                            type === WORKITEM_SURVEY_QUESTION_TYPES.TIME) {
                            nextQuestionsResponse[i].value = Date.now();
                        } else if (type === WORKITEM_SURVEY_QUESTION_TYPES.LOCATION) {
                            const {
                                data: addresses
                            } = await apiAddress.get();
                            const userLocation = await getGpsFromGeolocation();

                            if (userLocation) {
                                const nearestAddress = getNearestAddressByGeolocation(
                                    addresses,
                                    userLocation
                                );

                                if (nearestAddress) {
                                    nextQuestionsResponse[i].value = {
                                        id: nearestAddress.id,
                                        name: nearestAddress.name
                                    };
                                }
                            }


                            setAddresses(addresses);
                        }
                    }
                } catch (error) {
                    //
                }

                setActiveQuestionIndex(
                    data?.description || parsedAttachments?.length
                        ? -1
                        : 0
                );
                setQuestionsResponse(nextQuestionsResponse);
                setFormTemplate({
                    ...data,
                    attachments: parsedAttachments,
                    items: parsedItems
                });
            } catch (err) {
                setError(true);
            } finally {
                setIsLoading(false);
            }
        };

        if (formTemplateId) {
            fetchData();
        }
    }, [formTemplateId, formTemplateCreatorId]);

    return {
        formTemplate,
        activeQuestionIndex,
        setActiveQuestionIndex,
        questionsResponse,
        setQuestionsResponse,
        addresses,
        setAddresses,
        isLoading,
        error,
    };
}
