import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import FallbackSuspense from 'components/Splash';
import {
    REDIRECT_ROUTE__SIGNED_IN__TO__SIGNED_OUT,
    REDIRECT_ROUTE__SIGNED_OUT__TO__SIGNED_IN
} from 'config';

SuspensedRoute.propTypes = {
    isSignedIn: PropTypes.bool,
    isSignedInOnly: PropTypes.bool,
    isSignedOutOnly: PropTypes.bool,
    fallback: PropTypes.object,
    Component: PropTypes.object.isRequired
};

export default function SuspensedRoute(props) {
    const {
        Component, fallback,
        isSignedIn, isSignedInOnly, isSignedOutOnly
    } = props;
    const location = useLocation();

    if (isSignedInOnly) {
        if (!isSignedIn) {
            return (
                <Navigate
                    to={REDIRECT_ROUTE__SIGNED_IN__TO__SIGNED_OUT}
                    state={{ from: location }}
                    replace={true} />
            );
        }
    } else if (isSignedOutOnly) {
        if (isSignedIn) {
            return (
                <Navigate
                    to={REDIRECT_ROUTE__SIGNED_OUT__TO__SIGNED_IN}
                    state={{ from: location }}
                    replace={true} />
            );
        }
    }

    return (
        <Suspense fallback={fallback || <FallbackSuspense />}>
            <Component />
        </Suspense>
    );
}
