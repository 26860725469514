import axios from 'axios';
import packageJson from '../../package.json';

/**
 * window & process.env constants
 */
export const APP_ENV = process.env.REACT_APP_ENV;
export const APP_URL = window.location.href.includes('localhost') ? 'http://localhost:3000/' : process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
export const GOOGLE_API_KEY = 'AIzaSyCD9MTwyQrwrd8VDFAzkwi5-3RxhqQCmnw';
export const GOOGLE_MAP_ID = 'd630f40bc18d5079';
export const PUSHER_AUTH_URL = `${API_URL}/v2/pusher`;
export const CLOUDINARY_NOTIFICATION_URL = `${API_URL}/media-analytics`;
export const CLOUDINARY_URL = 'https://res.cloudinary.com/storewise/';
export const CLOUDINARY_API = 'https://api.cloudinary.com/v1_1/storewise/';
export const CLOUDINARY_API_KEY = '166938655983154';
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGFtaWVua29wcCIsImEiOiJjanE1Z2I2aTcxeTF3M3hwcGJqYXN1MWU1In0.IgZDCz_yK-RX7bCVqBue8g';
export const MAPBOX_STYLE = 'mapbox://styles/damienkopp/ck7d32na6055k1ip4a58hogg0';
export const TINYMCE_KEY = 'ht0y267s07wof0chrwdx9w89h2k8507dek2ppuj1xkc9ynty';
export const STOREWISE_VERSION = packageJson.version;
export const DOWNLOAD_URL_APP_IOS = 'https://apps.apple.com/app/id1523506615';
export const DOWNLOAD_URL_APP_ANDROID = 'https://play.google.com/store/apps/details?id=com.storewise';

export const LAPI = axios.create({
    baseURL: API_URL
});

export const STOREWISE_ADMIN_IDS = [
    'V7jnx2qMMhO5g0TBvV24r0V0siD3',
    'xNPdMmo1YuSDujDqxtvVycP1nVs1',
    'eWDUhgkjhwZqATkT2EvzeXovWK53',
    'e8W5vY8RTaZ3Y1Wot44lQxFaZii1',
    'GxaYhcWvcjSmo1ybl438AMajZGr1',
    'NHMN5rlskeMjby9rvkcUhDAGbQC3',
    'kuCQ1R7ydkRzTVujkZEWWIKwU3A2',
    'JytHmexR74NApp8RgwmtCXqZYiC2',
    'ooTnkImoENhdQsqVvslQuQ0icYA3'
];

/**
 * Storage keys
 */
// export const STORAGEKEY_AUTH_TOKEN = '@sk-authToken';
export const SK_APP_LANGUAGE = '@sk-app-language';
export const SK_ACTIVE_CHANNEL_ID = '@sk-active-channel-id';
export const SK_IS_FIRST_LOGIN = '@sk-is-first-login';
export const SK_CHAT_INPUT = '@sk-chat-input';

/**
 * App environment
 *
 * APP_ENVS: see root file ".env-cmdrc"
 * APP_SENTRY_CONFIG: https://docs.sentry.io/error-reporting/configuration/?platform=javascript
 * APP_WEBFONT_CONFIG: https://github.com/typekit/webfontloader
 */
export const APP_ENVS = {
    LOCAL: 'local',
    DEV: 'dev',
    DEMO: 'demo',
    PRODUCTION: 'production'
};
export const IS_NOT_PROD_ENV = APP_ENV !== APP_ENVS.PRODUCTION;
export const IS_PROD_ENV = APP_ENV === APP_ENVS.PRODUCTION;
export const IS_LOCAL = APP_ENV === APP_ENVS.LOCAL;
export const APP_SENTRY_CONFIG = {
    environment: APP_ENV,
    dsn: 'https://a143696713b346a0a68688241840cfab@o521830.ingest.sentry.io/5632415'
};
export const APP_WEBFONT_CONFIG = {
    google: {
        families: [
            'Roboto:300',
            'Roboto:400',
            'Roboto:500',
            'Roboto:700',
            'Roboto:900'
        ]
    }
};

/**
 * Routing
 */
export const ROUTES = {
    SPLASH: '/',
    AUTH: '/auth',
    CHAT: '/chat',
    INSIGHTS: '/insights',
    INVITE: '/invite',

    CARDS: (props) => {
        const {
            id,
            type,
            filter
        } = props || {};

        const queries = [];

        let path = '/cards';

        if (id) {
            queries.push(`id=${id}`);
        }

        if (type) {
            queries.push(`type=${type}`);
        }

        if (filter) {
            queries.push(`filter=${filter}`);
        }

        if (queries.length) {
            path = `${path}?${queries.join('&')}`;
        }

        return path;
    },

    CARD_MOBILE: (workItemId = '') => {
        let query = '';

        if (workItemId) {
            query = `?id=${workItemId}`;
        }

        return `/card-mobile${query}`;
    },

    FORMS: (props) => {
        const {
            formTemplateId,
            formResponseId,
            formTemplateIdRespond
        } = props || {};
        let query = '';

        if (formTemplateId) {
            query = `?ftId=${formTemplateId}`;

            if (formResponseId) {
                query = `${query}&frId=${formResponseId}`;
            }
        } else if (formTemplateIdRespond) {
            query = `?ftIdR=${formTemplateIdRespond}`;
        }

        return `/forms${query}`;
    },

    FORM_RESPOND_MOBILE: (formTemplateId = '') => {
        let query = '';

        if (formTemplateId) {
            query = `?ftId=${formTemplateId}`;
        }

        return `/form-respond-mobile${query}`;
    },

    SETTINGS: '/settings',

    // Dev routes
    AUTH_EMAIL: '/auth-email',
    TEST: '/test',
    LOGOUT: '/logout',

    // IFrame routes
    IFRAME: {
        APP_FORM: '/app-form',
        APP_VIEW: '/app-view',
        CHECK_IN: '/check-in',
        AUTH_MAP: '/auth-map'
    }
};
export const ROUTES_IFRAME = [
    ROUTES.IFRAME.APP_FORM,
    ROUTES.IFRAME.APP_VIEW,
    ROUTES.IFRAME.CHECK_IN,
    ROUTES.IFRAME.AUTH_MAP
];
export const ROUTES_WITH_NAV = [
    ROUTES.CHAT,
    ROUTES.CARDS(),
    ROUTES.FORMS(),
    ROUTES.INSIGHTS,
    ROUTES.INVITE,
    ROUTES.SETTINGS
];

// export const ROUTE_REDIRECT_AFTER_REGISTRATION = ROUTES.DASHBOARD;
// export const ROUTE_REDIRECT_AFTER_LOGIN = ROUTES.DASHBOARD;
export const REDIRECT_ROUTE__SIGNED_IN__TO__SIGNED_OUT = ROUTES.SPLASH;
export const REDIRECT_ROUTE__SIGNED_OUT__TO__SIGNED_IN = ROUTES.CHAT;

/**
 * App
 */
export const NAVIGATOR_GEOLOCATION_CONFIG = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 15000
};

export const CHAT_USER_STATUS = {
    VISIBLE: 0,
    REMOVED: 1,
    ARCHIVED: 2
};

export const CHAT_USER_TYPES = {
    MEMBER: 1,
    ADMIN: 2
};

export const CHAT_CHANNEL_TYPES = {
    DIRECT: 0, // One to one
    PRIVATE: 1, // Group
    PUBLIC: 2, // Public group
    CORPORATE: 3,
    SUPPORT: 4,
    MARKETING: 5,
    FOLDER: 6 // Folder
};

export const CHAT_MESSAGE_TYPES = {
    TEXT: 1,
    IMAGE: 2,
    VIDEO: 3,
    EVENT: 4,
    FILE: 5,
    APP: 6,
    OPTIONS: 7,
    FORM: 8
};

export const CHAT_SEARCH_FILTER_TYPES = {
    PEOPLE: 'user',
    CHATS: 'channel',
    IMAGE: 'image',
    VIDEO: 'video',
    FILE: 'file',
    CARD: 'card',
    LINK: 'link',
    MESSAGE: 'message'
};

export const USER_CONNECTION_STATUS = {
    NOT_CONNECTED: 'NONE',
    REQUEST_SENT: 'REQ_SENT',
    REQUEST_RECEIVED: 'REQ_RECV',
    CONNECTED: 'CONNECTED'
};

export const REGEX_URL = /(https?:\/\/[^\s]+)/g;

export const AT_LOCATION_DISTANCE_THRESHOLD = 0.1; // in kilometer

export const METABASE_DASHBOARDS = {
    CHAT_USAGE: 'chat_usage',
    WORK_ITEMS: 'work_items',
    PEOPLE: 'people',
    PLACES: 'places',
    ADMIN: 'admin'
};

export const CARDS_USER_STATUS = {
    ARCHIVED: 2
};

export const COLORS = [
    '#2E5BFF', '#2CBDFB', '#A160FB', '#FF005C',
    '#FD7366', '#FFB84E', '#24CE85', '#04162f',
];
