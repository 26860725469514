import {
  AppView,
  AppForm,
  Profile,
  ProfilePublic,
  Forward,
  ShareFormTemplate,
  Notifications,
} from 'components';

export enum FrameType {
  Profile,
  PublicProfile,
  ShareFormTemplate,
  AppForm,
  AppView,
  Forward,
  Notifications,
}

export const FrameComponents: Record<FrameType, (props: any) => JSX.Element> = {
  [FrameType.Profile]: Profile,
  [FrameType.PublicProfile]: ProfilePublic,
  [FrameType.ShareFormTemplate]: ShareFormTemplate,
  [FrameType.Forward]: Forward,
  [FrameType.AppForm]: AppForm,
  [FrameType.AppView]: AppView,
  [FrameType.Notifications]: Notifications,
}

