import React, {
    lazy
} from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';
import {
    useIsSignedIn
} from 'utils/use/me';
import {
    ROUTES
} from 'config';
import * as Sentry from '@sentry/react';
import Fallback from './Fallback';
import Route404 from './404';
import SuspensedRoute from './SuspensedRoute';

const Splash = lazy(() => import('containers/Splash'));
const Auth = lazy(() => import('containers/Auth'));
const AuthEmail = lazy(() => import('containers/AuthEmail'));
const Chat = lazy(() => import('containers/Chat'));
const Invite = lazy(() => import('containers/Invite'));
const Insights = lazy(() => import('containers/Insights'));
const Cards = lazy(() => import('containers/Cards'));
const Forms = lazy(() => import('containers/Forms'));
const FormRespondMobile = lazy(() => import('containers/FormRespondMobile'));
const CardMobile = lazy(() => import('containers/CardMobile'));
const Settings = lazy(() => import('containers/Settings'));
const Test = lazy(() => import('containers/Test'));

const __routes = [{
    routeProps: {
        path: ROUTES.SPLASH
    },
    suspensedRouteProps: {
        Component: Splash,
        isSignedInOnly: false
    }
}, {
    routeProps: {
        path: ROUTES.AUTH
    },
    suspensedRouteProps: {
        Component: Auth,
        isSignedInOnly: false
    }
}, {
    routeProps: {
        path: ROUTES.AUTH_EMAIL
    },
    suspensedRouteProps: {
        Component: AuthEmail,
        isSignedInOnly: false
    }
}, {
    routeProps: {
        path: ROUTES.CHAT
    },
    suspensedRouteProps: {
        Component: Chat
    }
}, {
    routeProps: {
        path: ROUTES.INVITE,
        exact: false
    },
    suspensedRouteProps: {
        Component: Invite
    }
}, {
    routeProps: {
        path: ROUTES.INSIGHTS,
        exact: false
    },
    suspensedRouteProps: {
        Component: Insights
    }
}, {
    routeProps: {
        path: ROUTES.CARDS(),
        exact: false
    },
    suspensedRouteProps: {
        Component: Cards
    }
}, {
    routeProps: {
        path: ROUTES.FORMS(),
        exact: false
    },
    suspensedRouteProps: {
        Component: Forms
    }
}, {
    routeProps: {
        path: ROUTES.FORM_RESPOND_MOBILE(),
        exact: false
    },
    suspensedRouteProps: {
        Component: FormRespondMobile
    }
}, {
    routeProps: {
        path: ROUTES.CARD_MOBILE(),
        exact: false
    },
    suspensedRouteProps: {
        Component: CardMobile
    }
}, {
    routeProps: {
        path: ROUTES.SETTINGS
    },
    suspensedRouteProps: {
        Component: Settings
    }
}, {
    routeProps: {
        path: ROUTES.TEST
    },
    suspensedRouteProps: {
        Component: Test
    }
}];

export default function Router() {
    const {
        isSignedIn
    } = useIsSignedIn();

    const renderRoute = (item, index) => {
        const {
            routeProps,
            suspensedRouteProps
        } = item;

        const {
            exact = true
        } = routeProps;

        const {
            isSignedInOnly = true
        } = suspensedRouteProps;

        const path = exact ? routeProps.path : `${routeProps.path}/*`;

        return (
            <Route
                key={index}
                {...routeProps}
                path={path}
                exact={exact}
                element={(
                    <SuspensedRoute
                        {...suspensedRouteProps}
                        isSignedInOnly={isSignedInOnly}
                        isSignedIn={isSignedIn} />
                )} />
        );
    };

    return (
        <Sentry.ErrorBoundary
            fallback={<Fallback body='500' />}
            showDialog={true}>
            <Routes>
                {__routes.map(renderRoute)}

                <Route
                    path="*"
                    element={<Route404 />} />
            </Routes>
        </Sentry.ErrorBoundary>
    );
}
