// import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/Modal';
import File from 'components/File';
import {
    useGallery
} from './use/useGallery';
import {
    cloudinaryVideoThumbnail,
    getFileNameByUri
} from 'utils';
import {
    openImageView
} from 'components/ImageView';
import styles from './index.module.scss';

export default function Gallery() {
    const {
        isVisible,
        setIsVisible,
        data
    } = useGallery();

    const onClose = () => {
        setIsVisible(false);
    };

    const renderItem = (item, index) => {
        const {
            uri,
            type
        } = item;
        const isImage = type.indexOf('image') > -1;
        const isVideo = type.indexOf('video') > -1;
        let children;

        if (isImage || isVideo) {
            const uriThumb = isVideo
                ? cloudinaryVideoThumbnail(uri)
                : uri;

            const onClick = () => {
                openImageView({
                    image: uri
                });
            };

            children = (
                <img
                    className={styles.image}
                    onClick={onClick}
                    src={uriThumb}
                    alt='' />
            );
        } else {
            children = (
                <File
                    href={uri}
                    name={getFileNameByUri(uri)} />
            );
        }

        return (
            <div
                key={index}
                className={styles.item}>
                {children}
            </div>
        );
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Modal onClose={onClose}>
            <div
                className={styles.Root}
                style={{
                    maxHeight: window.innerHeight * 0.8
                }}>
                {data.map(renderItem)}
            </div>
        </Modal>
    );
}
