// import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useMediaViewerCore } from 'utils/use/mediaViewer';
import styles from './index.module.scss';

export default function Component() {
    const [media, setMedia] = useState([]);

    useMediaViewerCore(setMedia);

    if (!media.length) {
        return null;
    }

    const onClose = () => setMedia([]);
    const renderMedia = (item, index) => {
        const { isImage, isVideo, src, thumbnail } = item;

        if (isImage) {
            return (
                <img
                    key={index}
                    className={styles.Component__img}
                    src={src}
                    alt='' />
            );
        }

        if (isVideo) {
            return (
                <video
                    key={index}
                    className={styles.Component__video}
                    poster={thumbnail}
                    src={src}
                    controls={true} />
            );
        }

        return null;
    };

    return createPortal(
        <div className={styles.Component}>
            <div
                className={styles.Component__close}
                onClick={onClose} />

            {media.map(renderMedia)}
        </div>,
        window.document.body
    );
}
