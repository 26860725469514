import PropTypes from 'prop-types';
import React from 'react';
import {
    Button
} from 'components';
import styles from './index.module.scss';

Quantity.propTypes = {
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
};

export default function Quantity(props) {
    const {
        t,
        onChange,
        value
    } = props;

    const onChangeNumber = (e) => {
        const {
            target
        } = e;
        const {
            value
        } = target;

        onChange(value.replace(/\D/g, ''));
    };
    const onDecrement = () => {
        onChange(`${parseInt(value, 10) - 1}`);
    };
    const onIncrement = () => {
        onChange(`${!value ? 1 : parseInt(value, 10) + 1}`);
    };

    return (
        <div className={styles.Root}>
            <Button
                className={styles.decrement}
                isDisabled={!value || value < 1}
                onClick={onDecrement}
                label='-' />

            <input
                className={styles.input}
                placeholder={t('forms.create.form.questions.quantity')}
                value={value || ''}
                onChange={onChangeNumber} />

            <Button
                className={styles.increment}
                onClick={onIncrement}
                label='+' />
        </div>
    );
}
