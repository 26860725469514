import {
  useState,
  useEffect
} from 'react';
import {
  apiForms
} from 'api';

let __templatesCache = [];

function useGlobalTemplates() {
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState(__templatesCache);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const {
          data
        } = await apiForms.getGlobalTemplates();

        __templatesCache = data;

        setTemplates(data);
      } catch (err) {
        //
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  return {
    isLoading,
    templates
  };
}

export {
  useGlobalTemplates
};
