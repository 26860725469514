// import PropTypes from 'prop-types';
import React, {
    useState,
    useRef
} from 'react';
import {
    createPortal
} from 'react-dom';
import {
    Button
} from 'components';
import {
    useMenuCore
} from 'utils/use/useMenu';
import styles from './index.module.scss';

export default function MenuCore() {
    const [data, setData] = useState(null);
    const ref = useRef();
    const {
        innerWidth,
        innerHeight
    } = window;
    const {
        top,
        left,
        items
    } = data || {};
    const style = {
        top,
        left
    };

    const onClose = () => {
        setData(null);
    };

    useMenuCore({
        setData,
        ref,
        onClose
    });

    if (!data) {
        return null;
    }

    if (top > (innerHeight / 2)) {
        style.transform = 'translateY(-100%)';
    }

    if (left > (innerWidth / 2)) {
        style.transform = `${style.transform || ''} translateX(-100%)`;
    }

    const renderItem = (item, index) => {
        const {
            isVisible,
            isDisabled,
            isCloseOnClick,
            label,
            onClick
        } = item;

        const _onClick = (e) => {
            onClick(e, {
                closeMenu: onClose
            });

            if (isCloseOnClick) {
                onClose();
            }
        };

        if (isVisible === false) {
            return null;
        }

        return (
            <Button
                key={index}
                isDisabled={isDisabled}
                className={styles.Component__item}
                onClick={_onClick}>
                {label}
            </Button>
        );
    };

    return createPortal(
        <div
            ref={ref}
            className={styles.Component}
            style={style}>
            {items.map(renderItem)}
        </div>,
        window.document.body
    );
}
