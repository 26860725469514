import PropTypes from 'prop-types';
import React, {
    useRef
} from 'react';
import {
    useDrag,
    useDrop
} from 'react-dnd';
import styles from './index.module.scss';

Question.propTypes = {
    data: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    onMoveQuestion: PropTypes.func.isRequired
};

export default function Question(props) {
    const {
        data,
        index,
        isActive,
        onClick,
        onMoveQuestion
    } = props;
    const {
        dragId,
        title
    } = data;
    const refRoot = useRef();

    const [
        {}, // eslint-disable-line
        drop
    ] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover(item, monitor) {
            const dragIndex = item.index;
            const hoverIndex = index;

            if (!refRoot.current || dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = refRoot.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
                (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) {
                return;
            }

            onMoveQuestion(dragIndex, hoverIndex);

            item.index = hoverIndex;
        }
    });
    const [
        {}, // eslint-disable-line
        drag
    ] = useDrag({
        type: 'card',
        item: () => ({
            id: dragId,
            index
        }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    drag(drop(refRoot));

    return (
        <div
            key={index}
            ref={refRoot}
            className={`${styles.Root} ${isActive && styles.Root__isActive}`}
            onClick={onClick}>
            {index + 1}.

            <div>
                {title}
            </div>
        </div>
    );
}
