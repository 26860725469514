let setMedia;

//  <MediaViewer />
export function useMediaViewerCore(_setMedia) {
    setMedia = _setMedia;
}

// setter
export function setMediaViewerMedia(items) {
    setMedia && setMedia(items);
}
