import PropTypes from 'prop-types';
import React, {
  useState
} from 'react';
import {
  COLORS
} from 'config/styles';
import {
  SelectLanguage,
  OutsideClickTrigger
} from 'components';
import styles from './index.module.scss';

function sortTagsByActiveFirst(tags, activeTags) {
  const sortedTags = [];

  for (const index in tags) {
    const tag = tags[index];

    if (activeTags.indexOf(tag) > -1) {
      sortedTags.unshift(tag);
    } else {
      sortedTags.push(tag);
    }
  }

  return sortedTags;
}

function filterTemplates(templates, filters) {
  const filteredTemplates = [];

  for (const index in templates) {
    const template = templates[index];
    let filterOut = false;

    if (filters.search) {
      if (template.title.indexOf(filters.search) === -1) {
        filterOut = true;
      }
    }

    if (filters.language && !filterOut) {
      if (template.lang !== filters.language) {
        filterOut = true;
      }
    }

    if (filters.tags?.length && !filterOut) {
      const findInFilterTags = (tag) => {
        return filters.tags.indexOf(tag) > -1;
      };

      if (!template.tags?.length || !template.tags.find(findInFilterTags)) {
        filterOut = true;
      }
    }

    if (!filterOut) {
      filteredTemplates.push(template);
    }
  }

  return filteredTemplates;
}

Filters.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  availableTags: PropTypes.array.isRequired
};

function Filters(props) {
  const {
    className,
    t,
    onChange,
    availableTags
  } = props;

  const [isTagsVisible, setIsTagsVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [language, setLanguage] = useState('');
  const [tags, setTags] = useState([]);
  const sortedAvailableTags = sortTagsByActiveFirst(
    availableTags,
    tags
  );

  const onToggleTagsDropdown = (e) => {
    setIsTagsVisible(!isTagsVisible);
  };

  const onHideTagsDropdown = () => {
    setIsTagsVisible(false);
  };

  const onChangeLanguage = (nextLanguage) => {
    setLanguage(nextLanguage);
    onChange({
      search,
      tags,
      language: nextLanguage
    });
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    onChange({
      language,
      tags,
      search: e.target.value
    });
  };

  const renderTag = (tag) => {
    const isActive = tags.indexOf(tag) > -1;
    const style = {};

    if (isActive) {
      style.color = COLORS.BRAND_BLUE;
      style.fontWeight = 'bold';
    }

    const onClick = () => {
      setTags((prevState) => {
        let nextTags = [];

        if (isActive) {
          const filterTag = (_tag) => _tag !== tag;

          nextTags = prevState.filter(filterTag);
        } else {
          nextTags = [...prevState, tag];
        }

        onChange({
          language,
          search,
          tags: nextTags
        });

        return nextTags;
      });
    };

    return (
      <div
        key={tag}
        style={style}
        onClick={onClick}>
        #{tag}
      </div>
    );
  };

  return (
    <div className={`${styles.root} ${className}`}>
      <input
        placeholder={t('forms.create.template_select.search_templates')}
        value={search}
        onChange={onChangeSearch} />

      <div>
        <span>
          {t('forms.create.template_select.filter_by_language')}
        </span>

        <div>
          <SelectLanguage
            className={styles.SelectLanguage}
            nullable={true}
            value={language}
            onChange={onChangeLanguage} />
        </div>
      </div>

      {availableTags.length > 0 && (
        <div>
          <span>
            {t('forms.create.template_select.filter_by_tags')}
          </span>

          <div className={styles.tags}>
            <div className={styles.tagsList}>
              {sortedAvailableTags.map(renderTag)}
            </div>

            {availableTags.length > 2 && (
              <div
                className={styles.tagsDropdownToggle}
                onClick={onToggleTagsDropdown}>
                ...
              </div>
            )}

            {isTagsVisible && (
              <OutsideClickTrigger
                className={styles.tagsDropdown}
                onTrigger={onHideTagsDropdown}>
                {sortedAvailableTags.map(renderTag)}
              </OutsideClickTrigger>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export {
  filterTemplates
};

export default Filters;
