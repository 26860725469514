import {
  FrameWidthLimits,
} from './types';

class RightFrameUtil {

  public static getWidthLimits(): FrameWidthLimits {
    return {
      min: 380,
      max: Math.ceil(window.innerWidth * 0.7),
    };
  }

}

export default RightFrameUtil;
