import { useState, useEffect } from 'react';
import { getUniqueId } from 'utils';

const __listenersIsVisible = {};
let __isVislbe = false;

function openCheckIn() {
    __isVislbe = true;

    for (const key in __listenersIsVisible) {
        __listenersIsVisible[key](true);
    }
}

function closeCheckIn() {
    __isVislbe = false;

    for (const key in __listenersIsVisible) {
        __listenersIsVisible[key](false);
    }
}

export function useCheckIn() {
    const [isVisible, setIsVisible] = useState(__isVislbe);

    const open = () => openCheckIn();
    const close = () => closeCheckIn();

    useEffect(() => {
        const key = getUniqueId();

        __listenersIsVisible[key] = setIsVisible;

        return () => {
            delete __listenersIsVisible[key];
        };
    }, []);

    return {
        isVisible,
        open,
        close
    };
}
