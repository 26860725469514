import PropTypes from 'prop-types';
import React, {
    useEffect
} from 'react';
import {
    createPortal
} from 'react-dom';
import styles from './index.module.scss';

Modal.propTypes = {
    children: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired
};

export default function Modal(props) {
    const {
        children,
        onClose
    } = props;

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    return createPortal(
        <div className={styles.Root}>
            <div
                className={styles.background}
                onClick={onClose} />

            <div className={styles.body}>
                {children}
            </div>
        </div>,
        window.document.body
    );
}
