import PropTypes from 'prop-types';
import React, {
    useState,
    useRef,
    useEffect
} from 'react';
import {
    setQuestions,
    getQuestions
} from '../../use/useInputs';
import Types from './Types';
import Builder from './Builder';
import {
    getUniqueId
} from 'utils';
import styles from './index.module.scss';

const __defaults = {
    activeType: '',
    isMandatory: false,
    initialTitle: '',
    initialOptions: []
};

Questions.propTypes = {
    t: PropTypes.func.isRequired,
    activeQuestionIndex: PropTypes.number,
    setActiveQuestionIndex: PropTypes.func.isRequired,
    renderSubmit: PropTypes.func.isRequired
};

export default function Questions(props) {
    const {
        t,
        activeQuestionIndex,
        setActiveQuestionIndex,
        renderSubmit
    } = props;
    const refLastActiveQuestionIndex = useRef(null);
    const [activeType, setActiveType] = useState(__defaults.activeType);
    const [isMandatory, setIsMandatory] = useState(__defaults.isMandatory);
    const [initialTitle, setInitialTitle] = useState(__defaults.initialTitle);
    const [initialOptions, setInitialOptions] = useState(__defaults.initialOptions);

    const onSelectType = (type) => {
        setActiveType(type);
    };

    const onUnselectType = () => {
        setActiveType(__defaults.activeType);
    };

    const onRemoveQuestion = () => {
        setQuestions((prevState) => {
            const nextQuestions = [];

            for (let i = 0, ix = 0, len = prevState.length; i < len; i++) {
                if (i !== activeQuestionIndex) {
                    nextQuestions[ix++] = prevState[i];
                }
            }

            return nextQuestions;
        });
        setActiveQuestionIndex(null);
    };

    const onSubmitQuestion = (question) => {
        const {
            title,
            options
        } = question;

        const submittableQuestion = {
            title,
            isMandatory,
            dragId: getUniqueId(),
            type: activeType,
            items: options
        };

        setQuestions((prevState) => {
            if (activeQuestionIndex !== null) {
                const nextQuestions = [];

                for (let i = 0, len = prevState.length; i < len; i++) {
                    if (i === activeQuestionIndex) {
                        nextQuestions[i] = submittableQuestion;
                    } else {
                        nextQuestions[i] = prevState[i];
                    }
                }

                return nextQuestions;
            }

            return [
                ...prevState,
                submittableQuestion
            ];
        });
        setActiveQuestionIndex(null);
        setInitialTitle(__defaults.initialTitle);
        setInitialOptions(__defaults.initialOptions);
        setIsMandatory(__defaults.isMandatory);
        setActiveType(__defaults.activeType);
    };

    useEffect(() => {
        if (activeQuestionIndex !== refLastActiveQuestionIndex.current) {
            const question = getQuestions()[activeQuestionIndex];

            if (activeQuestionIndex === null || !question) {
                setInitialTitle(__defaults.initialTitle);
                setInitialOptions(__defaults.initialOptions);
                setIsMandatory(__defaults.isMandatory);
                setActiveType(__defaults.activeType);
            } else {
                setInitialTitle(question.title);
                setInitialOptions(question.items);
                setIsMandatory(question.isMandatory);
                setActiveType(question.type);
            }
        }

        refLastActiveQuestionIndex.current = activeQuestionIndex;
    }, [activeQuestionIndex]);

    return (
        <div className={styles.Root}>
            <Types
                t={t}
                activeType={activeType}
                onSelect={onSelectType}
                isMandatory={isMandatory}
                setIsMandatory={setIsMandatory} />

            {!!activeType && (
                <Builder
                    t={t}
                    type={activeType}
                    isExistingQuestion={activeQuestionIndex !== null}
                    initialTitle={initialTitle}
                    initialOptions={initialOptions}
                    renderSubmit={renderSubmit}
                    onUnselectType={onUnselectType}
                    onRemoveQuestion={onRemoveQuestion}
                    onSubmit={onSubmitQuestion} />
            )}
        </div>
    );
}
