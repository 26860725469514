import PropTypes from 'prop-types';
import React, {
    useRef,
    useState,
    useEffect
} from 'react';
import {
    InputSearch,
    ActivityIndicator
} from 'components';
import {
    apiUnsplash
} from 'api';
import {
    cloudinaryFetch
} from 'utils';
import styles from './index.module.scss';

Photos.propTypes = {
    t: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired
};

// https://github.com/unsplash/unsplash-js
export default function Photos(props) {
    const {
        t,
        onSelect
    } = props;
    const refLastQuery = useRef('');
    const refIsProcessing = useRef(true);
    const [isProcessing, setIsProcessing] = useState(true);
    const [query, setQuery] = useState('');
    const [images, setImages] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const onScroll = (e) => {
        const { target } = e;
        const { scrollTop, scrollHeight, clientHeight } = target;
        const scrollOffsetBottom = scrollHeight - clientHeight - scrollTop;

        if (scrollOffsetBottom < 150 && !refIsProcessing.current) {
            if (page < totalPages) {
                refIsProcessing.current = true;
                setPage((prevState) => prevState + 1);
            }
        }
    };

    useEffect(() => {
        if (query !== refLastQuery.current) {
            refLastQuery.current = query;
            setImages([]);
            setTotalPages(1);

            if (page !== 1) {
                setPage(1);
                return;
            }
        }

        const onTimeout = async () => {
            refIsProcessing.current = true;
            setIsProcessing(true);

            try {
                const { data } = await apiUnsplash.get({
                    query: query || 'abstract background',
                    page,
                    per_page: 26
                });
                const {
                    results,
                    total_pages: totalPages
                } = data;

                setImages((prevState) => {
                    return [
                        ...prevState,
                        ...results
                    ];
                });
                setTotalPages(totalPages);
            } catch (err) {
                //
            } finally {
                setIsProcessing(false);
                refIsProcessing.current = false;
            }
        };

        const timeout = setTimeout(
            onTimeout,
            page === 1 ? 500 : 0
        );

        return () => {
            clearTimeout(timeout);
        };
    }, [query, page]);

    const renderImage = (item, index) => {
        const {
            urls,
            links,
            user
        } = item;
        const {
            thumb
        } = urls;
        const {
            name,
            links: userLinks
        } = user;
        const onClick = () => {
            onSelect(
                cloudinaryFetch(links.download)
            );
        };

        return (
            <div
                key={index}
                className={styles.image}
                onClick={onClick}>
                <img src={thumb} alt='' />

                <a
                    className={styles.image_link}
                    href={userLinks.html}>
                    {name}
                </a>
            </div>
        );
    };

    return (
        <div className={styles.Root}>
            <InputSearch
                className={styles.InputSearch}
                value={query}
                onChange={setQuery}
                placeholder='Search' />

            <div className={styles.unsplash}>
                {t('forms.create.form.cover.photos_by_unsplash')}
            </div>

            <div
                className={styles.images}
                onScroll={onScroll}>
                {images.map(renderImage)}
                {isProcessing && (
                    <ActivityIndicator
                        className={styles.ActivityIndicator} />
                )}
            </div>
        </div>
    );
}
