// import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { SearchMessages } from 'components';
import { useChatSearch, showChatSearch } from './useChatSearch';
import { CHAT_SEARCH_FILTER_TYPES } from 'config';
import styles from './index.module.scss';

export default function ChatSearch() {
    const { isVisible, search } = useChatSearch();
    const refLastSearch = useRef('');

    useEffect(() => {
        if (!isVisible &&
            search &&
            refLastSearch.current !== search) {
            showChatSearch();
        }

        refLastSearch.current = search;
    }, [isVisible, search]);

    return createPortal(
        <div
            className={styles.Root}
            style={{ display: isVisible ? 'block' : 'none' }}>
            {isVisible && (
                <SearchMessages
                    className={styles.SearchMessages}
                    search={search}
                    initialType={CHAT_SEARCH_FILTER_TYPES.CHATS}
                    isGlobal={true} />
            )}
        </div>,
        window.document.body
    );
}
