import IconTask from './assets/workitem/task.svg';
import IconSurvey from './assets/workitem/survey.svg';
import IconCheckin from './assets/workitem/checkin.svg';
import IconAnnouncement from './assets/workitem/announcement.svg';
import IconTaskColored from './assets/workitem/task-colored.svg';
import IconFormColored from './assets/workitem/form-colored.svg';
import IconAnnouncementColored from './assets/workitem/announcement-colored.svg';
import IconCheckinColored from './assets/workitem/checkin-colored.svg';

import IconTaskWhiteWithoutBackground from './assets/workitem/task-white-no-background.svg';
import IconCheckinWhiteWithoutBackground from './assets/workitem/checkin-white-no-background.svg';
import IconSurveyWhiteWithoutBackground from './assets/workitem/survey-white-no-background.svg';
import IconFormWhiteWithoutBackground from './assets/workitem/form-white-no-background.svg';
import IconAnnouncementWhiteWithoutBackground from './assets/workitem/announcement-white-no-background.svg';
// Card
import IconCardTask from './assets/workitem/card/task.svg';
import IconCardSurvey from './assets/workitem/card/survey.svg';
import IconCardForm from './assets/workitem/card/form.svg';
import IconCardCheckin from './assets/workitem/card/checkin.svg';
import IconCardAnnouncement from './assets/workitem/card/announcement.svg';
// Survey
import IconSingleSelectionBlack from './assets/workitem/survey/single-selection-black.svg';
import IconSingleSelectionWhite from './assets/workitem/survey/single-selection-white.svg';
import IconMultiSelectionBlack from './assets/workitem/survey/multi-selection-black.svg';
import IconMultiSelectionWhite from './assets/workitem/survey/multi-selection-white.svg';
import IconTextBlack from './assets/workitem/survey/text-black.svg';
import IconTextWhite from './assets/workitem/survey/text-white.svg';
import IconAttachmentBlack from './assets/workitem/survey/attachment-black.svg';
import IconAttachmentWhite from './assets/workitem/survey/attachment-white.svg';
import IconYesNoBlack from './assets/workitem/survey/yes-no-black.svg';
import IconYesNoWhite from './assets/workitem/survey/yes-no-white.svg';
import IconEmotionsBlack from './assets/workitem/survey/emotions-black.svg';
import IconEmotionsWhite from './assets/workitem/survey/emotions-white.svg';
import IconQuantityBlack from './assets/workitem/survey/quantity-black.svg';
import IconQuantityWhite from './assets/workitem/survey/quantity-white.svg';
import IconPriceBlack from './assets/workitem/survey/price-black.svg';
import IconPriceWhite from './assets/workitem/survey/price-white.svg';
import IconSliderBlack from './assets/workitem/survey/slider-black.svg';
import IconSliderWhite from './assets/workitem/survey/slider-white.svg';
import IconDrawBlack from './assets/workitem/survey/draw-black.svg';
import IconDrawWhite from './assets/workitem/survey/draw-white.svg';
import IconEmailBlack from './assets/workitem/survey/email-black.svg';
import IconEmailWhite from './assets/workitem/survey/email-white.svg';
import IconLocationBlack from './assets/workitem/survey/location-black.svg';
import IconLocationWhite from './assets/workitem/survey/location-white.svg';
import IconDateBlack from './assets/workitem/survey/date-black.svg';
import IconDateWhite from './assets/workitem/survey/date-white.svg';
import IconTimeBlack from './assets/workitem/survey/time-black.svg';
import IconTimeWhite from './assets/workitem/survey/time-white.svg';
// import IconBarcodeBlack from './assets/workitem/survey/barcode-black.svg';
// import IconBarcodeWhite from './assets/workitem/survey/barcode-white.svg';

export const WORKITEM_STATUS = {
    OPEN: 0,
    CLOSED: 1
};

export const WORKITEM_ASSIGNED_FILTER_TYPES = {
    ASSIGNED_TO_ME: 'assigned-to-me',
    ASSIGNED_BY_ME: 'assigned-by-me',
    SHARED_WITH_ME: 'assigned-with-me',
    FORMS: 'forms'
};

export const WORKITEM_TYPES = {
    TASK: 1,
    SURVEY: 2,
    ANNOUNCEMENT: 3,
    CHECK_IN_REQUEST: 4,
    FORM: 12
};

export const WORKITEM_CONFIG = {
    [WORKITEM_TYPES.TASK]: {
        IconCard: IconCardTask,
        Icon: IconTask,
        IconColored: IconTaskColored,
        IconWhiteWithoutBackground: IconTaskWhiteWithoutBackground
    },
    [WORKITEM_TYPES.SURVEY]: {
        IconCard: IconCardSurvey,
        Icon: IconSurvey,
        IconColored: IconSurvey,
        IconWhiteWithoutBackground: IconCheckinWhiteWithoutBackground
    },
    [WORKITEM_TYPES.FORM]: {
        IconCard: IconCardForm,
        Icon: IconCardForm,
        IconColored: IconFormColored,
        IconWhiteWithoutBackground: IconFormWhiteWithoutBackground
    },
    [WORKITEM_TYPES.ANNOUNCEMENT]: {
        IconCard: IconCardAnnouncement,
        Icon: IconAnnouncement,
        IconColored: IconAnnouncementColored,
        IconWhiteWithoutBackground: IconSurveyWhiteWithoutBackground
    },
    [WORKITEM_TYPES.CHECK_IN_REQUEST]: {
        IconCard: IconCardCheckin,
        Icon: IconCheckin,
        IconColored: IconCheckinColored,
        IconWhiteWithoutBackground: IconAnnouncementWhiteWithoutBackground
    }
};

export const WORKITEM_SURVEY_QUESTION_TYPES = {
    FREE_TEXT: 'text',
    SINGLE_SELECTION: 'dropdown',
    MULTI_SELECTION: 'checkbox',
    ATTACHMENT: 'file',
    BARCODE: 'barcode',
    EMOTIONS: 'feelings',
    LINEAR_SCALE: 'slider',
    YES_NO: 'radio-yn',
    QUANTITY: 'quantity',
    PRICE: 'amount',
    DRAW: 'draw',
    EMAIL: 'email',
    LOCATION: 'location',
    DATE: 'date',
    TIME: 'time'
};

export const WORKITEM_SURVEY_QUESTION_CONFIG = {
    [WORKITEM_SURVEY_QUESTION_TYPES.FREE_TEXT]: {
        iconBlack: IconTextBlack,
        iconWhite: IconTextWhite,
        isItems: false
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.SINGLE_SELECTION]: {
        iconBlack: IconSingleSelectionBlack,
        iconWhite: IconSingleSelectionWhite,
        isItems: true
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.MULTI_SELECTION]: {
        iconBlack: IconMultiSelectionBlack,
        iconWhite: IconMultiSelectionWhite,
        isItems: true
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.ATTACHMENT]: {
        iconBlack: IconAttachmentBlack,
        iconWhite: IconAttachmentWhite,
        isItems: false
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.EMOTIONS]: {
        iconBlack: IconEmotionsBlack,
        iconWhite: IconEmotionsWhite,
        isItems: false
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.YES_NO]: {
        iconBlack: IconYesNoBlack,
        iconWhite: IconYesNoWhite,
        isItems: false
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.QUANTITY]: {
        iconBlack: IconQuantityBlack,
        iconWhite: IconQuantityWhite,
        isItems: false
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.PRICE]: {
        iconBlack: IconPriceBlack,
        iconWhite: IconPriceWhite,
        isItems: false
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.LINEAR_SCALE]: {
        iconBlack: IconSliderBlack,
        iconWhite: IconSliderWhite,
        isItems: true
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.DRAW]: {
        iconBlack: IconDrawBlack,
        iconWhite: IconDrawWhite,
        isItems: false
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.EMAIL]: {
        iconBlack: IconEmailBlack,
        iconWhite: IconEmailWhite,
        isItems: false
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.LOCATION]: {
        iconBlack: IconLocationBlack,
        iconWhite: IconLocationWhite,
        isItems: false
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.DATE]: {
        iconBlack: IconDateBlack,
        iconWhite: IconDateWhite,
        isItems: false
    },
    [WORKITEM_SURVEY_QUESTION_TYPES.TIME]: {
        iconBlack: IconTimeBlack,
        iconWhite: IconTimeWhite,
        isItems: false
    }

    // [WORKITEM_SURVEY_QUESTION_TYPES.BARCODE]: {
    //     iconBlack: IconBarcodeBlack,
    //     iconWhite: IconBarcodeWhite,
    //     isItems: false
    // }
};
