import {
  useEffect,
  useState,
  useRef
} from 'react';
import {
  apiUsers
} from 'api';

const __cache = {};

function useUser(apiProps) {
  const {
    id
  } = apiProps || {};

  const refApiProps = useRef(apiProps);

  if (refApiProps.current?.id !== apiProps?.id) {
    refApiProps.current = apiProps;
  }

  const [isLoading, setIsLoading] = useState(!!id);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setIsLoading(!!id);
    setUser(__cache[id] || null);

    if (id) {
      const getUser = async () => {
        const data = await fetchUser(refApiProps.current);

        __cache[id] = data;

        if (refApiProps.current?.id === id) {
          if (data) {
            setUser(data);
          }

          setIsLoading(false);
        }
      };

      getUser();
    }

    return () => {
      refApiProps.current = null;
    };
  }, [id]);

  return {
    isLoading,
    user
  };
}

async function fetchUser(props) {
  try {
    const {
      data
    } = await apiUsers.get(props);

    return data[0];
  } catch (err) {
    return null;
  }
}

export {
  useUser
};
