import PropTypes from 'prop-types';
import React from 'react';
import PriceInput from 'react-number-format';
import styles from './index.module.scss';

Price.propTypes = {
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
};

export default function Price(props) {
    const {
        t,
        onChange,
        value
    } = props;

    const onChangePrice = (data) => {
        const {
            floatValue
        } = data;

        onChange(floatValue);
    };

    return (
        <div className={styles.Root}>
            <PriceInput
                className={styles.PriceInput}
                placeholder={t('forms.create.form.questions.amount')}
                onValueChange={onChangePrice}
                value={value}
                thousandSeparator={true}
                prefix='$'
                inputMode='decimal' />
        </div>
    );
}
