import PropTypes from 'prop-types';
import React from 'react';
import {
    InputDate
} from 'components';
import styles from './index.module.scss';

Date.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
};

export default function Date(props) {
    const {
        onChange,
        value
    } = props;

    const onChangeDate = (date) => {
        onChange(
            date.getTime()
        );
    };

    return (
        <InputDate
            className={styles.Root}
            value={value}
            onChange={onChangeDate} />
    );
}
