import {
    useState,
    useEffect
} from 'react';
import {
    apiForms
} from 'api';
import {
    apiPusher
} from 'api/pusher';
import {
    getUserId
} from 'utils/use/me';

const RESPONSE_ITEMS_LIMIT = 20;

let __formTemplates = [];
let __setFormTemplates;
let __fetchData;

export async function archiveFormTemplate(formTemplateId) {
    const filterItem = (item) => item.id !== formTemplateId;

    __formTemplates = __formTemplates.filter(filterItem);
    __setFormTemplates && __setFormTemplates(__formTemplates);

    try {
        await apiForms.archiveFormTemplate(formTemplateId);
    } catch (err) {
        //
    }
}

export async function deleteFormTemplate(formTemplateId) {
    const filterItem = (item) => item.id !== formTemplateId;

    __formTemplates = __formTemplates.filter(filterItem);
    __setFormTemplates && __setFormTemplates(__formTemplates);

    try {
        await apiForms.deleteFormTemplate(formTemplateId);
    } catch (err) {
        //
    }
}

export function fetchFormTemplates() {
    __fetchData && __fetchData();
}

export function addFormTemplate(formTemplate) {
    __setFormTemplates && __setFormTemplates((prevState) => {
        const nextFormTemplates = [
            formTemplate,
            ...prevState
        ];

        __formTemplates = nextFormTemplates;

        return nextFormTemplates;
    });
}

export function useFormTemplates() {
    const [formTemplates, setFormTemplates] = useState(__formTemplates);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isNextPage, setIsNextPage] = useState(false);

    useEffect(() => {
        __fetchData = async () => {
            setIsLoading(true);

            try {
                const {
                    data
                } = await apiForms.getAllTemplates({
                    take: RESPONSE_ITEMS_LIMIT,
                    page
                });

                data.sort(sortByUpdatedAt);

                __formTemplates = data;

                setFormTemplates(data);
                setIsNextPage(data.length >= RESPONSE_ITEMS_LIMIT);
            } catch (err) {
                //
            } finally {
                setIsLoading(false);
            }
        };

        __fetchData();
    }, [page]);

    useEffect(() => {
        if (isNextPage) {
            const onScroll = () => {
                const {
                    scrollHeight,
                    scrollTop,
                    clientHeight
                } = window.document.documentElement;

                if (scrollHeight - scrollTop === clientHeight) {
                    window.document.removeEventListener('scroll', onScroll);
                    setIsNextPage(false);
                    setPage((prevState) => prevState + 1);
                }
            };

            window.document.addEventListener('scroll', onScroll);

            return () => {
                window.document.removeEventListener('scroll', onScroll);
            };
        }
    }, [isNextPage]);

    useEffect(() => {
        __setFormTemplates = setFormTemplates;

        const subscriptionKey = `private-Auth.${getUserId()}`;
        const subscripion = apiPusher.subscribe(subscriptionKey);
        const onUpdate = async (props) => {
            const {
                form_id: formTemplateId
            } = props;

            try {
                const {
                    data
                } = await apiForms.getOneTemplate(formTemplateId);

                setFormTemplates((prevState) => {
                    const mapItem = (item) => {
                        const {
                            id
                        } = item;

                        if (id === formTemplateId) {
                            return data;
                        }

                        return item;
                    };

                    return prevState.map(mapItem);
                });
            } catch (err) {
                //
            }
        };

        subscripion.bind('update-form', onUpdate);

        return () => {
            subscripion.unbind('update-form', onUpdate);
            apiPusher.unsubscribe(subscriptionKey);
        };
    }, []);

    return {
        formTemplates,
        isLoading
    };
}

function sortByUpdatedAt(itemA, itemB) {
    const {
        updated_at: a
    } = itemA;
    const {
        updated_at: b
    } = itemB;

    return b - a;
}
