import { useRef, useEffect, useCallback } from 'react';
import { postMessageToParentFrame } from 'utils';
import { getBearerToken } from 'utils/auth';

export function useIframeRequest() {
    const refCallback = useRef(null);

    const requestAuthToken = useCallback((callback) => {
        postMessageToParentFrame({ request: 'auth-token' });
        refCallback.current = callback;
    }, []);

    useEffect(() => {
        const onPostMessage = async ({ data: dataRaw }) => {
            let data = dataRaw;

            // Support for RN
            if (typeof dataRaw === 'string') {
                try {
                    data = JSON.parse(dataRaw);
                } catch (err) {}
            }

            refCallback.current && refCallback.current(data.authToken || await getBearerToken());
            refCallback.current = null;
        };

        window.addEventListener('message', onPostMessage);
        // Support for RN
        document.addEventListener('message', onPostMessage);

        return () => {
            window.removeEventListener('message', onPostMessage);
            document.removeEventListener('message', onPostMessage);
        };
    }, []);

    return {
        requestAuthToken
    };
}
