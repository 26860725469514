import {
    useState,
    useEffect,
    useRef
} from 'react';
import {
    uploadFiles
} from 'utils/upload';

export function useUpload(props) {
    const {
        isGpsRequired,
        isPostProcess,
        channelId,
        workItemId,
        formTemplateId
    } = props || {};
    const [isUploading, setIsUploading] = useState(false);
    const refIsMounted = useRef();

    const upload = async (_props) => {
        const {
            files,
            onChangeUploadProgress,
            authToken
        } = _props;

        setIsUploading(true);

        const data = await uploadFiles(files, {
            onChangeUploadProgress,
            channelId,
            workItemId,
            formTemplateId,
            isGpsRequired,
            isPostProcess,
            authToken
        });

        if (refIsMounted.current) {
            setIsUploading(false);
        }

        return data;
    };

    useEffect(() => {
        refIsMounted.current = true;

        return () => {
            refIsMounted.current = false;
        };
    }, []);

    return {
        isUploading,
        upload
    };
}
