import PropTypes from 'prop-types';
import React, {
  useState
} from 'react';
import {
  Button
} from 'components';
import {
  DndProvider
} from 'react-dnd';
import {
  HTML5Backend
} from 'react-dnd-html5-backend';
import {
  getTitle,
  useQuestionsValue,
  setQuestions,
  createFormTemplate,
  isExistingTemplate,
  editFormTemplate,
  archiveFormTemplate
} from '../use/useInputs';
import {
  TABS
} from '../config';
import {
  moveArrayItem
} from 'utils';
import {
  getProfile,
  getPermissions
} from 'utils/use/me';
import {
  addToast
} from 'utils/toaster';
import Question from './Question';
import styles from './index.module.scss';
// Assets
import IconMandatory from './assets/mandatory.svg';
import IconTextBlack from './assets/text-black.svg';
import IconTextBlue from './assets/text-blue.svg';
import IconCoverBlack from './assets/cover-black.svg';
import IconCoverBlue from './assets/cover-blue.svg';
import IconQuestionsBlack from './assets/questions-black.svg';
import IconQuestionsBlue from './assets/questions-blue.svg';
import IconTemplateBlue from './assets/template-blue.svg';
import IconTemplateBlack from './assets/template-black.svg';

Tabs.propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    activeQuestionIndex: PropTypes.number,
    setActiveQuestionIndex: PropTypes.func.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
    onArchived: PropTypes.func.isRequired,
    onDiscard: PropTypes.func.isRequired,
    onSubmitted: PropTypes.func.isRequired
};

export default function Tabs(props) {
  const {
    className,
    t,
    setActiveTab,
    activeTab,
    activeQuestionIndex,
    setActiveQuestionIndex,
    onArchived,
    onDiscard,
    onSubmitted
  } = props;
  const [isProcessingArchive, setIsProcessingArchive] = useState(false);
  const [isProcessingEdit, setIsProcessingEdit] = useState(false);
  const [isProcessingSubmit, setIsProcessingSubmit] = useState(false);
  const questions = useQuestionsValue();
  const isTitleOk = !!getTitle();
  const isQuestionsOk = questions.length > 0;
  const isSuperAdmin = (
    getProfile().is_admin &&
    getPermissions().create_card_global_templates
  );
  const isArchiveDisabled = (
    isProcessingEdit ||
    isProcessingSubmit
  );
  const isEditDisabled = (
    isProcessingArchive ||
    isProcessingSubmit
  );
  const isSubmitDisabled = (
    !isTitleOk ||
    !isQuestionsOk ||
    isProcessingArchive ||
    isProcessingEdit
  );

  const tabs = [
    {
      onClick: () => {
        setActiveTab(TABS.TITLE);
      },
      isActive: activeTab === TABS.TITLE,
      isMandatory: true,
      isOk: isTitleOk,
      IconInactive: IconTextBlack,
      IconActive: IconTextBlue,
      label: t('forms.create.tabs.title')
    },
    {
      onClick: () => {
        setActiveTab(TABS.DESCRIPTION);
      },
      isActive: activeTab === TABS.DESCRIPTION,
      IconInactive: IconTextBlack,
      IconActive: IconTextBlue,
      label: t('forms.create.tabs.description')
    },
    {
      onClick: () => {
        setActiveTab(TABS.COVER);
      },
      isActive: activeTab === TABS.COVER,
      IconInactive: IconCoverBlack,
      IconActive: IconCoverBlue,
      label: t('forms.create.tabs.cover')
    },
    {
      onClick: () => {
        setActiveTab(TABS.QUESTIONS);
        setActiveQuestionIndex(null);
      },
      isMandatory: true,
      isOk: isQuestionsOk,
      isActive: (
        activeTab === TABS.QUESTIONS &&
        activeQuestionIndex === null
      ),
      IconInactive: IconQuestionsBlack,
      IconActive: IconQuestionsBlue,
      label: t('forms.create.tabs.questions')
    }
  ];

  if (isSuperAdmin) {
    tabs.push({
      onClick: () => {
        setActiveTab(TABS.TEMPLATE);
      },
      isActive: activeTab === TABS.TEMPLATE,
      IconInactive: IconTemplateBlack,
      IconActive: IconTemplateBlue,
      label: t('forms.create.tabs.template')
    });
  }

  const onArchive = async () => {
    if (window.confirm(t('forms.create.archive_confirmation'))) {
      setIsProcessingArchive(true);
      await archiveFormTemplate();
      onArchived();
    }
  };

  const onEdit = async () => {
    if (window.confirm(t('forms.create.edit_confirmation'))) {
      setIsProcessingEdit(true);

      if (await editFormTemplate()) {
        addToast(
          t('forms.create.edit_success')
        );
      }

      setIsProcessingArchive(false);
    }
  };

  const onMoveQuestion = (oldIndex, newIndex) => {
    setQuestions((prevState) => {
      return moveArrayItem(
        prevState,
        oldIndex,
        newIndex
      );
    });
  };

  const onSelectQuestion = (index) => {
    setActiveQuestionIndex(index);
    setActiveTab(TABS.QUESTIONS);
  };

  const onSubmitForm = async () => {
    setIsProcessingSubmit(true);

    if (await createFormTemplate()) {
      onSubmitted();
    } else {
      setIsProcessingSubmit(false);
    }
  };

  const renderTab = (item, index) => {
    const {
      isActive,
      isMandatory,
      isOk,
      onClick,
      IconInactive,
      IconActive,
      label
    } = item;
    let className = '';
    let Icon = IconInactive;

    if (isActive) {
      className = styles.tab__isActive;
      Icon = IconActive;
    }

    return (
      <Button
        key={index}
        className={`${styles.tab} ${className}`}
        onClick={onClick}>
        <div>
          <img src={Icon} alt='' />

          {label}
        </div>

        {(isMandatory && !isOk) && (
          <img src={IconMandatory} alt='' />
        )}
      </Button>
    );
  };

  const renderQuestion = (item, index) => {
    const onClick = () => {
      onSelectQuestion(index);
    };

    return (
      <Question
        key={index}
        isActive={index === activeQuestionIndex}
        index={index}
        onClick={onClick}
        onMoveQuestion={onMoveQuestion}
        data={item} />
    );
  };

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.tabs}>
        {tabs.map(renderTab)}

        <div className={styles.questions}>
          <DndProvider backend={HTML5Backend}>
            {questions.map(renderQuestion)}
          </DndProvider>
        </div>
      </div>

      <div>
        <Button
          className={styles.discard}
          label={t('forms.create.discard')}
          onClick={onDiscard} />

        {(isSuperAdmin && isExistingTemplate()) && (
          <>
            <Button
              className={styles.discard}
              label={t('forms.create.archive')}
              isProcessing={isProcessingArchive}
              isDisabled={isArchiveDisabled}
              onClick={onArchive} />

            <Button
              className={styles.discard}
              label={t('forms.create.edit')}
              isProcessing={isProcessingEdit}
              isDisabled={isEditDisabled}
              onClick={onEdit} />
          </>
        )}

        <Button
          className={styles.submit}
          label={t('forms.create.submit')}
          colorActivityIndicator='#fff'
          isProcessing={isProcessingSubmit}
          isDisabled={isSubmitDisabled}
          onClick={onSubmitForm} />
      </div>
    </div>
  );
}
