import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/messaging';
import { apiDeviceTokens } from 'api';
import { FIREBASE_VAPID_KEY } from 'config';
import * as Sentry from '@sentry/react';

firebase.initializeApp(window.FIREBASE_CONFIG);
firebase.auth().useDeviceLanguage();
firebase.analytics();

async function setDeviceToken(deviceToken) {
    if (deviceToken) {
        try {
            await apiDeviceTokens.set({
                device_id: deviceToken,
                type: 3
            });
        } catch (err) {
            Sentry.captureException(err);
        }
    }
}

export function initPushNotifications() {
    if (window.todesktop) {
        window.todesktop.on('pushNotifications.start', (e, token) => {
            console.log('pushNotifications.start -> token', token);

            setDeviceToken(token);
        });
        window.todesktop.on('pushNotifications.receive', (e, notification) => {
            console.log('pushNotifications.receive -> notification', notification);

            const { data: { badge } } = notification;

            window.todesktop.app.setBadgeCount(parseInt(badge, 10));
        });
        window.todesktop.on('pushNotifications.error', (e, error) => {
            console.log('pushNotifications.error', error);
        });
        window.todesktop.pushNotifications.start(
            window.FIREBASE_CONFIG.messagingSenderId,
            { showNotifications: true }
        );
    } else if (firebase.messaging.isSupported()) {
        window.Notification.requestPermission()
            .then((permission) => {
                if (permission === 'granted') {
                    const messaging = firebase.messaging();

                    messaging
                        .getToken({ vapidKey: FIREBASE_VAPID_KEY })
                        .then(setDeviceToken);

                    // messaging.onMessage((payload) => {
                    //     console.log('Message received. ', payload);
                    // });
                }
            });
    }
}
