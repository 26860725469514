import * as Sentry from '@sentry/react';
import axios from 'axios';
import Compressor from 'compressorjs';
import {
    apiFiles
} from 'api';
import {
    getGpsFromFile,
    getGpsFromGeolocation
} from 'utils/gps';
import {
    getUserId,
    getOrgId
} from 'utils/use/me';
import {
    APP_ENV,
    APP_ENVS,
    CLOUDINARY_API,
    CLOUDINARY_API_KEY,
    CLOUDINARY_NOTIFICATION_URL
} from 'config';

function getBufferFromFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsArrayBuffer(file);
    });
}

async function preRequestUpload(props) {
    const {
        isSignature, authToken,
        contentType, extension, folder, notificationUrl, context
    } = props;
    const payload = {
        notification_url: notificationUrl,
        contentType,
        extension,
        folder,
        context
    };

    if (isSignature) {
        payload.cl = true;
    }

    if (authToken) {
        const { data } = await apiFiles.requestPutUrlIFRAME(authToken, payload);

        return data;
    } else {
        const { data } = await apiFiles.requestPutUrl(payload);

        return data;
    }
}

async function getGpsContexts(file) {
    const gpsFromFile = await getGpsFromFile(file);
    let gps = 'null';
    let gpsVerified = 'false';
    let timestamp = 'null';

    if (gpsFromFile?.lat && gpsFromFile?.lng) {
        gps = `${gpsFromFile.lat},${gpsFromFile.lng}`;
        gpsVerified = 'true';

        if (gpsFromFile.timestamp) {
            timestamp = `${gpsFromFile.timestamp.replace(' ', 'T')}.000Z`;
        }
    } else {
        const gpsFromGeoLocation = await getGpsFromGeolocation();

        if (gpsFromGeoLocation?.lat && gpsFromGeoLocation?.lng) {
            gps = `${gpsFromGeoLocation.lat},${gpsFromGeoLocation.lng}`;
            timestamp = new Date().toISOString();
        }
    }

    return [
        `gps=${gps}`,
        `gps_verified=${gpsVerified}`,
        `timestamp=${timestamp}`
    ];
}

export function compressImageFile(file) {
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        new Compressor(
            file,
            {
                quality: 0.8,
                maxWidth: 1080,
                maxHeight: 1080,
                success: resolve,
                error: (err_) => {
                    resolve(file);
                }
            }
        );
    });
}

export async function uploadFiles(files, props) {
    const {
        userId = getUserId(),
        orgId = getOrgId(),
        workItemId,
        formTemplateId,
        channelId,
        isGpsRequired,
        isPostProcess,
        onChangeUploadProgress,
        authToken
    } = props || {};
    const fileUris = [];
    const fileCount = files.length;
    const data = [];
    let uploadsDoneCount = 0;

    const onUploadProgress = (progressEvent) => {
        const {
            loaded,
            total
        } = progressEvent;
        const progressPercentOverall = uploadsDoneCount * 100;
        const progressPercent = (loaded / total) * 100;

        if (onChangeUploadProgress) {
            onChangeUploadProgress(
                Math.ceil(
                    (progressPercentOverall + progressPercent) / fileCount
                )
            );
        }
    };

    for (let i = 0; i < fileCount; i++) {
        let file = files[i];
        let uri = '';
        let folder = `${APP_ENV === 'local' ? APP_ENVS.DEV : APP_ENV}/${orgId}`;

        const {
            name,
            type: contentType
        } = file;
        const extension = name.slice(name.indexOf('.'));

        try {
            const isVideo = contentType.indexOf('video') > -1;
            const isImage = contentType.indexOf('image') > -1;

            if (isVideo || isImage) {
                const notificationUrl = CLOUDINARY_NOTIFICATION_URL;
                const gpsContexts = isGpsRequired ? await getGpsContexts(file) : [];
                const contextArray = [
                    `userId=${userId}`,
                    `orgId=${orgId}`,
                    `channelId=${channelId || 'null'}`,
                    `formTemplateId=${formTemplateId || 'null'}`,
                    `workItemId=${workItemId || 'null'}`,
                    `post_process=${isPostProcess ? '1' : '0'}`,
                    ...gpsContexts
                ];
                const context = contextArray.join('|');
                let url;

                if (isVideo) {
                    folder = `${folder}/video`;
                    url = `${CLOUDINARY_API}video/upload`;
                } else {
                    folder = `${folder}/image`;
                    url = `${CLOUDINARY_API}image/upload`;

                    if (file.type.includes('gif') === false) {
                        file = await compressImageFile(file);
                    }
                }

                const {
                    signature,
                    timestamp
                } = await preRequestUpload({
                    contentType,
                    extension,
                    authToken,
                    folder,
                    notificationUrl,
                    context,
                    isSignature: true
                });

                const formData = new FormData();

                formData.append('file', file);
                formData.append('api_key', CLOUDINARY_API_KEY);
                formData.append('timestamp', timestamp);
                formData.append('signature', signature);
                formData.append('folder', folder);
                formData.append('notification_url', notificationUrl);
                formData.append('image_metadata', true);
                formData.append('context', context);

                const { data: { secure_url: secureUrl } } = await axios({
                    method: 'post',
                    data: formData,
                    url,
                    onUploadProgress
                });

                uri = secureUrl;
            } else {
                const putUrl = await preRequestUpload({
                    contentType,
                    extension,
                    authToken,
                    folder: `${folder}/file`
                });
                const fileBuffer = await getBufferFromFile(file);

                await axios.put(
                    putUrl,
                    fileBuffer,
                    {
                        headers: {
                            'Content-Type': contentType
                        },
                        onUploadProgress
                    }
                );

                uri = putUrl.split('?')[0];
            }

            fileUris.push(uri);
            data.push({
                uri,
                name: name || '',
                type: contentType || ''
            });
        } catch (err) {
            Sentry.captureException(err);
        }

        uploadsDoneCount++;
    }

    return {
        uris: fileUris,
        data
    };
}
