import PropTypes from 'prop-types';
import React, {
    useState
} from 'react';
import {
    useCover
} from 'containers/App/FormCreate/use/useInputs';
import {
    InputFile,
    ActivityIndicator
} from 'components';
import {
    COLORS
} from 'config';
import Colors from './Colors';
import Photos from './Photos';
import styles from './index.module.scss';
// Assets
import ImagePhotos from './assets/photos.svg';
import IconUpload from './assets/upload.svg';

Cover.propTypes = {
    t: PropTypes.func.isRequired,
    renderSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default function Cover(props) {
    const {
        t,
        renderSubmit,
        onSubmit
    } = props;
    const {
        value,
        setValue
    } = useCover();
    const [isProcessingUpload, setIsProcessingUpload] = useState(false);
    const [isColorsVisible, setIsColorsVisible] = useState(false);
    const [isPhotosVisible, setIsPhotosVisible] = useState(false);
    const coverStyle = value.indexOf('http') === 0
        ? { backgroundImage: `url(${value})` }
        : { backgroundColor: value };
    const isOptionsVisible = (
        !isColorsVisible &&
        !isPhotosVisible
    );

    const onShowColors = () => {
        setIsColorsVisible(true);
    };
    const onHideColors = () => {
        setIsColorsVisible(false);
    };
    const onShowPhotos = () => {
        setIsPhotosVisible(true);
    };
    const onHidePhotos = () => {
        setIsPhotosVisible(false);
    };
    const onShowOptions = () => {
        onHideColors();
        onHidePhotos();
    };
    const onChangeUploadFile = (uris) => {
        setValue(uris[0]);
    };

    const renderColor = (color, index) => (
        <div
            key={index}
            className={styles.color}
            style={{
                backgroundColor: color
            }} />
    );

    return (
        <div className={styles.Root}>
            {!!value && (
                <div
                    className={styles.cover}
                    style={coverStyle} />
            )}

            {isOptionsVisible && (
                <div className={styles.options}>
                    <div
                        className={styles.option}
                        onClick={onShowColors}>
                        {COLORS.map(renderColor)}

                        <div className={styles.option_title}>
                            {t('forms.create.form.cover.colors')}
                        </div>
                    </div>

                    <div
                        className={styles.option}
                        style={{
                            backgroundColor: '#c4c4c4',
                            backgroundImage: `url(${ImagePhotos})`
                        }}
                        onClick={onShowPhotos}>
                        <div className={styles.option_title}>
                            {t('forms.create.form.cover.photos')}
                        </div>
                    </div>

                    <div className={styles.option}>
                        <div className={styles.option_title}>
                            {t('forms.create.form.cover.custom')}
                        </div>

                        <InputFile
                            className={styles.InputFile}
                            isDisabled={isProcessingUpload}
                            isProcessAnalytics={false}
                            accept='image/*'
                            onChangeProcessingState={setIsProcessingUpload}
                            onChange={onChangeUploadFile}>
                            {isProcessingUpload
                                ? <ActivityIndicator
                                    className={styles.ActivityIndicatorInput}
                                    color='#000' />
                                : <img
                                    src={IconUpload}
                                    alt='' />
                            }
                        </InputFile>
                    </div>
                </div>
            )}

            {isColorsVisible && (
                <Colors
                    onSelect={setValue} />
            )}

            {isPhotosVisible && (
                <Photos
                    t={t}
                    onSelect={setValue} />
            )}

            <div className={styles.footer}>
                {!isOptionsVisible && (
                    renderSubmit({
                        className: styles.back,
                        label: t('forms.create.form.cover.other_options'),
                        onClick: onShowOptions
                    })
                )}

                {renderSubmit({
                    className: styles.submit,
                    isValid: true,
                    onClick: onSubmit
                })}
            </div>
        </div>
    );
}
