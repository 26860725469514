// import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { registerToaster } from 'utils/toaster';

export default function Toaster() {
    const { addToast } = useToasts();

    useEffect(() => {
        registerToaster({
            addToast
        });
    }, [addToast]);

    return null;
}
