import PropTypes from 'prop-types';
import React from 'react';
import './index.scss';

ActivityIndicator.propTypes = {
    size: PropTypes.number,
    className: PropTypes.string,
    color: PropTypes.string,
    info: PropTypes.any
};

ActivityIndicator.defaultProps = {
    size: 24,
    className: ''
};

export default function ActivityIndicator(props) {
    const { className, size, color, info } = props;
    const style = color ? { stroke: color } : null;

    return (
        <div className={`ActivityIndicator ${className}`} style={style}>
            <svg
                className='ActivityIndicator__spinner'
                aria-label={info || 'loading'}
                width={`${size}px`}
                height={`${size}px`}
                viewBox='0 0 66 66'
                xmlns='http://www.w3.org/2000/svg'>
                <circle
                    className='ActivityIndicator__spinner-path'
                    fill='none'
                    strokeWidth='6'
                    strokeLinecap='round'
                    cx='33'
                    cy='33'
                    r='30' />
            </svg>

            {!!info && (
                typeof info === 'string'
                    ? <div className='ActivityIndicator__info'>
                        {info}
                    </div>
                    : info
            )}
        </div>
    );
}
