// import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { getUniqueId } from 'utils';

const __listenersSearch = {};
const __listenersVisibility = {};
let __search = '';
let __isVisible = false;

export function onChangeChatSearch(nextSearch) {
    __search = nextSearch;

    for (const key in __listenersSearch) {
        __listenersSearch[key](nextSearch);
    }
}

export function showChatSearch() {
    __isVisible = true;

    for (const key in __listenersVisibility) {
        __listenersVisibility[key](true);
    }
}

export function hideChatSearch() {
    __isVisible = false;

    for (const key in __listenersVisibility) {
        __listenersVisibility[key](false);
    }
}

export function toggleChatSearch() {
    if (__isVisible) {
        hideChatSearch();
    } else {
        showChatSearch();
    }
}

export function useChatSearch() {
    const [isVisible, setIsVisible] = useState(__isVisible);
    const [search, setSearch] = useState(__search);

    useEffect(() => {
        const key = getUniqueId();

        __listenersSearch[key] = setSearch;
        __listenersVisibility[key] = setIsVisible;

        return () => {
            delete __listenersSearch[key];
            delete __listenersVisibility[key];
        };
    }, []);

    return {
        isVisible,
        search
    };
}
