// import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useCheckIn } from './useCheckIn';
import { useAutoCheckInState, setState } from 'utils/use/autoCheckIn';
import { getBearerToken } from 'utils/auth';
import { APP_URL, ROUTES } from 'config';
import styles from './index.module.scss';

export default function CheckIn() {
    const { isVisible, close } = useCheckIn();
    const refRoot = useRef();
    const refIsRendered = useRef(false);
    const refIframe = useRef();
    const refIframeSrc = useRef(`${APP_URL}${ROUTES.IFRAME.CHECK_IN.slice(1)}`);
    const { isAutoCheckIn, isProcessingToggleAutoCheckIn, state, onToggleAutoCheckIn } = useAutoCheckInState();
    const [isInit, setIsInit] = useState(false);

    if (isVisible) {
        refIsRendered.current = true;
    }

    useEffect(() => {
        if (isVisible) {
            const onMouseDown = (e) => {
                if (refRoot.current && !refRoot.current.contains(e.target)) {
                    close();
                }
            };

            document.addEventListener('mousedown', onMouseDown);

            return () => {
                document.removeEventListener('mousedown', onMouseDown);
            };
        }
    }, [isVisible, close]);

    useEffect(() => {
        const onPostMessage = async ({ data, source }) => {
            if (data?.request && source?.window?.location?.href === refIframeSrc.current) {
                if (data.request === 'close') {
                    close();
                    return;
                }

                if (data.request === 'toggleAutoCheckIn') {
                    onToggleAutoCheckIn();
                    return;
                }

                if (data.request === 'setCheckInState') {
                    setState(data.state);
                    return;
                }

                if (data.request === 'init') {
                    setIsInit(true);
                }

                if (refIframe.current) {
                    refIframe.current.contentWindow.postMessage({
                        ...data,
                        authToken: await getBearerToken()
                    });
                }
            }
        };

        window.addEventListener('message', onPostMessage);

        return () => {
            window.removeEventListener('message', onPostMessage);
        };
    }, [close, onToggleAutoCheckIn]);

    useEffect(() => {
        if (refIframe.current && isInit) {
            (async () => {
                refIframe.current.contentWindow.postMessage({
                    authToken: await getBearerToken(),
                    request: 'setAutoCheckInState',
                    isAutoCheckIn,
                    isProcessingToggleAutoCheckIn,
                    state
                });
            })();
        }
    }, [isInit, isAutoCheckIn, isProcessingToggleAutoCheckIn, state]);

    if (!refIsRendered.current) {
        return null;
    }

    return createPortal(
        <div
            ref={refRoot}
            className={styles.Root}
            style={{ display: isVisible ? 'block' : 'none' }}>
            <iframe
                ref={refIframe}
                className={styles.iframe}
                src={refIframeSrc.current}
                title='Check-in' />
        </div>,
        window.document.body
    );
}
