import PropTypes from 'prop-types';
import React, {
    useRef
} from 'react';
import {
    Button
} from 'components';
import {
    useUpload
} from 'utils/use/upload';
import {
    cloudinaryWaterMarkImage
} from 'utils';
import CanvasDraw from 'react-canvas-draw';
import styles from './index.module.scss';
// Assets
import IconDone from './assets/done.svg';
import IconClear from './assets/clear.svg';

function dataURLToFile(dataURL, props) {
    const {
        name = 'filename',
        type = ''
    } = props || {};

    return fetch(dataURL)
        .then((result) => result.arrayBuffer())
        .then((buffer) => new File(
            [buffer],
            name,
            {
                type
            }
        ));
}

Draw.propTypes = {
    onChange: PropTypes.func.isRequired,
    formTemplateId: PropTypes.string.isRequired,
    value: PropTypes.string
};

export default function Draw(props) {
    const {
        onChange,
        formTemplateId,
        value
    } = props;
    const refCanvasDraw = useRef();
    const {
        isUploading,
        upload
    } = useUpload({
        formTemplateId,
        isPostProcess: false
    });

    const onClear = () => {
        if (value) {
            onChange(null);
        } else {
            refCanvasDraw.current.clear();
        }
    };

    const onSubmit = async () => {
        const file = await dataURLToFile(
            refCanvasDraw.current.getDataURL(),
            {
                name: 'drawing.png',
                type: 'image/png'
            }
        );

        const {
            uris
        } = await upload({
            files: [file]
        });

        if (uris.length) {
            onChange(
                cloudinaryWaterMarkImage(uris[0])
            );
        }
    };

    return (
        <div className={styles.Root}>
            {value
                ? <img
                    className={styles.image}
                    src={value}
                    alt='' />
                : <CanvasDraw
                    ref={refCanvasDraw}
                    className={styles.CanvasDraw}
                    brushRadius={1}
                    brushColor='#000' />
            }

            <div className={styles.buttons}>
                <Button
                    className={styles.clear}
                    onClick={onClear}>
                    <img src={IconClear} alt='' />
                </Button>

                <Button
                    className={styles.submit}
                    isProcessing={isUploading}
                    isDisabled={!!value}
                    colorActivityIndicator='#fff'
                    onClick={onSubmit}>
                    <img src={IconDone} alt='' />
                </Button>
            </div>
        </div>
    );
}
