import {
  getDate,
  getMonth,
  getYear,
  getHours,
  getMinutes
} from 'date-fns';

function downloadFile(blob) {
  const url = URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  const date = new Date();
  const dateString = `${getDate(date)}-${getMonth(date) + 1}-${getYear(date)} ${getHours(date)}:${getMinutes(date)}`;

  anchor.setAttribute('href', url);
  anchor.setAttribute('download', `storewise ${dateString}`);
  document.body.appendChild(anchor);
  anchor.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(anchor);
  }, 150);
}

async function downloadRemoteFile(fileUrl) {
  try {
    const response = await fetch(fileUrl);

    downloadFile(
      await response.blob()
    );
  } catch (err) {
    //
  }
}

export {
  downloadFile,
  downloadRemoteFile
};
