import PropTypes from 'prop-types';
import {
    getFileIconByUri
} from 'utils';
import styles from './index.module.scss';

File.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    hrefOriginal: PropTypes.string,
    name: PropTypes.string
};

export default function File(props) {
    const {
        className,
        href,
        hrefOriginal,
        name
    } = props;

    return (
        <a
            className={`${styles.Root} ${className}`}
            href={href}
            target='_blank'
            rel='noreferrer'
            download={name}>
            <img
                src={getFileIconByUri(hrefOriginal || href)}
                alt='' />

            {!!name && (
                <span>
                    {name}
                </span>
            )}
        </a>
    );
}
