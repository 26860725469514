let __setIsVisible = null;
let __setIsBarcodeScanner = null;
let __refCallbackOnTakePicture = null;
let __refCallbackOnChangeProcessingState = null;

//  <Camera />
export function useCameraCore(props) {
    const {
        setIsVisible,
        setIsBarcodeScanner,
        refCallbackOnTakePicture,
        refCallbackOnChangeProcessingState
    } = props;

    __setIsVisible = setIsVisible;
    __setIsBarcodeScanner = setIsBarcodeScanner;
    __refCallbackOnTakePicture = refCallbackOnTakePicture;
    __refCallbackOnChangeProcessingState = refCallbackOnChangeProcessingState;
}

// consumers
export function showCamera(props) {
    const {
        onTakePicture,
        onChangeProcessingState,
        isBarcodeScanner = false
    } = props;

    __setIsVisible(true);
    __setIsBarcodeScanner(isBarcodeScanner);
    __refCallbackOnTakePicture.current = onTakePicture;
    __refCallbackOnChangeProcessingState.current = onChangeProcessingState;
}
