import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Quagga from '@ericblade/quagga2';
import styles from './index.module.scss';

function getMedian(array) {
    const sortArray = (a, b) => a - b;

    array.sort(sortArray);

    const half = Math.floor(array.length / 2);

    if (array.length % 2 === 1) {
        return array[half];
    }

    return (array[half - 1] + array[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes) {
    const filterError = ({ error }) => error !== undefined;
    const mapError = ({ error }) => error;
    const errors = decodedCodes.filter(filterError).map(mapError);
    const medianOfErrors = getMedian(errors);

    return medianOfErrors;
}

BarcodeScanner.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default function BarcodeScanner(props) {
    const { onSubmit: _onSubmit } = props;
    const refComponent = useRef();
    const [value, setValue] = useState(null);
    const [videoHeight, setVideoHeight] = useState(window.innerHeight);

    const onSubmit = () => _onSubmit(value);

    useEffect(() => {
        const onProcessed = (result) => {
            const drawingCtx = Quagga.canvas.ctx.overlay;
            const drawingCanvas = Quagga.canvas.dom.overlay;

            if (result) {
                if (result.boxes) {
                    const filterBox = (box) => box !== result.box;
                    const forEachBox = (box) => {
                        Quagga.ImageDebug.drawPath(
                            box,
                            { x: 0, y: 1 },
                            drawingCtx,
                            { color: 'lime', lineWidth: 2 }
                        );
                    };

                    drawingCtx.clearRect(
                        0,
                        0,
                        drawingCanvas.width,
                        drawingCanvas.height
                    );
                    result.boxes.filter(filterBox).forEach(forEachBox);
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(
                        result.box,
                        { x: 0, y: 1 },
                        drawingCtx,
                        { color: 'lime', lineWidth: 2 }
                    );
                }
            }
        };

        let intervalSetCanvasHeight = null;

        Quagga.init({
            inputStream: {
                type: 'LiveStream',
                constraints: {
                    facingMode: 'environment'
                },
                target: refComponent.current
            },
            locator: {
                patchSize: 'medium',
                halfSample: true
            },
            numOfWorkers: navigator.hardwareConcurrency || 0,
            decoder: {
                readers: [
                    'code_128_reader',
                    'ean_reader',
                    'ean_8_reader',
                    'code_39_reader',
                    'code_39_vin_reader',
                    'codabar_reader',
                    'upc_reader',
                    'upc_e_reader',
                    'i2of5_reader',
                    '2of5_reader',
                    'code_93_reader',
                    'code_32_reader'
                ]
            },
            locate: true
        }, (err) => {
            Quagga.onProcessed(onProcessed);

            if (!err) {
                Quagga.start();

                // on Ready
                const onInterval = () => {
                    const nodes = refComponent.current.querySelectorAll('video');

                    if (nodes?.length) {
                        const { height } = nodes[0].getBoundingClientRect();

                        setVideoHeight(height);
                    }
                };

                intervalSetCanvasHeight = setInterval(onInterval, 1500);
            }
        });

        const onDetected = (result) => {
            const median = getMedianOfCodeErrors(result.codeResult.decodedCodes);

            // Accept the code if at least 75% certain that it read correctly
            if (median < 0.25 && result.codeResult.code) {
                setValue(result.codeResult.code);
            }
        };

        Quagga.onDetected(onDetected);

        return () => {
            clearInterval(intervalSetCanvasHeight);
            Quagga.offDetected(onDetected);
            Quagga.offProcessed(onProcessed);
            Quagga.stop();
        };
    }, []);

    return (
        <div
            ref={refComponent}
            className={styles.Component}
            style={{ height: videoHeight }}>
            <canvas
                className='drawingBuffer'
                style={{
                    position: 'absolute',
                    left: 0,
                    width: '100%'
                }}
                width='100%'
                height='100%' />

            {!!value && (
                <div
                    className={styles.submit}
                    onClick={onSubmit}>
                    {value}
                </div>
            )}
        </div>
    );
}
