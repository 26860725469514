import PropTypes from 'prop-types';
import React from 'react';
import {
    Addresses
} from 'components';
import styles from './index.module.scss';

Location.propTypes = {
    addresses: PropTypes.array.isRequired,
    setAddresses: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object
};

export default function Location(props) {
    const {
        addresses,
        setAddresses,
        onChange,
        value
    } = props;
    const {
        id: activeAddressId
    } = value || {};

    const onChangeAddress = (id, address) => {
        const {
            name
        } = address;

        onChange({
            id,
            name
        });
    };

    return (
        <Addresses
            className={styles.Root}
            classNameList={styles.List}
            isProcessing={false}
            isEditable={false}
            data={addresses}
            activeAddressId={activeAddressId}
            onChange={onChangeAddress}
            setAddresses={setAddresses} />
    );
}
