import PropTypes from 'prop-types';
import React from 'react';
import {
  downloadFile
} from 'utils/download';
import styles from './index.module.scss';
// Assets/
import IconDownload from './assets/download.svg';

const __mapColumn = (content) => {
  return content.replace(/\n/g, ' ').trim();
};

Table.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array
};

function Table(props) {
  const {
    className,
    data,
  } = props;

  const onDownload = () => {
    const csvRows = [];

    for (let i = 0, len = data.length; i < len; i++) {
      const columns = data[i];

      csvRows[i] = columns.map(__mapColumn).join(',');
    }

    downloadFile(
      new Blob(
        [csvRows.join('\r\n')],
        {
          type: 'text/csv'
        }
      )
    );
  };

  const renderColumn = (content, index) => {
    return (
      <div key={index}>
        {content}
      </div>
    );
  };

  const renderRow = (columns, index) => {
    if (!columns?.length) {
      return null;
    }

    return (
      <div key={index}>
        {columns.map(renderColumn)}
      </div>
    );
  };

  if (!data?.length) {
    return null;
  }

  return (
    <div className={`${styles.root} ${className}`}>
      {data.map(renderRow)}

      <div
        className={styles.download}
        onClick={onDownload}>
        <img src={IconDownload} alt='' />
      </div>
    </div>
  );
}

export default Table;
