import {
    LAPI,
    CHAT_USER_STATUS,
    CARDS_USER_STATUS,
    CHAT_MESSAGE_TYPES
} from 'config';

export const apiUsers = {
    me: () => LAPI.get('/v2/users/me'),
    meIFRAME: (Authorization) => LAPI.get('/v2/users/me', {
        headers: { Authorization }
    }),

    updateMe: (payload) => LAPI.post('/v2/users/me', payload),

    get: (params = {}) => LAPI.get('/users', { params }),
    getIFRAME: (Authorization, params = {}) => LAPI.get('/users', {
        params,
        headers: { Authorization }
    }),

    getPublic: () => LAPI.get('/users', { params: { public: true } }),
    getPublicIFRAME: (Authorization) => LAPI.get('/users', {
        params: { public: true },
        headers: { Authorization }
    }),

    getConnected: () => LAPI.get('/users', { params: { public: true, connected: true } }),
    getConnectedIFRAME: (Authorization) => LAPI.get('/users', {
        params: { public: true, connected: true },
        headers: { Authorization }
    }),

    getById: (id, isPublic = false) => LAPI.get('/users', { params: { id, public: isPublic } }),

    set: (id, data) => LAPI.post('/users', { id, ...data }),

    joinOrg: (code) => LAPI.post('/v2/users/me', {
        code
    }),
    leaveOrg: () => LAPI.delete('/users/me')
};

export const apiFiles = {
    // { extension, contentType, cl: true }
    requestPutUrl: (data) => LAPI.post('/upload', data),
    requestPutUrlIFRAME: (Authorization, data) => LAPI.post('/upload', data, {
        headers: { Authorization }
    }),
    convertPdf: (file) => LAPI.post('/upload/convert', { file })
};

export const apiOrg = {
    // https://envolve.atlassian.net/wiki/spaces/CP/pages/404422657/Organizations
    create: (data) => LAPI.post('/v2/organizations', data),
    createIFRAME: (Authorization, data) => LAPI.post('/v2/organizations', data, {
        headers: { Authorization }
    }),
    join: (code) => LAPI.post('/v2/organizations', { code }),
    update: (payload) => LAPI.post('/v2/organizations', payload),
    sendInvite: (data) => LAPI.post('/invites', data)
};

export const apiChat = {
    getChannels: () => LAPI.get('/channels'),
    getChannelsIFRAME: (Authorization) => LAPI.get('/channels', {
        headers: { Authorization }
    }),

    getChannel: (channelId) => LAPI.get(
        '/v2/channels/fetch',
        {
            params: {
                id: channelId,
            },
        }
    ),
    getChannelIFRAME: (Authorization, channelId) => LAPI.get(
        '/v2/channels/fetch',
        {
            params: {
                id: channelId,
            },
            headers: { Authorization }
        },
    ),
    getMessages: (channelId, data) => LAPI.get('/messages', { params: { channel_id: channelId, ...data } }),
    getMessagesIFRAME: (Authorization, id) => LAPI.get('/messages', {
        params: { id },
        headers: { Authorization }
    }),
    searchMessages: (params) => LAPI.get('/messages/search', { params }),
    updateChannel: (channelId, data) => LAPI.post('/v2/channels/update', { id: channelId, ...data }),
    createChannel: (data) => LAPI.post('/v2/channels/add', data),
    archiveChannel: (channelId, userId) => LAPI.post('/v2/channels/update', {
        id: channelId,
        users: [{ user_id: userId, status: CHAT_USER_STATUS.ARCHIVED }]
    }),
    unArchiveChannel: (channelId, userId) => LAPI.post('/v2/channels/update', {
        id: channelId,
        users: [{ user_id: userId, status: CHAT_USER_STATUS.VISIBLE }]
    }),
    createMessage: (channelId, data) => LAPI.post(
        '/v2/messages/add',
        {
            channel_id: channelId,
            message_id: data.id,
            ...data,
        },
    ),
    deleteMessage: (messageId) => LAPI.post('/v2/messages/update', {
        id: messageId,
        type: CHAT_MESSAGE_TYPES.TEXT,
        content: 'Deleted',
        attributes: {
            deleted: true,
        },
    }),
    setLastReadAt: (channelId, userId, timestamp) => LAPI.post('/v2/channels/update', {
        id: channelId,
        users: [{ user_id: userId, last_read_at: timestamp, status: CHAT_USER_STATUS.VISIBLE }]
    }),
    updateUserType: (channelId, { userId, type }) => LAPI.post('/v2/channels/update', {
        id: channelId,
        users: [{ user_id: userId, status: CHAT_USER_STATUS.VISIBLE, type }]
    }),
    addUsers: (channelId, userIds) => LAPI.post('/v2/channels/update', {
        id: channelId,
        users: userIds.map((id) => ({ user_id: id, status: CHAT_USER_STATUS.VISIBLE }))
    }),
    removeUser: (channelId, userId) => LAPI.post('/v2/channels/update', {
        id: channelId,
        users: [{ user_id: userId, status: CHAT_USER_STATUS.REMOVED }]
    }),
    setReaction: (messageId, content) => LAPI.post('/messages/reactions', { message_id: messageId, content }),
    setReactionIFRAME: (Authorization, messageId, content) => LAPI.post('/messages/reactions', {
        message_id: messageId,
        content
    }, {
        headers: { Authorization }
    })
};

export const apiDeviceTokens = {
    set: (data) => LAPI.post('/device-tokens', data)
};

export const apiForms = {
    getAllTemplates: (params) => LAPI.get('/form_templates', { params }),
    getOneTemplate: (formTemplateId, formTemplateCreatorId) => LAPI.get('/form_templates', {
        params: {
            id: formTemplateId,
            creator_id: formTemplateCreatorId
        }
    }),
    getAllForms: (params) => LAPI.get('/forms', { params }),
    getOneForm: (id) => LAPI.get('/forms', { params: { id } }),

    getGlobalTemplates: () => {
        return LAPI.get('/form_templates', {
            params: {
                global_templates: true
            }
        });
    },

    downloadFormResponses: (id) => LAPI.get('/forms', {
        params: {
            template_id: id,
            download: true
        }
    }),
    downloadFormResponsesPdf: (id) => LAPI.get('/forms/download', {
        params: {
            id,
            type: 'forms'
        }
    }),
    downloadFormResponse: ({ formTemplateId, formId }) => LAPI.get('/forms/download', {
        params: {
            id: formTemplateId,
            form_id: formId,
            type: 'forms'
        }
    }),

    saveFormTemplate: (data) => LAPI.post('/form_templates', data),
    saveForm: (data) => LAPI.post('/forms', data),

    archiveFormTemplate: (id) => LAPI.post('/form_templates', {
        id,
        status: -1
    }),
    deleteFormTemplate: (id) => LAPI.delete('/form_templates', {
        params: {
            id
        }
    })
};

export const apiWorkItems = {
    get: (id) => LAPI.get('/work-items', { params: { id } }),
    getIFRAME: (Authorization, id) => LAPI.get('/work-items', {
        params: { id },
        headers: { Authorization }
    }),

    getAllFormTemplates: (params) => LAPI.get('/form_templates', { params }),
    getOneFormTemplate: (id) => LAPI.get('/form_templates', { params: { id } }),
    getOneFormTemplateIFRAME: (Authorization, id) => LAPI.get('/form_templates', {
        params: { id },
        headers: { Authorization }
    }),
    getAllForms: () => LAPI.get('/forms'),
    getOneForm: (id) => LAPI.get('/forms', { params: { id } }),

    getWithFilters: (filters) => LAPI.get('/work-items', { params: filters }),

    getTemplatesIFRAME: (Authorization, props = {}) => LAPI.get('/work-items', {
        params: { is_template: true, ...props },
        headers: { Authorization }
    }),

    archive: (id) => LAPI.post('/work-items', { id, user_status: CARDS_USER_STATUS.ARCHIVED }),

    // getOne: (filters) => LAPI.get('/work-items', { params: filters }),
    // getById: (id) => LAPI.get('/work-items', { params: { id } }),
    // save: (data) => LAPI.post('/work-items', data),
    // saveAnswers: (data) => LAPI.post('/work-items/user', data),
    // delete: (id) => LAPI.post('/work-items', { id, status: -1 })

    getDownloadIFRAME: (Authorization, id) => LAPI.get('/work-items', {
        params: { id, download: true },
        headers: { Authorization }
    }),

    saveResponseIFRAME: (Authorization, id, data) => LAPI.post('/work-items/user', {
        id,
        ...(data || {})
    }, {
        headers: { Authorization }
    }),

    saveIFRAME: (Authorization, data) => LAPI.post('/work-items', data, {
        headers: { Authorization }
    }),

    saveFormIFRAME: (Authorization, data) => LAPI.post('/form_templates', data, {
        headers: { Authorization }
    }),

    saveFormResponseIFRAME: (Authorization, data) => LAPI.post('/forms', data, {
        headers: { Authorization }
    })
};

export const apiAttendances = {
    get: (params) => LAPI.get('/attendances', { params }),
    getIFRAME: (Authorization, params) => LAPI.get('/attendances', {
        params,
        headers: { Authorization }
    }),
    getUsersIFRAME: (Authorization) => LAPI.get('/attendances/users', {
        headers: { Authorization }
    }),
    set: (data) => LAPI.post('/attendances', data),
    setIFRAME: (Authorization, data) => LAPI.post('/attendances', data, {
        headers: { Authorization }
    })
};

export const apiAddress = {
    get: () => LAPI.get('/address'),
    getIFRAME: (Authorization) => LAPI.get('/address', {
        headers: { Authorization }
    }),

    setIFRAME: (Authorization, data) => LAPI.post('/address', data, {
        headers: { Authorization }
    }),

    getById: (id) => LAPI.get('/address', { params: { id } }),
    getByIdIFRAME: (Authorization, id) => LAPI.get('/address', {
        params: { id },
        headers: { Authorization }
    }),

    create: (data) => LAPI.post('/address', data),
    createIFRAME: (Authorization, data) => LAPI.post('/address', data, {
        headers: { Authorization }
    }),

    deleteIFRAME: (Authorization, id) => LAPI.post('/address', {
        id,
        deleted_at: new Date().toISOString().slice(0, -5)
    }, {
        headers: { Authorization }
    })
};

export const apiMetabase = {
    getEmbedUrl: (dashboard) => LAPI.get(`/metabase/dashboards/${dashboard}`)
};

export const apiMedia = {
    get: (params) => LAPI.get('/media', { params }),
    getIFRAME: (Authorization, params) => LAPI.get('/media', {
        params,
        headers: { Authorization }
    })
};

export const apiLinkPreview = {
    get: (link) => LAPI.post('/link-preview', {
        link
    })
};

export const apiUnsplash = {
    get: (params) => LAPI.post('/unsplash', params),
    getIFRAME: (Authorization, params) => LAPI.post('/unsplash', params, {
        headers: { Authorization }
    })
};

export const apiConnections = {
    request: (userId) => LAPI.post('/connections', { user_id: userId, status: 1 }),
    accept: (userId) => LAPI.post('/connections', { user_id: userId, status: 2 }),
    reject: (userId) => LAPI.post('/connections', { user_id: userId, status: -1 })
};

export const apiBing = {
    search: (q) => LAPI.post('/bing', { q })
};

export const apiDocument = {
    parseImage: (payload) => {
        return LAPI.post('/document-ai', payload);
    }
};
