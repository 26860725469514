import PropTypes from 'prop-types';
import React from 'react';
import {
    Button,
    ActivityIndicator
} from 'components';
import {
    WORKITEM_SURVEY_QUESTION_CONFIG
} from 'config/workitem';
import styles from './index.module.scss';
// Assets
import IconMandatory from './assets/mandatory.svg';

Tabs.propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
    questions: PropTypes.array,
    invalidQuestionIndexes: PropTypes.array.isRequired,
    activeQuestionIndex: PropTypes.number.isRequired,
    setActiveQuestionIndex: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    isProcessingFile: PropTypes.bool.isRequired,
    isProcessingSubmit: PropTypes.bool.isRequired,
    isProcessingDraft: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default function Tabs(props) {
    const {
        t,
        className,
        questions,
        invalidQuestionIndexes,
        activeQuestionIndex,
        setActiveQuestionIndex,
        onSaveDraft,
        isProcessingFile,
        isProcessingSubmit,
        isProcessingDraft,
        isSuccess,
        isLoading
    } = props;

    const onJumpToSummary = () => {
        setActiveQuestionIndex(questions.length);
    };

    const renderQuestion = (item, index) => {
        const {
            type,
            title,
            isMandatory
        } = item;
        const {
            iconBlack,
            iconWhite
        } = WORKITEM_SURVEY_QUESTION_CONFIG[type] || {};
        const isActive = index === activeQuestionIndex;
        const icon = isActive ? iconBlack : iconWhite;
        const isValid = invalidQuestionIndexes.indexOf(index) === -1;

        const onClick = () => {
            setActiveQuestionIndex(index);
        };

        return (
            <Button
                key={index}
                className={`${styles.question} ${isActive && styles.question__isActive}`}
                isDisabled={isProcessingSubmit || isProcessingFile}
                onClick={onClick}>
                <div>
                    <img src={icon} alt='' />

                    <div className={styles.questionTitle}>
                        {title}
                    </div>
                </div>

                {isValid
                    ? <span>
                        ✓
                    </span>
                    : isMandatory && (
                        <img src={IconMandatory} alt='' />
                    )
                }
            </Button>
        );
    };

    return (
        <div className={`${styles.Root} ${className}`}>
            <div className={styles.questions}>
                {!!questions?.length && questions.map(renderQuestion)}
                {isLoading && (
                    <ActivityIndicator
                        className={styles.ActivityIndicator} />
                )}
            </div>

            {(questions?.length && activeQuestionIndex !== questions?.length) && (
                <Button
                    className={styles.summary}
                    label={t('forms.respond.summary')}
                    isDisabled={isProcessingFile}
                    onClick={onJumpToSummary} />
            )}

            <Button
                className={styles.submit}
                label={t('forms.respond.save_draft')}
                colorActivityIndicator='#fff'
                isDisabled={(
                    isLoading ||
                    isSuccess ||
                    isProcessingSubmit ||
                    isProcessingFile
                )}
                isProcessing={isProcessingDraft}
                onClick={onSaveDraft} />
        </div>
    );
}
