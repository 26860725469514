import {
  useState
} from 'react';
import {
  setData
} from './useInputs';

let __setIsVisible;
let __setSelectTemplate;

export function showFormCreate(props) {
  const duplicate = !!props?.isDuplicate;

  if (typeof __setSelectTemplate === 'function') {
    __setSelectTemplate(!duplicate);
  }

  if (duplicate === true) {
    const {
      formTemplate
    } = props;

    setData({
      ...formTemplate,
      id: null,
    });
  }

  __setIsVisible(true);
}

export default function useFormCreate() {
  const [isVisible, setIsVisible] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState(true);

  __setIsVisible = setIsVisible;
  __setSelectTemplate = setSelectTemplate;

  const onShow = () => {
    setIsVisible(true);
  };

  const onHide = () => {
    setIsVisible(false);
  };

  return {
    selectTemplate,
    setSelectTemplate,
    isVisible,
    onShow,
    onHide
  };
}
