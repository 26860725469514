// import PropTypes from 'prop-types';
import React from 'react';
import ActivityIndicator from 'components/ActivityIndicator';
import {
    useLoading
} from './use/useLoading';
import styles from './index.module.scss';

export default function Loading() {
    const {
        isVisible
    } = useLoading();

    return (
        <div className={`${styles.Root} ${isVisible && styles.Root__isVisible}`}>
            <div className={`${styles.body} ${isVisible && styles.body__isVisible}`}>
                <ActivityIndicator size={42} />
            </div>
        </div>
    );
}
