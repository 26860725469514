import {
  events,
  EventName,
  GenericObject,
} from 'global';
import {
  TypingUser,
} from './types';

class TypingUsers {
  private static instance?: TypingUsers;
  public static getInstance(): TypingUsers {
    if (!TypingUsers.instance) {
      TypingUsers.instance = new TypingUsers();
    }

    return TypingUsers.instance;
  }

  private timeoutMap: GenericObject = {};
  private _typingUsers: TypingUser[] = [];
  private set typingUsers(typingUsers: TypingUser[]) {
    this._typingUsers = typingUsers;
    events().trigger(
      EventName.ChatUserTyping,
      typingUsers,
    );
  }
  public get typingUsers(): TypingUser[] {
    return this._typingUsers;
  }

  private constructor() {}

  public addTypingUser(typingUser: TypingUser): void {
    for (let i = 0; i < this.typingUsers.length; i++) {
      if (this.typingUsers[i].userId === typingUser.userId &&
          this.typingUsers[i].channelId === typingUser.channelId) {
        this.setRemovalTimeout(typingUser);
        return;
      }
    }

    this.typingUsers = [...this.typingUsers, typingUser];
    this.setRemovalTimeout(typingUser);
  }

  private setRemovalTimeout(typingUser: TypingUser): void {
    const timeoutKey: string = `${typingUser.channelId}-${typingUser.userId}`;

    clearTimeout(timeoutKey);

    this.timeoutMap[timeoutKey] = setTimeout(
      (): void => {
        this.removeTypingUser(typingUser);
      },
      2000,
    );
  }

  private removeTypingUser(typingUser: TypingUser): void {
    const nextTypingUsers: TypingUser[] = [];

    for (let i = 0, ix = 0; i < this.typingUsers.length; i++) {
      if (this.typingUsers[i].userId === typingUser.userId &&
          this.typingUsers[i].channelId === typingUser.channelId) {
        continue;
      }

      nextTypingUsers[ix++] = this.typingUsers[i];
    }

    this.typingUsers = nextTypingUsers;
  }

}

export default (): TypingUsers => {
  return TypingUsers.getInstance();
};
