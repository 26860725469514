import { useState, useCallback } from 'react';
import { apiChat } from 'api';
import { setActiveChannelId } from 'utils/use/chatActiveChannel';
import { getUserId } from 'utils/use/me';
import { CHAT_USER_STATUS, CHAT_USER_TYPES, CHAT_CHANNEL_TYPES } from 'config';
import { AssistantUserId } from 'global';

export function useChatCreate(props) {
    const [isProcessing, setIsProcessing] = useState(false);

    const createChat = useCallback(async (config) => {
        const { isPublic = false, userIds, title, cover } = config;
        const userId = getUserId();
        const mapUser = (id) => {
            let type = CHAT_USER_TYPES.MEMBER;

            // - chat creator will be admin
            // - for 1 to 1 chats both users will be admin
            if (id === userId || userIds.length === 1) {
                type = CHAT_USER_TYPES.ADMIN;
            }

            return {
                user_id: id,
                status: CHAT_USER_STATUS.VISIBLE,
                type
            };
        };
        let type;
        let returnData;

        setIsProcessing(true);

        try {
            if (userIds.length === 1) {
                type = CHAT_CHANNEL_TYPES.DIRECT;
            } else if (isPublic) {
                type = CHAT_CHANNEL_TYPES.PUBLIC;
            } else {
                type = CHAT_CHANNEL_TYPES.PRIVATE;
            }

            const { data } = await apiChat.createChannel({
                type,
                title: title || '',
                cover: cover || '',
                users: [userId, ...userIds].map(mapUser)
            });

            returnData = data;

            setActiveChannelId(data.id);
        } catch (err) {
            //
        } finally {
            setIsProcessing(false);
        }

        return returnData || {};
    }, []);

    const createSupportChat = useCallback(async () => {
        setIsProcessing(true);

        try {
            const mapUser = (id) => ({
                user_id: id,
                status: CHAT_USER_STATUS.VISIBLE,
                type: id === 'kvXzbpTpZjc0nhTskCzG6vyXxHa2' ? CHAT_USER_TYPES.ADMIN : CHAT_USER_TYPES.MEMBER
            });
            const { data } = await apiChat.createChannel({
                title: '',
                cover: '',
                type: CHAT_CHANNEL_TYPES.SUPPORT,
                users: [getUserId(), 'kvXzbpTpZjc0nhTskCzG6vyXxHa2'].map(mapUser)
            });

            setIsProcessing(false);
            setActiveChannelId(data.id);

            return true;
        } catch (err) {
            setIsProcessing(false);

            return false;
        }
    }, []);

    const createAssistantChat = async () => {
        try {
            const {
                data,
            } = await apiChat.createChannel({
                title: '',
                cover: '',
                type: CHAT_CHANNEL_TYPES.DIRECT,
                users: [
                    {
                        user_id: getUserId(),
                        status: CHAT_USER_STATUS.VISIBLE,
                        type: CHAT_USER_TYPES.MEMBER,
                    },
                    {
                        user_id: AssistantUserId,
                        status: CHAT_USER_STATUS.VISIBLE,
                        type: CHAT_USER_TYPES.ADMIN,
                    },
                ],
            });

            setActiveChannelId(data.id);
        } catch (error) {
            console.error(error);
        }
    };

    return {
        isProcessingChatCreation: isProcessing,
        createChat,
        createSupportChat,
        createAssistantChat,
    };
}
