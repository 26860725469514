import EXIF from 'exif-js';
import {
    AT_LOCATION_DISTANCE_THRESHOLD,
    GOOGLE_API_KEY
} from 'config';

let lastGeoLocation = null;
let lastGeoRequestTimestamp = null;

async function getGpsFromGeolocation() {
    const timestamp = Date.now();

    if (lastGeoRequestTimestamp &&
        lastGeoLocation &&
        timestamp - lastGeoRequestTimestamp < (15 * 60 * 1000)) {
        return lastGeoLocation;
    }

    lastGeoRequestTimestamp = timestamp;

    try {
        const response = await fetch(
            `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_API_KEY}`,
            {
                method: 'POST'
            }
        );

        const {
            location
        } = await response.json();

        lastGeoLocation = location;

        return location;
    } catch (err) {
        return false;
    }
}

function getDistanceBetweenGeolocations(coordsA, coordsB) {
    const { lat: latA, lng: lngA } = coordsA;
    const { lat: latB, lng: lngB } = coordsB;
    const radlatA = Math.PI * latA / 180;
    const radlatB = Math.PI * latB / 180;
    const theta = lngA - lngB;
    const radtheta = Math.PI * theta / 180;

    let dist = Math.sin(radlatA) * Math.sin(radlatB) + Math.cos(radlatA) * Math.cos(radlatB) * Math.cos(radtheta);

    dist = dist > 1 ? 1 : dist;
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;

    // distance between the coordiantes in kilometer
    return dist;
}

function getNearestAddressByGeolocation(addresses, geolocation) {
    for (let i = 0, len = addresses.length; i < len; i++) {
        const address = addresses[i];
        const {
            lat,
            lng
        } = address;
        const distance = getDistanceBetweenGeolocations(
            geolocation,
            {
                lat,
                lng
            }
        );

        if (distance <= AT_LOCATION_DISTANCE_THRESHOLD) {
            return address;
        }
    }

    return false;
}

function calcGpsFloat(data, ref) {
    const deg = data[0];
    const min = data[1];
    const sec = data[2];

    let gps = deg + (min / 60) + (sec / 3600);

    if (ref === 'S' || ref === 'W') {
        gps *= -1;
    }

    return gps;
}

function getGpsFromFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        const onLoad = () => {
            reader.removeEventListener('loadend', onLoad);

            const {
                GPSLatitude,
                GPSLatitudeRef,
                GPSLongitude,
                GPSLongitudeRef,
                DateTimeOriginal
            } = EXIF.readFromBinaryFile(reader.result) || {};

            if (GPSLatitude?.length === 3 &&
                GPSLongitude?.length === 3) {
                resolve({
                    lat: calcGpsFloat(GPSLatitude, GPSLatitudeRef),
                    lng: calcGpsFloat(GPSLongitude, GPSLongitudeRef),
                    timestamp: DateTimeOriginal
                });

                return;
            }

            resolve(false);
        };

        reader.addEventListener('loadend', onLoad);
        reader.readAsArrayBuffer(file);
    });
}

export {
    getGpsFromGeolocation,
    getGpsFromFile,
    getDistanceBetweenGeolocations,
    getNearestAddressByGeolocation
};
