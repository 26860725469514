// import PropTypes from 'prop-types';
import React, {
    useState
} from 'react';
import {
    useTranslation
} from 'react-i18next';
import {
    apiForms
} from 'api';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ActivityIndicator from 'components/ActivityIndicator';
import Tabs from './Tabs';
import {
    getGpsFromGeolocation
} from 'utils/gps';
import {
    ROUTES
} from 'config';
import {
    getInvalidQuestionIndexes
} from './utils';
import {
    WORKITEM_SURVEY_QUESTION_TYPES
} from 'config/workitem';
import Text from './Questions/Text';
import SingleSelection from './Questions/SingleSelection';
import MultiSelection from './Questions/MultiSelection';
import Attachment from './Questions/Attachment';
import Emotions from './Questions/Emotions';
import YesNo from './Questions/YesNo';
import Quantity from './Questions/Quantity';
import Price from './Questions/Price';
import LinearScale from './Questions/LinearScale';
import Draw from './Questions/Draw';
import Email from './Questions/Email';
import Location from './Questions/Location';
import Date from './Questions/Date';
import Time from './Questions/Time';
import Submit from './Submit';
import useFormRespond from './use/useFormRespond';
import useFormTemplate from './use/useFormTemplate';
import styles from './index.module.scss';

// FormRespond.propTypes = {};

export default function FormRespond() {
    const {
        t
    } = useTranslation();
    const {
        formTemplateId,
        formTemplateCreatorId,
        isVisible,
        isSuccess,
        isRestarting,
        setIsSuccess,
        onStartNew,
        onHide
    } = useFormRespond();
    const {
        formTemplate,
        activeQuestionIndex,
        setActiveQuestionIndex,
        questionsResponse,
        setQuestionsResponse,
        addresses,
        setAddresses,
        isLoading,
        error,
    } = useFormTemplate({
        formTemplateId,
        formTemplateCreatorId
    });
    const [isProcessingDraft, setIsProcessingDraft] = useState(false);
    const [isProcessingSubmit, setIsProcessingSubmit] = useState(false);
    const [isTabsVisible, setIsTabsVisible] = useState(false);
    const [isProcessingFile, setIsProcessingFile] = useState(false);
    const {
        title,
        description,
        attachments,
        cover,
        items: questions
    } = formTemplate || {};
    const invalidQuestionIndexes = getInvalidQuestionIndexes(questionsResponse);
    const isPreviousDisabled = (
        (description || attachments?.length
            ? activeQuestionIndex <= -1
            : activeQuestionIndex <= 0
        ) ||
        isProcessingSubmit ||
        isProcessingFile
    );
    const isSingleAttachment = attachments?.length === 1;
    const isNextDisabled = isProcessingFile;
    const isDescriptionVisible = activeQuestionIndex === -1;
    const isReady = (
        !isLoading &&
        !isRestarting
    );
    const coverStyle = {};

    if (cover?.indexOf('#') === 0) {
        coverStyle.backgroundColor = cover;
    } else {
        coverStyle.backgroundImage = `url(${cover})`;
    }

    if (window.location.href.indexOf(ROUTES.FORM_RESPOND_MOBILE()) > -1) {
        const screenRoot = document.getElementById('FormRespondMobile');

        if (screenRoot) {
            for (const key in coverStyle) {
                screenRoot.style[key] = coverStyle[key];
            }
        }
    }

    const onChangeValue = (value) => {
        setQuestionsResponse((prevState) => {
            const mapItem = (item, index) => {
                const {
                    type
                } = item;

                if (index === activeQuestionIndex) {
                    if (type === WORKITEM_SURVEY_QUESTION_TYPES.SINGLE_SELECTION ||
                        type === WORKITEM_SURVEY_QUESTION_TYPES.EMOTIONS ||
                        type === WORKITEM_SURVEY_QUESTION_TYPES.YES_NO) {
                        setActiveQuestionIndex((prevState) => {
                            return activeQuestionIndex + 1;
                        });
                    }

                    return {
                        ...item,
                        value
                    };
                }

                return item;
            };

            return prevState.map(mapItem);
        });
    };
    const onChangeItemValue = (itemIndex, itemValue) => {
        setQuestionsResponse((prevState) => {
            const mapItem = (item, index) => {
                const {
                    items
                } = item;

                if (index === activeQuestionIndex) {
                    const mapItem = (item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                value: itemValue
                            };
                        }

                        return item;
                    };

                    return {
                        ...item,
                        items: items.map(mapItem)
                    };
                }

                return item;
            };

            return prevState.map(mapItem);
        });
    };
    const onClickPrev = () => {
        setActiveQuestionIndex((prevState) => {
            return prevState - 1;
        });
    };
    const onClickNext = () => {
        setActiveQuestionIndex((prevState) => {
            return prevState + 1;
        });
    };
    const onToggleTabs = () => {
        setIsTabsVisible((prevState) => {
            return !prevState;
        });
    };
    const onSaveDraft = () => {
        setIsProcessingDraft(true);
        setIsProcessingDraft(false);
        // TODO
        window.alert('Coming soon!');
    };
    const onSubmit = async () => {
        setIsProcessingSubmit(true);

        try {
            const location = await getGpsFromGeolocation();

            await apiForms.saveForm({
                creator_id: formTemplateCreatorId,
                template_id: formTemplateId,
                items: questionsResponse,
                status: 12,
                ...(location || {})
            });

            setIsSuccess(true);
        } catch (err) {
            //
        } finally {
            setIsProcessingSubmit(false);
        }
    };

    const renderAttachment = (item, index) => {
        const {
            uri,
            type
        } = item;

        if (type.indexOf('video') !== -1) {
            return (
                <video
                    key={index}
                    src={uri}
                    controls={true} />
            );
        }

        return (
            <img
                key={index}
                src={uri}
                alt='' />
        );
    };

    const renderQuestions = () => {
        const activeQuestion = questionsResponse[activeQuestionIndex];
        const {
            type,
            items,
            value
        } = activeQuestion || {};

        switch (type) {
            case WORKITEM_SURVEY_QUESTION_TYPES.FREE_TEXT:
                return (
                    <Text
                        t={t}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.SINGLE_SELECTION:
                return (
                    <SingleSelection
                        t={t}
                        items={items}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.MULTI_SELECTION:
                return (
                    <MultiSelection
                        t={t}
                        items={items}
                        onChange={onChangeItemValue} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.ATTACHMENT:
                return (
                    <Attachment
                        t={t}
                        formTemplateId={formTemplateId}
                        isProcessingFile={isProcessingFile}
                        setIsProcessingFile={setIsProcessingFile}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.EMOTIONS:
                return (
                    <Emotions
                        t={t}
                        items={items}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.YES_NO:
                return (
                    <YesNo
                        t={t}
                        items={items}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.QUANTITY:
                return (
                    <Quantity
                        t={t}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.PRICE:
                return (
                    <Price
                        t={t}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.LINEAR_SCALE:
                return (
                    <LinearScale
                        t={t}
                        items={items}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.DRAW:
                return (
                    <Draw
                        t={t}
                        formTemplateId={formTemplateId}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.EMAIL:
                return (
                    <Email
                        t={t}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.LOCATION:
                return (
                    <Location
                        t={t}
                        addresses={addresses}
                        setAddresses={setAddresses}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.DATE:
                return (
                    <Date
                        t={t}
                        onChange={onChangeValue}
                        value={value} />
                );

            case WORKITEM_SURVEY_QUESTION_TYPES.TIME:
                return (
                    <Time
                        t={t}
                        onChange={onChangeValue}
                        value={value} />
                );

            default: {
                if (!isTabsVisible) {
                    setIsTabsVisible(true);
                }

                return (
                    <Submit
                        t={t}
                        isProcessing={isProcessingSubmit}
                        isSuccess={isSuccess}
                        invalidQuestionIndexes={invalidQuestionIndexes}
                        setActiveQuestionIndex={setActiveQuestionIndex}
                        onSubmit={onSubmit} />
                );
            }
        }
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Modal onClose={onHide}>
            <div
                className={styles.Root}
                style={coverStyle}>
                <div className={styles.backgroundOverlay} />

                <Tabs
                    t={t}
                    className={`${styles.Tabs} ${isTabsVisible && styles.Tabs__isVisible}`}
                    invalidQuestionIndexes={invalidQuestionIndexes}
                    questions={questions}
                    activeQuestionIndex={activeQuestionIndex}
                    setActiveQuestionIndex={setActiveQuestionIndex}
                    onSaveDraft={onSaveDraft}
                    isSuccess={isSuccess}
                    isProcessingFile={isProcessingFile}
                    isProcessingSubmit={isProcessingSubmit}
                    isProcessingDraft={isProcessingDraft}
                    isLoading={!isReady} />

                <div className={styles.body}>
                    <div className={styles.cover}>
                        {!isReady
                            ? <ActivityIndicator color='#fff' />
                            : title
                        }
                    </div>

                    {error && (
                        <div className={styles.error}>
                            {t('forms.respond.error')}
                        </div>
                    )}

                    {(isReady && !!questionsResponse?.length) && (
                        <>
                            {!!questionsResponse[activeQuestionIndex]?.title && (
                                <div className={styles.questionTitle}>
                                    {questionsResponse[activeQuestionIndex].title}
                                </div>
                            )}

                            {isDescriptionVisible
                                ? <>
                                    <div
                                        className={styles.description}
                                        dangerouslySetInnerHTML={{ __html: description }} />

                                    <div className={`${styles.attachments} ${isSingleAttachment && styles.attachments__isSingle}`}>
                                        {attachments?.map(renderAttachment)}
                                    </div>
                                </>
                                : <div className={styles.questions}>
                                    {renderQuestions()}
                                </div>
                            }

                            {isSuccess
                                ? <div className={`${styles.buttons} ${styles.buttons__isSuccess}`}>
                                    <Button
                                        className={styles.buttonClose}
                                        label={t('forms.respond.close_form')}
                                        onClick={onHide} />

                                    <Button
                                        className={styles.buttonRestart}
                                        label={t('forms.respond.start_new')}
                                        onClick={onStartNew} />
                                </div>
                                : <div className={`${styles.buttons} ${isDescriptionVisible && styles.buttons__centered}`}>
                                    {!isDescriptionVisible && (
                                        <Button
                                            className={styles.buttonPrev}
                                            isDisabled={isPreviousDisabled}
                                            label={t('forms.respond.previous')}
                                            onClick={onClickPrev} />
                                    )}

                                    {activeQuestionIndex < questionsResponse.length && (
                                        <Button
                                            className={styles.buttonNext}
                                            label={(
                                                isDescriptionVisible
                                                    ? t('forms.respond.get_started')
                                                    : t('forms.respond.next')
                                            )}
                                            isDisabled={isNextDisabled}
                                            onClick={onClickNext} />
                                    )}
                                </div>
                            }
                        </>
                    )}

                    <Button
                        className={styles.tabsToggle}
                        onClick={onToggleTabs}
                        label={(
                            isTabsVisible
                                ? t('forms.respond.navigation_hide')
                                : t('forms.respond.navigation_show')
                        )} />
                </div>
            </div>
        </Modal>
    );
}
