import PropTypes from 'prop-types';
import React from 'react';
import {
    Button
} from 'components';
import styles from './index.module.scss';

SingleSelection.propTypes = {
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number
};

export default function SingleSelection(props) {
    const {
        items,
        onChange,
        value
    } = props;

    const renderItem = (item, index) => {
        const {
            title
        } = item;
        const isActive = value === index;

        const onClick = () => {
            onChange(index);
        };

        return (
            <Button
                key={index}
                className={`${styles.item} ${isActive && styles.item__isActive}`}
                label={title}
                onClick={onClick} />
        );
    };

    return (
        <div className={styles.Root}>
            {items.map(renderItem)}
        </div>
    );
}
