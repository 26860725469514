import {
    useState
} from 'react';

let __setIsVisible;

export function showLoading() {
    __setIsVisible && __setIsVisible(true);
}

export function hideLoading() {
    __setIsVisible && __setIsVisible(false);
}

export function useLoading() {
    const [isVisible, setIsVisible] = useState(false);

    __setIsVisible = setIsVisible;

    return {
        isVisible,
        setIsVisible
    };
}
