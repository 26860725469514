import { apiPusher } from 'api/pusher';
import { getUserId } from './me';
import { EventName, events, typingUsers } from 'global';

let __isInit = false;
let __pusherChannel = null;
let __onlineUserIdMap = {
  '93f94ffd-3db6-4f78-9033-83c0bb93285e': true,
  'bd08b674-44b0-4e77-b7c0-8fd646abcb0e': true,
  'kvXzbpTpZjc0nhTskCzG6vyXxHa2': true,
};

export function init() {
  if (__isInit) {
    return;
  }

  __isInit = true;
  __pusherChannel = apiPusher.subscribe('presence-Channel.All');
  __pusherChannel.bind('pusher:member_added', onMemberAdded);
  __pusherChannel.bind('pusher:member_removed', onMemberRemoved);
  __pusherChannel.bind('client-typing', (data) => {
    if (data?.channelId &&
        data?.userId) {
      typingUsers().addTypingUser(data);
    }
  });
  __pusherChannel.bind('pusher:subscription_succeeded', (data) => {
    for (const userId in data.members) {
      __onlineUserIdMap[userId] = true;
    }

    events().trigger(
      EventName.UsersOnlineIdMap,
      __onlineUserIdMap,
    );
  });
}

export function triggerTypingEvent(channelId) {
  if (__pusherChannel) {
    __pusherChannel.trigger(
      'client-typing',
      {
        userId: getUserId(),
        channelId,
      },
    );
  }
}

function onMemberAdded(data) {
  __onlineUserIdMap[data.id] = true;

  events().trigger(
    EventName.UsersOnlineIdMap,
    __onlineUserIdMap,
  );
}

function onMemberRemoved(data) {
  __onlineUserIdMap[data.id] = false;

  events().trigger(
    EventName.UsersOnlineIdMap,
    __onlineUserIdMap,
  );
}
