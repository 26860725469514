import PropTypes from 'prop-types';
import React, {
    useState,
    useEffect
} from 'react';
import {
    Button,
    Input
} from 'components';
import {
    WORKITEM_SURVEY_QUESTION_TYPES,
    WORKITEM_SURVEY_QUESTION_CONFIG
} from 'config/workitem';
import styles from './index.module.scss';
// Assets
import IconRemove from './assets/remove.svg';

Builder.propTypes = {
    t: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    renderSubmit: PropTypes.func.isRequired,
    onRemoveQuestion: PropTypes.func.isRequired,
    onUnselectType: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialTitle: PropTypes.string.isRequired,
    initialOptions: PropTypes.array.isRequired,
    isExistingQuestion: PropTypes.bool.isRequired
};

export default function Builder(props) {
    const {
        t,
        type,
        renderSubmit,
        onRemoveQuestion,
        onUnselectType,
        onSubmit: _onSubmit,
        initialTitle,
        initialOptions,
        isExistingQuestion
    } = props;
    const [title, setTitle] = useState(initialTitle);
    const [options, setOptions] = useState(initialOptions);
    const {
        isItems: isOptionsType
    } = WORKITEM_SURVEY_QUESTION_CONFIG[type];
    const filterEmptyOption = (option) => {
        const {
            title
        } = option;

        return !!title;
    };
    const isValid = (
        !!title &&
        (!isOptionsType || (isOptionsType && options.filter(filterEmptyOption).length > 0))
    );

    const onChangeTitle = (e) => {
        const {
            target
        } = e;
        const {
            value
        } = target;

        setTitle(value);
    };
    const onAddOption = (title) => {
        title = typeof title === 'string' ? title : '';

        setOptions((prevState) => {
            return [
                ...prevState,
                { title }
            ];
        });
    };
    const onSubmit = () => {
        let nextOptions;

        if (type === WORKITEM_SURVEY_QUESTION_TYPES.EMOTIONS) {
            nextOptions = [
                { title: '😄' },
                { title: '😳' },
                { title: '😢' },
                { title: '😡' }
            ];
        } else if (type === WORKITEM_SURVEY_QUESTION_TYPES.YES_NO) {
            nextOptions = [
                { title: t('forms.create.form.questions.option.yes') },
                { title: t('forms.create.form.questions.option.no') }
            ];
        } else {
            nextOptions = options.filter(filterEmptyOption);
        }

        _onSubmit({
            title,
            options: nextOptions
        });
    };

    useEffect(() => {
        if (initialTitle && initialOptions) {
            setTitle(initialTitle);
            setOptions(initialOptions);
        }
    }, [initialTitle, initialOptions]);

    const renderOption = (item, index) => {
        const {
            title: optionTitle
        } = item;
        const onChangeOptionTitle = (e) => {
            const {
                target
            } = e;
            const {
                value
            } = target;

            setOptions((prevState) => {
                const nextOptions = [...prevState];

                for (let i = 0, len = nextOptions.length; i < len; i++) {
                    if (i === index) {
                        nextOptions[i].title = value;
                    }
                }

                return nextOptions;
            });
        };
        const onRemoveOption = () => {
            setOptions((prevState) => {
                const nextOptions = [];

                for (let i = 0, ix = 0, len = prevState.length; i < len; i++) {
                    if (i !== index) {
                        nextOptions[ix++] = prevState[i];
                    }
                }

                return nextOptions;
            });
        };
        const onKeyDown = (e) => {
            const {
                key
            } = e;

            if (key === 'Enter') {
                onAddOption();
            }
        };
        const onPaste = (e) => {
            e.preventDefault();

            const textLines = e.clipboardData.getData('Text').split('\n');

            setOptions((prevState) => {
                const nextOptions = [...prevState];

                for (let i = 0, len = nextOptions.length; i < len; i++) {
                    if (i === index) {
                        nextOptions[i].title = textLines[0];
                    }
                }

                return nextOptions;
            });

            for (let i = 1, len = textLines.length; i < len; i++) {
                if (textLines[i]) {
                    onAddOption(textLines[i]);
                }
            }
        };

        return (
            <div
                key={index}
                className={styles.option}>
                <Input
                    className={styles.InputOption}
                    placeholder={t('forms.create.form.questions.option.placeholder')}
                    autoFocus={true}
                    value={optionTitle}
                    onKeyDown={onKeyDown}
                    onPaste={onPaste}
                    onChange={onChangeOptionTitle} />

                <Button
                    className={styles.removeOption}
                    onClick={onRemoveOption}>
                    <img src={IconRemove} alt='' />
                </Button>
            </div>
        );
    };

    return (
        <div className={styles.Root}>
            <Input
                className={styles.Input}
                placeholder={t('forms.create.form.questions.title.placeholder')}
                autoFocus={true}
                value={title}
                onChange={onChangeTitle} />

            {isOptionsType && (
                <>
                    <div className={styles.options}>
                        {options.map(renderOption)}
                    </div>

                    <Button
                        className={styles.addOption}
                        label={t('forms.create.form.questions.add_option')}
                        onClick={onAddOption} />
                </>
            )}

            <div className={styles.footer}>
                {isExistingQuestion
                    ? renderSubmit({
                        className: styles.removeQuestion,
                        onClick: onRemoveQuestion,
                        label: t('forms.create.form.questions.remove_question')
                    })
                    : renderSubmit({
                        className: styles.changeQuestion,
                        onClick: onUnselectType,
                        label: t('forms.create.form.questions.change_type')
                    })
                }

                {renderSubmit({
                    isValid,
                    isDisabled: !isValid,
                    label: isExistingQuestion
                        ? t('forms.create.form.questions.edit_question')
                        : t('forms.create.form.questions.add_question'),
                    onClick: onSubmit
                })}
            </div>
        </div>
    );
}
