import {
    useOutsideClick
} from './outsideClick';

let __onToggle = null;

//  <Menu />
export function useMenuCore(props) {
    const {
        ref,
        setData,
        onClose
    } = props;

    __onToggle = setData;

    useOutsideClick(ref, onClose);
}

// consumers
export function useMenu(props) {
    const {
        items
    } = props;

    const onToggle = (e) => {
        e.preventDefault();
        e.stopPropagation();
        __onToggle({
            top: e.clientY,
            left: e.clientX,
            items
        });
    };

    return {
        onToggle
    };
}
