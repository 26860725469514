import PropTypes from 'prop-types';
import React from 'react';
import './index.scss';

SuspenseFallback.propTypes = {
    className: PropTypes.string
};

SuspenseFallback.defaultProps = {
    className: ''
};

export default function SuspenseFallback({ className }) {
    return (
        <div className={`SuspenseFallback ${className || ''}`} />
    );
}
