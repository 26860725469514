
export enum Language {
  English = 'en',
  French = 'fr',
  Malay = 'ml',
  Indonesian = 'id',
  Arabic = 'ar',
  Filipino = 'fl',
  Chinese = 'zh',
  Vietnamese = 'vi',
}

export enum NativeLanguageName {
  English = 'English',
  French = 'Français',
  Malay = 'Melayu',
  Indonesian = 'Bahasa Indonesia',
  Arabic = 'عربي',
  Filipino = 'Filipino',
  Chinese = '中国人',
  Vietnamese = 'Tiếng Việt',
};

export const DefaultLanguage: Language = Language.English;

export const TermsOfUseUrl: string = 'https://getstorewise.com/terms-of-use/';
export const PrivacyPolicyUrl: string = 'https://getstorewise.com/privacy-statement/';

enum BuildEnvironment {
  Local = 'local',
  Dev = 'dev',
  Demo = 'demo',
  Prod = 'production',
}

const CurrentBuildEnvironment: BuildEnvironment = process.env.REACT_APP_ENV as BuildEnvironment;

export const AssistantUserId: string = {
  [BuildEnvironment.Local]: '800edac2-dd3a-4690-bce3-c8814196771a',
  [BuildEnvironment.Dev]: '800edac2-dd3a-4690-bce3-c8814196771a',
  [BuildEnvironment.Demo]: 'aa621b82-c140-423c-9874-42661ff6eddc',
  [BuildEnvironment.Prod]: '93f94ffd-3db6-4f78-9033-83c0bb93285e',
}[CurrentBuildEnvironment];
