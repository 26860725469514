import {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  MouseEvent,
} from 'react';
import {
  createPortal,
} from 'react-dom';
import {
  EventName,
  Unsubscribe,
  events,
} from 'global';
import {
  FrameOpenEvent,
  FrameWidthLimits,
} from './types';
import rightFrameView, {
  FrameComponents,
  FrameType,
} from '.';
import RightFrameUtil from './util';
import styles from './index.module.scss';

function RightFrameViewProvider(): JSX.Element {
  const mouseDownX: MutableRefObject<number> = useRef<number>(0);
  
  const [width, setWidth] = useState((): number => {
    return RightFrameUtil.getWidthLimits().min;
  });
  const refCurrentWidth: MutableRefObject<number> = useRef<number>(width);
  const refLastWidth: MutableRefObject<number> = useRef<number>(width);

  const [activeFrameType, setActiveFrameType] = useState<FrameType | null>(null);
  const [activeFrameProps, setActiveFrameProps] = useState<Record<string, any>>({});

  const [dragging, setDragging] = useState<boolean>(false);
  
  const onMouseDown = (event: MouseEvent): void => {
    event.preventDefault();
    mouseDownX.current = event.clientX;
    setDragging(true);
  };

  useEffect((): Unsubscribe => {
    return events().listenTo(
      EventName.RightFrameView,
      (event: FrameOpenEvent): void => {
        setActiveFrameType(event.frameType);
        setActiveFrameProps(event.props);
      },
    );
  }, []);

  useEffect((): Unsubscribe | void => {
    if (dragging) {
      const onMouseUp = (): void => {
        setDragging(false);
        refLastWidth.current = refCurrentWidth.current;
      };

      const onMouseMove = (event: MouseEvent): void => {
        event.preventDefault();

        const xMoved: number = mouseDownX.current - event.clientX;
        const nextWidth: number = refLastWidth.current + xMoved;
        const limits: FrameWidthLimits = RightFrameUtil.getWidthLimits();

        if (nextWidth < limits.min ||
            nextWidth > limits.max) {
          return;
        }

        refCurrentWidth.current = nextWidth;
        setWidth(nextWidth);
      };

      window.addEventListener('mouseup', onMouseUp);
      // @ts-ignore
      window.addEventListener('mousemove', onMouseMove);

      return (): void => {
        window.removeEventListener('mouseup', onMouseUp);
        // @ts-ignore
        window.removeEventListener('mousemove', onMouseMove);
      };
    }
  }, [dragging]);

  const onClose = (): void => {
    rightFrameView().hide();
  };

  const renderFrameComponent = (): JSX.Element | null => {
    if (activeFrameType !== null) {
      const FrameComponent: (props: any) => JSX.Element = FrameComponents[activeFrameType];

      return (
        <FrameComponent
          onClose={onClose}
          {...activeFrameProps} />
      );
    }

    return null;
  };

  return createPortal(
    <div
      className={styles.root}
      style={{
        width,
        transform: activeFrameType !== null
          ? 'translateX(0)'
          : `translateX(${width}px)`,
      }}>
      <div className={styles.frame}>
        {renderFrameComponent()}
      </div>

      {dragging && (
        <div className={styles.overlay} />
      )}

      {activeFrameType !== null && (
        <div
          className={styles.drag}
          onMouseDown={onMouseDown} />
      )}
    </div>,
    window.document.body
  );
}

export default RightFrameViewProvider;
