import PropTypes from 'prop-types';
import React from 'react';
import {
    useTitle
} from 'containers/App/FormCreate/use/useInputs';
import styles from './index.module.scss';

Title.propTypes = {
    t: PropTypes.func.isRequired,
    renderSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default function Title(props) {
    const {
        t,
        renderSubmit,
        onSubmit
    } = props;
    const {
        value,
        setValue
    } = useTitle();

    const onChange = (e) => {
        const {
            target
        } = e;
        const {
            value
        } = target;

        setValue(value);
    };

    return (
        <div className={styles.Root}>
            <input
                className={styles.input}
                placeholder={t('forms.create.form.title.placeholder')}
                required={true}
                value={value}
                onChange={onChange} />

            {renderSubmit({
                className: styles.submit,
                isValid: !!value,
                onClick: onSubmit
            })}
        </div>
    );
}
