import PropTypes from 'prop-types';
import React from 'react';
import {
    Button
} from 'components';
import styles from './index.module.scss';

MultiSelection.propTypes = {
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function MultiSelection(props) {
    const {
        items,
        onChange
    } = props;

    const renderItem = (item, index) => {
        const {
            title,
            value
        } = item;

        const onClick = () => {
            onChange(index, !value);
        };

        return (
            <Button
                key={index}
                className={`${styles.item} ${!!value && styles.item__isActive}`}
                label={title}
                onClick={onClick} />
        );
    };

    return (
        <div className={styles.Root}>
            {items.map(renderItem)}
        </div>
    );
}
