import PropTypes from 'prop-types';
import {
  useState
} from 'react';
import {
  InputFile
} from 'components';
import {
  cloudinaryWaterMarkImage,
  getFileIconByUri
} from 'utils';
import Image from './Image';
import styles from './index.module.scss';
// Assets
import IconRemove from './assets/remove.svg';

Attachment.propTypes = {
  t: PropTypes.func.isRequired,
  formTemplateId: PropTypes.string.isRequired,
  isProcessingFile: PropTypes.bool.isRequired,
  setIsProcessingFile: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array
};

export default function Attachment(props) {
    const {
        t,
        formTemplateId,
        isProcessingFile,
        setIsProcessingFile,
        onChange,
        value
    } = props;
  
    const [progress, setProgress] = useState(0);

    const onChangeFile = (uris, data) => {
        const mapFile = (file) => {
            if (file.type.indexOf('image') > -1) {
                return {
                    ...file,
                    uri: cloudinaryWaterMarkImage(file.uri)
                };
            }

            return file;
        };

        onChange([
            ...(value || []),
            ...data.map(mapFile)
        ]);
    };

    const renderFile = (file, index) => {
        const {
            name,
            uri,
            type
        } = file;
        const isImage = type.indexOf('image') > -1;
        const onRemove = () => {
            const filterFile = (file) => {
                return file.uri !== uri;
            };

            onChange(value.filter(filterFile));
        };

        return (
            <div
                key={index}
                className={styles.file}>
                {isImage
                    ? <div className={styles.fileImage}>
                        <Image src={uri} />
                    </div>
                    : <div className={styles.fileGeneric}>
                        <img
                            src={getFileIconByUri(uri)}
                            alt='' />

                        <div className={styles.fileName}>
                            {name}
                        </div>
                    </div>
                }

                <div
                    className={styles.fileRemove}
                    onClick={onRemove}>
                    <img
                        src={IconRemove}
                        alt='' />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.Root}>
            <InputFile
                className={styles.InputFile}
                isDisabled={isProcessingFile}
                isMultiple={true}
                isGpsRequired={true}
                useDragAndDrop={true}
                formTemplateId={formTemplateId}
                onChangeProcessingState={setIsProcessingFile}
                onChangeUploadProgress={setProgress}
                onChange={onChangeFile}>
                {isProcessingFile
                    ? <div className={styles.progress}>
                        <div className={styles.progressText}>
                            {`${progress}%`}
                        </div>

                        <div className={styles.progressBar}>
                            <div
                                style={{
                                    width: `${progress}%`
                                }} />
                        </div>
                    </div>
                    : t('forms.respond.questions.upload.placeholder')
                }
            </InputFile>

            {!!value?.length && (
                <div className={styles.files}>
                    {value.map(renderFile)}
                </div>
            )}
        </div>
    );
}
