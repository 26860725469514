import {
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, TutorialFirstLogin, Pulse } from 'components';
import {
    ROUTES,
    ROUTES_WITH_NAV,
    STOREWISE_VERSION,
} from 'config';
import { useProfile, logout } from 'utils/use/me';
import { useAutoCheckIn, useAutoCheckInState, STATES as CHECK_IN_STATES } from 'utils/use/autoCheckIn';
import { useCheckIn } from 'containers/App/CheckIn/useCheckIn';
import { useChatCreate } from 'utils/use/chatCreate';
import { useConnectionRequests } from 'utils/use/useConnectionRequests';
import styles from './index.module.scss';
// Assets
import IconChat from './assets/chat.svg';
import IconCards from './assets/cards.svg';
import IconForms from './assets/forms.svg';
import IconInsights from './assets/insights.svg';
import IconSettings from './assets/settings.svg';
import IconHelp from './assets/help.svg';
import IconAi from './ai.svg';
import IconSendInvite from './assets/send-invite.svg';
import IconLogout from './assets/logout.svg';
import IconCheckInIn from './assets/checkin-in.svg';
import IconCheckInOut from './assets/checkin-out.svg';
import IconDownload from './assets/download.svg';
import rightFrameView, { FrameType } from '../RightFrameView';

function getIsVisible(props) {
    const {
        location,
        isUserId
    } = props;
    const {
        pathname
    } = location;
    let isNavRoute = false;
    let activeRoute;

    for (let i = 0, len = ROUTES_WITH_NAV.length; i < len; i++) {
        if (pathname.indexOf(ROUTES_WITH_NAV[i]) === 0) {
            isNavRoute = true;
            activeRoute = ROUTES_WITH_NAV[i];
            break;
        }
    }

    return {
        isVisible: isUserId && isNavRoute,
        activeRoute
    };
}

export default function Nav() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        id: userId,
        photo_url: avatar,
        display_name: username
    } = useProfile();

    const [loggingOut, setLoggingOut] = useState(false);
    const [loadingAssistantChat, setLoadingAssistantChat] = useState(false);

    const {
        isVisible: isNavVisible,
        activeRoute
    } = getIsVisible({
        location,
        isUserId: !!userId
    });

    const {
        state: checkInState
    } = useAutoCheckInState();
    const {
        count: connectionRequestCount
    } = useConnectionRequests({
        isReady: isNavVisible
    });
    const {
        isVisible: isVisibleCheckIn,
        open: openCheckIn
    } = useCheckIn();
    const {
        createSupportChat,
        createAssistantChat,
        isProcessingChatCreation: isProcessingSupport
    } = useChatCreate();
    const items = [
        {
            label: t('nav.label.assistant'),
            isProcessing: loadingAssistantChat,
            iconSrc: IconAi,
            onClick: async () => {
                navigate(ROUTES.CHAT);
                setLoadingAssistantChat(true);
                await createAssistantChat();
                setLoadingAssistantChat(false);
            },
        },
        { label: t('nav.label.invite'), iconSrc: IconSendInvite, route: ROUTES.INVITE, count: connectionRequestCount },
        { label: t('nav.label.chats'), iconSrc: IconChat, route: ROUTES.CHAT },
        { label: t('nav.label.cards'), iconSrc: IconCards, route: ROUTES.CARDS() },
        { label: t('nav.label.forms'), iconSrc: IconForms, route: ROUTES.FORMS() },
        { label: t('nav.label.insights'), iconSrc: IconInsights, route: ROUTES.INSIGHTS },
        { label: t('nav.label.settings'), iconSrc: IconSettings, route: ROUTES.SETTINGS },
    ];

    useAutoCheckIn();

    const onClickAvatar = () => {
        rightFrameView().show(FrameType.Profile);
        navigate(ROUTES.SETTINGS);
    };

    const onRoute = (route) => route && navigate(route);
    const renderItem = (item, index) => {
        const {
            isProcessing,
            isHidden,
            label,
            iconSrc,
            route,
            count,
            onClick: _onClick
        } = item;
        const isActive = route === activeRoute;
        const _onRoute = () => onRoute(route);
        const onClick = _onClick || _onRoute;
        const isDisabled = !route && !_onClick;

        if (isHidden) {
            return null;
        }

        if (isDisabled) {
            return (
                <div
                    key={index}
                    className={`${styles.item} ${styles.item__isDisabled}`}
                    title={label}>
                    <img src={iconSrc} alt='' />
                </div>
            );
        }

        return (
            <Button
                key={index}
                isProcessing={isProcessing}
                className={`${styles.item} ${isActive && styles.item__isActive}`}
                colorActivityIndicator='#fff'
                onClick={onClick}
                title={label}>
                <img src={iconSrc} alt='' />

                {!!count && (
                    <div className={styles.item_count}>
                        {count}
                    </div>
                )}
            </Button>
        );
    };

    if (!isNavVisible) {
        return null;
    }

    return (
        <>
            <div className={styles.Component}>
                <div
                    className={styles.Component__header}
                    onClick={onClickAvatar}>
                    <Avatar
                        className={styles.Component__header_Avatar}
                        src={avatar}
                        alt={username} />
                </div>

                {checkInState === CHECK_IN_STATES.PROCESSING
                    ? <div
                        className={`${styles.checkIn} ${styles.checkIn__loader}`}
                        onClick={!isVisibleCheckIn ? openCheckIn : null}>
                        <Pulse />
                    </div>
                    : <div
                        className={styles.checkIn}
                        onClick={!isVisibleCheckIn ? openCheckIn : null}>
                        {checkInState === CHECK_IN_STATES.CHECKED_IN
                            ? <img src={IconCheckInOut} alt='' />
                            : <img src={IconCheckInIn} alt='' />
                        }
                    </div>
                }

                <div className={styles.Component__version}>
                    {`v${STOREWISE_VERSION}`}
                </div>

                <div className={styles.Component__items}>
                    {items.map(renderItem)}
                </div>

                <div className={styles.Component__items_line} />

                {renderItem({
                    label: t('nav.label.download_desktop_app'),
                    iconSrc: IconDownload,
                    onClick: () => {
                        window.open('https://dl.todesktop.com/210508ljvgx21v5', '_blank');
                    }
                })}
                {renderItem({
                    label: t('nav.label.help_and_support'),
                    isProcessing: isProcessingSupport,
                    iconSrc: IconHelp,
                    onClick: async () => {
                        if (await createSupportChat()) {
                            setTimeout(() => {
                                navigate(ROUTES.CHAT);
                            }, 300);
                        }
                    }
                })}

                <div className={styles.Component__space} />

                <div className={styles.Component__logout}>
                    {renderItem({
                        isProcessing: loggingOut,
                        label: t('nav.label.logout'),
                        iconSrc: IconLogout,
                        onClick: () => {
                            setLoggingOut(true);
                            logout();
                        },
                    })}
                </div>

                <TutorialFirstLogin />
            </div>

            <div className={styles.margin} />
        </>
    );
}
