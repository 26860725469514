import {
    useState,
    useEffect
} from 'react';

let __setIsVisible;
let __setData;

export function showGallery(data) {
    if (__setData && __setIsVisible) {
        __setData(data);
        __setIsVisible(true);
    }
}

export function hideGallery(data) {
    __setIsVisible && __setIsVisible(true);
}

export function useGallery() {
    const [isVisible, setIsVisible] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        __setIsVisible = setIsVisible;
        __setData = setData;
    }, []);

    return {
        isVisible,
        setIsVisible,
        data
    };
}
