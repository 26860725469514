import PropTypes from 'prop-types';
import {
    useState
} from 'react';
import {
    useDescription,
    useAttachments
} from 'containers/App/FormCreate/use/useInputs';
import {
    InputFile,
    ActivityIndicator
} from 'components';
import {
    cloudinaryVideoThumbnail
} from 'utils';
import styles from './index.module.scss';
// Assets
import IconUpload from './assets/upload.svg';
import IconRemove from './assets/remove.svg';

Description.propTypes = {
    renderSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default function Description(props) {
    const {
        renderSubmit,
        onSubmit
    } = props;
    const {
        value: description,
        setValue: setDescription
    } = useDescription();
    const {
        value: attachments,
        setValue: setAttachments
    } = useAttachments();
    const [isProcessingUpload, setIsProcessingUpload] = useState(false);

    const onChangeUploadFile = (uris, files) => {
        setAttachments((prevState) => ([
            ...prevState,
            ...files
        ]));
    };

    const onChangeText = (e) => {
        setDescription(e.target.value);
    };

    const onKeyDown = (e) => {
        e.stopPropagation();
    };

    const renderAttachment = (item, index) => {
        const {
            uri: _uri,
            type
        } = item;
        let uri = _uri;

        if (type.indexOf('video') !== -1) {
            uri = cloudinaryVideoThumbnail(uri);
        }

        const onRemove = () => {
            setAttachments((prevState) => {
                const filterItem = (_item, _index) => {
                    return _index !== index;
                };

                return prevState.filter(filterItem);
            });
        };

        return (
            <div
                key={index}
                className={styles.attachment}
                style={{
                    backgroundImage: `url(${uri})`
                }}>
                <div
                    className={styles.attachment_remove}
                    onClick={onRemove}>
                    <img src={IconRemove} alt='' />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.Root}>
            <textarea
                className={styles.textarea}
                onKeyDown={onKeyDown}
                value={description}
                onChange={onChangeText} />

            <InputFile
                className={styles.InputFile}
                isDisabled={isProcessingUpload}
                isProcessAnalytics={false}
                isMultiple={true}
                accept='image/*,video/*'
                onChangeProcessingState={setIsProcessingUpload}
                onChange={onChangeUploadFile}>
                {isProcessingUpload
                    ? <ActivityIndicator />
                    : <img src={IconUpload} alt='' />
                }
            </InputFile>

            {attachments.length > 0 && (
                <div className={styles.attachments}>
                    {attachments.map(renderAttachment)}
                </div>
            )}

            {renderSubmit({
                className: styles.submit,
                isValid: true,
                onClick: onSubmit
            })}
        </div>
    );
}
