// import PropTypes from 'prop-types';
import {
    WORKITEM_SURVEY_QUESTION_TYPES
} from 'config/workitem';
import isEmail from 'validator/lib/isEmail';

export function getInvalidQuestionIndexes(questionsResponse) {
    const invalidQuestionIndexes = [];

    if (questionsResponse?.length) {
        for (let i = 0, len = questionsResponse.length; i < len; i++) {
            const {
                type,
                value,
                items
            } = questionsResponse[i];
            let isValid = true;

            switch (type) {
                case WORKITEM_SURVEY_QUESTION_TYPES.FREE_TEXT:
                case WORKITEM_SURVEY_QUESTION_TYPES.DRAW:
                case WORKITEM_SURVEY_QUESTION_TYPES.LOCATION:
                case WORKITEM_SURVEY_QUESTION_TYPES.DATE:
                case WORKITEM_SURVEY_QUESTION_TYPES.TIME: {
                    isValid = !!value;
                    break;
                }

                case WORKITEM_SURVEY_QUESTION_TYPES.SINGLE_SELECTION:
                case WORKITEM_SURVEY_QUESTION_TYPES.YES_NO:
                case WORKITEM_SURVEY_QUESTION_TYPES.EMOTIONS:
                case WORKITEM_SURVEY_QUESTION_TYPES.LINEAR_SCALE: {
                    isValid = typeof value === 'number' && value > -1;
                    break;
                }

                case WORKITEM_SURVEY_QUESTION_TYPES.PRICE: {
                    isValid = typeof value === 'number';
                    break;
                }

                case WORKITEM_SURVEY_QUESTION_TYPES.ATTACHMENT: {
                    isValid = typeof value?.length === 'number' && value.length > 0;
                    break;
                }

                case WORKITEM_SURVEY_QUESTION_TYPES.QUANTITY: {
                    isValid = !!value && value >= 0;
                    break;
                }

                case WORKITEM_SURVEY_QUESTION_TYPES.EMAIL: {
                    isValid = isEmail(value || '');
                    break;
                }

                case WORKITEM_SURVEY_QUESTION_TYPES.MULTI_SELECTION: {
                    let isItemsValid = false;

                    for (let i = 0, len = items.length; i < len; i++) {
                        const {
                            value
                        } = items[i];

                        if (value) {
                            isItemsValid = true;
                            break;
                        }
                    }

                    isValid = isItemsValid;
                    break;
                }

                default:
                    break;
            }

            if (!isValid) {
                invalidQuestionIndexes.push(i);
            }
        }
    }

    return invalidQuestionIndexes;
}
