import {
  useRef,
  MutableRefObject
} from 'react';
import {
  CountryFlagProps,
} from './types';
import {
  Language,
} from 'global';

function CountryFlag(props: CountryFlagProps) {
  const refLastLanguage: MutableRefObject<Language> = useRef<Language>(props.language);
  const refIcon: MutableRefObject<string | null | false> = useRef<string | null | false>(null);

  if (refLastLanguage.current !== props.language) {
    refLastLanguage.current = props.language;
    refIcon.current = null;
  }

  if (refIcon.current === null) {
    try {
      refIcon.current = require(`./flags/${props.language}.svg`);
    } catch (error) {
      refIcon.current = false;
    }
  }

  return (
    <img src={refIcon.current || ''} alt={props.language} />
  );
}

export default CountryFlag;
