import {
  useEffect,
  useState,
  useRef
} from 'react';
import {
  apiMedia
} from 'api';

const __cache = {};

function useMediaData(imageUrl) {
  const refImageUrl = useRef(imageUrl);

  const [isLoading, setIsLoading] = useState(!!imageUrl);
  const [mediaData, setMediaData] = useState(null);

  useEffect(() => {
    refImageUrl.current = imageUrl;

    setIsLoading(!!imageUrl);
    setMediaData(__cache[imageUrl] || null);

    if (imageUrl) {
      const getMediaData = async () => {
        const mediaData = await fetchMediaData(imageUrl);

        __cache[imageUrl] = mediaData;

        if (refImageUrl.current === imageUrl) {
          if (mediaData) {
            setMediaData(mediaData);
          }

          setIsLoading(false);
        }
      };

      getMediaData();
    }

    return () => {
      refImageUrl.current = null;
    };
  }, [imageUrl]);

  return {
    isLoading,
    mediaData
  };
}

async function fetchMediaData(imageUrl) {
  try {
    const publicId = imageUrl.match(/upload\/(?:v\d+\/)?([^.]+)/)[1];

    const {
      data
    } = await apiMedia.get({
      external_id: publicId
    });

    const {
      created_at: dateCreated,
      date_taken: dateTaken,
      address,
      postcode,
      place,
      region,
      country,
      locality,
    } = data[0];

    const isValidValue = (value) => {
      return (
        typeof value === 'string' &&
        value.trim() !== '' &&
        value !== '??'
      );
    };

    return {
      ...data[0],
      fullAddress: [address, place, locality, region, postcode, country].filter(isValidValue).join(', '),
      date: dateTaken?.indexOf && dateTaken.indexOf('0000') === -1
        ? dateTaken
        : dateCreated
    };
  } catch (err) {
    return null;
  }
}

export {
  useMediaData
};
