import {
    formatDistanceToNowStrict as _formatDistanceToNowStrict,
    format as _format,
} from 'date-fns';
import {
    enGB as localeEn,
    fr as localeFr,
    ms as localeMs,
    id as localeId,
    ar as localeAr,
    // fil as localeFl,
    zhCN as localeZh,
    vi as localeVi,
} from 'date-fns/locale';
import {
    Language,
} from 'global';
import i18next from 'i18next';

const Locales = {
    [Language.English]: localeEn,
    [Language.French]: localeFr,
    [Language.Malay]: localeMs,
    [Language.Indonesian]: localeId,
    [Language.Arabic]: localeAr,
    [Language.Filipino]: localeEn, // No filipino locale
    [Language.Chinese]: localeZh,
    [Language.Vietnamese]: localeVi,
};

export function formatDistanceToNowStrict(date, options = {}) {
    return _formatDistanceToNowStrict(date, {
        ...options,
        locale: Locales[i18next.language] || localeEn,
    });
}

export function format(date, dateFormat, options = {}) {
    return _format(date, dateFormat, {
        ...options,
        locale: Locales[i18next.language] || localeEn,
    });
}
