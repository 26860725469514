import {
  Suspense
} from 'react';
import Router from './Router';
import RouterIframe from './Router/Iframe';
import MediaViewer from './MediaViewer';
import Menu from './Menu';
import ContextMenu from './Menu/ContextMenu';
import RightFrameViewProvider from './RightFrameView/Provider';
import FormCreate from './FormCreate';
import FormRespond from './FormRespond';
import CheckIn from './CheckIn';
import Toaster from './Toaster';
import Camera from './Camera';
import Analytics from './Analytics';
import ChatSearch from './ChatSearch';
import Gallery from './Gallery';
import Nav from './Nav';
import Loading from './Loading';
import {
  DragAndDrop,
} from 'module/provider';
import {
  Provider as ImageViewProvider
} from 'components/ImageView';
import {
  MediaAnalytics
} from 'components';
import {
  ROUTES_IFRAME
} from 'config';
import './index.scss';

export default function Container() {
  if (ROUTES_IFRAME.indexOf(window.location.pathname) > -1) {
    return (
      <main id='AppRoot'>
        <RouterIframe />
        <MediaViewer />
        <MediaAnalytics />
        <Camera />
        <Analytics />
      </main>
    );
  }

  return (
    <main id='AppRoot'>
      <Suspense fallback={<div />}>
        <CheckIn />
        <Nav />
        <ChatSearch />
        <MediaViewer />
        <MediaAnalytics />
        <RightFrameViewProvider />
        <FormCreate />
        <FormRespond />
        <Gallery />
      </Suspense>

      <DragAndDrop />
      <Toaster />
      <Router />
      <Menu />
      <ContextMenu />
      <Analytics />
      <ImageViewProvider />
      <Loading />
    </main>
  );
}
